import { Component, Inject, OnInit } from "@angular/core";
import { ApiToolService } from "../../services/api-tool.service";
import { ActivatedRoute, Router } from "@angular/router";
import { NotifyService } from "../../services/noyify.service";
import { BotService } from "../../services/bot.service";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { FlowBuilderService } from "../../services/flowbuilder.service";
import { TranslationService } from "../../services/translation.service";
import { SettingService } from "../../services/setting.service";

class DialogOverviewExampleDialog {}

@Component({
  selector: "app-flow-selection",
  templateUrl: "./flow-selection.component.html",
  styleUrls: ["./flow-selection.component.scss"],
})
export class FlowSelectionComponent implements OnInit {
  displayFolders = [];
  displayFlow = [];
  displaySubFolderNavList: any[] = [];
  selectedFlowIndex = null;
  isLoading = true;
  selectedLanguage = this._settingService.languages[0];
  constructor(
    public dialogRef: MatDialogRef<DialogOverviewExampleDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private apiToolService: ApiToolService,
    private router: Router,
    private route: ActivatedRoute,
    private _notificationService: NotifyService,
    private _flowBuilderService: FlowBuilderService,
    public _botService: BotService,
    readonly _translationService: TranslationService,
    public _settingService: SettingService
  ) {}

  ngOnInit() {
    this.getFlowBuilderList();
  }

  getFlowBuilderList(folderId?) {
    this.isLoading = true;
    if (!folderId) {
      this.displaySubFolderNavList = [];
    }
    this._flowBuilderService
      .getAllFlowFolderList(this.data.type ? this.data.type : "all", this.data.channel, this.selectedLanguage, folderId ? folderId : "")
      .then((result: any) => {
        this.displayFlow = result.result.docs;
        this.displayFolders = result.folderList;
        this.displayFolders.forEach((folder, i) => {
          if (i % 4 === 0) {
            folder.class = "yellow";
          } else if (i % 4 === 1) {
            folder.class = "red";
          } else if (i % 4 === 2) {
            folder.class = "green";
          } else {
            folder.class = "purple";
          }
        });
        this.isLoading = false;
      })
      .catch((err) => {
        this.isLoading = false;
        console.error(err);
        let errorMessage = "INTERNAL_SERVER_ERROR";
        if (err.error && err.error.error_code) {
          errorMessage = err.error.error_code;
        }
        this._translationService.getTranslate(errorMessage).then((res) => {
          this._notificationService.sendNotification("error", res);
        });
      });
  }

  openDisplaySubFolder(fIndex) {
    this.displaySubFolderNavList.push(this.displayFolders[fIndex]);
    this.getFlowBuilderList(this.displayFolders[fIndex]._id);
  }

  backToParentDisplayFolder(type, subFolderIndex?) {
    if (type === "oneBack") {
      if (this.displaySubFolderNavList.length === 1) {
        this.displaySubFolderNavList = [];
        this.getFlowBuilderList();
      } else {
        this.displaySubFolderNavList.splice(this.displaySubFolderNavList.length - 1, 1);
        this.getFlowBuilderList(this.displaySubFolderNavList[this.displaySubFolderNavList.length - 1]._id);
      }
    } else {
      this.displaySubFolderNavList.splice(subFolderIndex + 1);
      this.getFlowBuilderList(this.displaySubFolderNavList[this.displaySubFolderNavList.length - 1]._id);
    }
  }

  dismissModal(dialogRef) {
    dialogRef.close();
  }

  setSelection() {
    this.dialogRef.close({
      selectedFlow: this.displayFlow[this.selectedFlowIndex],
    });
  }
}
