import { Component, Input, OnChanges, OnInit, Output, SimpleChanges, EventEmitter } from "@angular/core";
import { BotService } from "../../../services/bot.service";
import { FlowBuilderService } from "../../../services/flowbuilder.service";
import { TranslationService } from "../../../services/translation.service";
import { NotifyService } from "../../../services/noyify.service";
import { SettingService } from "../../../services/setting.service";
import { CommonService } from "../../../services/common.service";
import { environment } from "../../../../../environments/environment";
import { getChannelInfo } from "../../../helper/channel.helper";

@Component({
  selector: "folder-flow-column",
  templateUrl: "./folder-flow-column.component.html",
  styleUrls: ["./folder-flow-column.component.scss"],
})
export class FolderFlowColumnComponent implements OnInit, OnChanges {
  @Input() selectedChannel;
  @Input() currentSelectedFlow;
  @Output() selectedFlow = new EventEmitter<any>();
  environment = environment;
  subFolderList = [];
  folders = [];
  search = "";
  showFolderSubFolderBlock = false;
  selectedBot = this._botService.getBot();
  flowBuilders = [];
  isLoading = false;
  currentPage = 1;
  totalPage;
  isPagination = false;
  gridViewSelected = true;
  subFolderNavList = [];
  selectedLanguage = "en";
  selectedLanguageFlowLanguage = this._settingService.languages[0];
  selectedFolder = undefined;
  isSerach = false;
  constructor(
    public _botService: BotService,
    public _translationService: TranslationService,
    public _notificationService: NotifyService,
    public _flowBuilderService: FlowBuilderService,
    public _settingService: SettingService,
    public _commonService: CommonService
  ) {}

  ngOnInit(): void {
    this.selectedLanguage = this._translationService.getTranslationLanguage();
  }

  get channelIcon() {
    return getChannelInfo(this.selectedChannel);
  }

  setSelectedFolder(event: any) {
    this.selectedFolder = event;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.selectedChannel && changes.selectedChannel.currentValue) {
      this.subFolderNavList = [];
      this.selectedFolder = undefined;
      this.getFlowBuilderList();
    }
  }

  searchList(event) {
    if (event.keyCode === 8 || event.keyCode === 46) {
      setTimeout(() => {
        if (event.target.value === "") {
          this.searchFlows("");
        }
      }, 0);
    }
    if (event.keyCode === 13) {
      this.searchFlows(event.target.value);
    }
  }

  searchFlows(value) {
    if (value.trim()) {
      this.isSerach = true;
      if (!this.subFolderNavList.length) {
        this.selectedFolder = undefined;
      }
      if (this.selectedFolder) {
        this.getSubFolderList(this.selectedFolder ? this.selectedFolder._id : null, null, null, value);
      } else {
        this.getFlowBuilderList(null, value);
      }
    } else if (!value.length) {
      if (this.isSerach) {
        this.isSerach = false;
        if (this.selectedFolder) {
          this.getSubFolderList(this.selectedFolder ? this.selectedFolder._id : null);
        } else {
          this.getFlowBuilderList();
        }
        this.getFlowBuilderList(this.selectedFolder ? this.selectedFolder._id : null);
      }
    }
  }

  getFlowBuilderList(folderId?, search?) {
    this.subFolderNavList = [];
    this._flowBuilderService
      .getAllFlowFolderList("flow", this.selectedChannel, this.selectedLanguageFlowLanguage, folderId, search)
      .then((result: any) => {
        this.flowBuilders = result.result.docs;
        if (!search) {
          this.folders = result.folderList;
        }
        this.setFolderColors();
        this.isLoading = false;
      })
      .catch((err) => {
        this.isLoading = false;
        console.error(err);
        let errorMessage = "INTERNAL_SERVER_ERROR";
        if (err.error && err.error.error_code) {
          errorMessage = err.error.error_code;
        }
        this._translationService.getTranslate(errorMessage).then((res) => {
          //   this.showErrors(res);
        });
      });
  }

  openSubFolder(event) {
    this.currentPage = 1;
    if (event.isMainFolder) {
      this.subFolderNavList = [];
      this.subFolderNavList.push({ folderName: event.name, _id: event.id });
    } else {
      if (event.parentFolder === this.subFolderNavList[0]._id) {
        const parentFolder = this.folders.find((folder) => folder._id === event.parentFolder);
        this.subFolderNavList = [];
        this.subFolderNavList.push({
          _id: parentFolder._id,
          folderName: parentFolder.folderName,
        });
        this.subFolderNavList.push({ folderName: event.name, _id: event.id });
      }
    }
    const duplicateFolder = this.subFolderNavList.find((folder) => folder._id === event.id);
    if (!duplicateFolder) {
      this.subFolderNavList.push({ folderName: event.name, _id: event.id });
    }
    this.getSubFolderList(event.id, this.currentPage, event.isMainFolder);
  }

  getSubFolderList(folderId, page?, isMainFolder?, search?) {
    this.isLoading = true;
    this.flowBuilders = [];
    this._flowBuilderService
      .getAllFlowFolderList("flow", this.selectedChannel, this.selectedLanguageFlowLanguage, folderId ? folderId : "", search)
      .then((data: any) => {
        const result = data.result;
        this.flowBuilders = result.docs;
        if (isMainFolder) {
          this.subFolderList = data.folderList;
        }
      })
      .catch((err) => {
        this.isLoading = false;
        console.error(err);
        let errorMessage = "INTERNAL_SERVER_ERROR";
        if (err.error && err.error.error_code) {
          errorMessage = err.error.error_code;
        }
        this._translationService.getTranslate(errorMessage).then((res) => {
          this._notificationService.sendNotification("error", res);
        });
      });
  }

  setFolderColors() {
    this.folders.forEach((folder, i) => {
      if (i % 4 === 0) {
        folder.class = "yellow";
      } else if (i % 4 === 1) {
        folder.class = "red";
      } else if (i % 4 === 2) {
        folder.class = "green";
      } else {
        folder.class = "purple";
      }
    });
  }

  selectGridView() {
    this.gridViewSelected = true;
  }

  selectListView() {
    this.gridViewSelected = false;
  }

  emitFlowSelectEvent(flowId, flowName) {
    this.selectedFlow.emit({ flowId, flowName });
  }

  backToParentFolder(type, subFolderIndex?) {
    if (type === "oneBack") {
      if (this.subFolderNavList.length === 1) {
        this.subFolderNavList = [];
        this.selectedFolder = undefined;
        this.getFlowBuilderList();
      } else {
        this.subFolderNavList.splice(this.subFolderNavList.length - 1, 1);
        if (this.subFolderNavList[this.subFolderNavList.length - 1]) {
          this.backToParentFolder("oneBack");
        }
      }
    } else {
      this.subFolderNavList.splice(subFolderIndex + 1);
      this.selectedFolder = this.subFolderNavList[this.subFolderNavList.length - 1];
      this.getSubFolderList(this.subFolderNavList[this.subFolderNavList.length - 1]._id);
    }
  }
}
