import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { FolderService } from "../../../services/folder.service";
import { FlowBuilderService } from "../../../services/flowbuilder.service";
import { BotService } from "../../../services/bot.service";

@Component({
  selector: "modal-move-folder",
  templateUrl: "./move-modal.component.html",
  styleUrls: ["../modal-common.scss"],
})
export class MoveModalComponent implements OnInit, OnChanges {
  @Input() moveFolder;
  @Input() flowName;
  @Input() type;
  @Input() displayFolders;
  @Input() selectedFolderIndex;
  @Input() disableAddFolder;
  @Input() selectedChannel;
  @Output() selectedFolderEvent = new EventEmitter();
  @Output() moveToFolderEvent = new EventEmitter();
  @Output() createFolderEvent = new EventEmitter();
  @Output() displayedFoldersEvent = new EventEmitter();
  @Output() displayMainList = new EventEmitter();
  @Output() displaySubList = new EventEmitter();
  hideFolderCount = 0;
  createFolderDialogRef;
  parentFolderIndex;
  parentFolderId;
  subFolderNavList = [];
  constructor(
    private _flowBuilderService: FlowBuilderService,
    public _botService: BotService,
    public dialog: MatDialog,
    private _folderService: FolderService
  ) {}

  ngOnInit(): void {
    setTimeout(() => {
      if (this.moveFolder?.parentFolder) {
        this.parentFolderIndex = this.displayFolders.findIndex((folder) => folder._id === this.moveFolder.parentFolder);
      }
      this.setFolderColor();
    }, 10);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.displayFolders && changes.displayFolders.currentValue) {
      this.setFolderColor();
    }
  }

  selectFolder(fName: string, index: number): void {
    this.hideFolderCount++;
    this.subFolderNavList = [];
    this.subFolderNavList.push({
      folderName: fName,
      _id: this.displayFolders[index]._id,
    });
    this.selectedFolderIndex = index;
    this.parentFolderId = this.displayFolders[this.selectedFolderIndex]._id;
    this.selectedFolderEvent.emit({ index, type: this.type });
    this.setFolderColor();
  }

  moveToFolder(): void {
    this.moveToFolderEvent.emit(this.subFolderNavList);
  }

  openDialog(template: TemplateRef<any>): void {
    this._folderService.openDialog(
      template,
      { width: "600px" },
      {
        title: "CREATE_FOLDER",
        template,
        isShowHeader: true,
      },
      "create"
    );
  }

  createFolder(event: any): void {
    const obj = {
      parentFolder: this.parentFolderId,
      data: event,
    };
    this.createFolderEvent.emit(obj);
    this.setFolderColor();
  }

  backToParentFolder(index: number): void {
    this.subFolderNavList.splice(index + 1);
    this.getSubDisplayFolderList(this.subFolderNavList[this.subFolderNavList.length - 1]._id);
  }

  getAllFolderList(): void {
    this.hideFolderCount = 0;
    this.displayMainList.emit();
    this.subFolderNavList = [];
    this.setFolderColor();
  }

  getSubDisplayFolderList(folderId: string): void {
    this.displaySubList.emit(folderId);
  }
  setFolderColor(): void {
    setTimeout(() => {
      this.displayFolders.forEach((folderEle, i) => {
        if (i % 4 === 0) {
          folderEle.class = "yellow";
        } else if (i % 4 === 1) {
          folderEle.class = "red";
        } else if (i % 4 === 2) {
          folderEle.class = "green";
        } else {
          folderEle.class = "purple";
        }
      });
    }, 50);
  }
}
