import { Component, EventEmitter, OnInit, Output } from "@angular/core";

@Component({
  selector: "modal-create-folder",
  templateUrl: "./create-folder-modal.component.html",
  styleUrls: ["../modal-common.scss"],
})
export class CreateFolderModalComponent {
  createFolderName = "";
  @Output() createFolderEvent = new EventEmitter();

  createFolder() {
    if (this.createFolderName.trim() !== "") {
      const newFolderData = {
        folderName: this.createFolderName,
      };
      this.createFolderEvent.emit(newFolderData);
    }
  }

  handleEnter(event: KeyboardEvent) {
    if (event.keyCode === 13) {
      event.preventDefault();
      this.createFolder();
    }
  }
}
