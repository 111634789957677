import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { FlowBuilderService } from "../../services/flowbuilder.service";
import { BotService } from "../../services/bot.service";
import { NotifyService } from "../../services/noyify.service";
import { FolderService } from "../../services/folder.service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { TranslationService } from "../../services/translation.service";

@Component({
  selector: "folder-subfolder",
  templateUrl: "./folder-subfolder.component.html",
  styleUrls: ["./folder-subfolder.component.scss"],
})
export class FolderSubfolderComponent implements OnInit, OnChanges, OnDestroy {
  @Input() selectedChannel;
  @Input() folders = [];
  @Input() displayFolders = [];
  @Input() subFolderList = [];
  @Input() showBlock;
  @Input() type;
  @Input() viewType;
  @Output() flowChanges = new EventEmitter();
  @Output() openSubFolderEvent = new EventEmitter();
  @Output() createFolderEvent = new EventEmitter();
  @Output() checkFolderForDelete = new EventEmitter();
  @Output() displayListEvent = new EventEmitter();
  @Output() moveFolderEvent = new EventEmitter();
  @Output() selectedFolderEvent = new EventEmitter();
  @Output() displayMainList = new EventEmitter();
  @Output() displaySubList = new EventEmitter();
  @Output() folderRenameEvent = new EventEmitter();
  createFolderName;
  moveToFolderIndex;
  moveToFolder;
  @ViewChild("moveToTemplate")
  moveTempRef: TemplateRef<any>;
  dialogRef;
  selectedFolder;
  selectedSubFolder;
  displaySubFolderNavList: any[] = [];
  tempDisplaySelectedFolderIndex;
  tempSubFolderIndex;
  fromDisplay = false;
  isSearch = false;
  searchSubFolderNavList: any[] = [];
  selectedBot = this._botService.getBot();
  deleteList: any;
  @ViewChild("deleteFolderModal")
  deleteFolderTemplate: TemplateRef<any>;
  deleteFolderIndex: any = "";
  deleteFolderData;
  isMainFolder = true;
  isDeleteMainFolder = true;
  isOpenModal = false;
  lastFolderName;
  selectedFolderIndex;
  private unsubscribe: Subject<void> = new Subject();
  constructor(
    public dialog: MatDialog,
    public _flowBuilderService: FlowBuilderService,
    public _botService: BotService,
    private _notificationService: NotifyService,
    private _folderService: FolderService,
    private _translationService: TranslationService
  ) {}

  ngOnInit() {
    setTimeout(() => {
      this.folders.forEach((folder) => ((folder.isOpen = false), (folder.isRename = false)));
    }, 500);
    this._folderService.moveFolderSubject.pipe(takeUntil(this.unsubscribe)).subscribe((data: any) => {
      if (data && data.length) {
        this.isOpenModal = false;
        this._flowBuilderService.selectedFolderActionIndex = undefined;
        this._flowBuilderService.selectedSubFolderIndex = undefined;
        this._translationService.getTranslate("YOU_CAN_NOT_MOVE_FOLDER_WITH_SUB_FOLDER").then((res) => {
          this.showErrors(res);
        });
      } else {
        this._folderService.openDialog(
          this.moveTempRef,
          { width: "600px", height: "70vh" },
          {
            title: "MOVE_TO",
            template: this.moveTempRef,
            isShowHeader: true,
          },
          "move"
        );
      }
    });
    this._folderService.closeDialogSubject.pipe(takeUntil(this.unsubscribe)).subscribe((data) => {
      this.isOpenModal = false;
      this._flowBuilderService.selectedFolderActionIndex = undefined;
      this._flowBuilderService.selectedSubFolderIndex = undefined;
      if (this.folders[this.moveToFolderIndex]) {
        this.folders[this.moveToFolderIndex].isOpen = false;
      }
      this.moveToFolderIndex = "";
      this.tempDisplaySelectedFolderIndex = undefined;
      if (this.deleteFolderIndex !== "" && data !== "cancel") {
        if (this.isDeleteMainFolder) {
          this.folders.splice(this.deleteFolderIndex, 1);
          this.setFolderColors();
        } else {
          if (this.deleteFolderData.parentFolder !== "") {
            const parentIndex = this.folders.findIndex((f) => f._id === this.deleteFolderData.parentFolder);
            this.folders[parentIndex].isOpen = false;
          }
        }
      }
    });
  }

  openDialog(templateRef, type?, index?, folder?, isMainFolder?): void {
    this.isOpenModal = true;
    if (type === "newFolder" || type === "subFolder") {
      this.createFolderName = "";
      this._folderService.openDialog(
        templateRef,
        { width: "600px" },
        {
          title: type === "newFolder" ? "CREATE_FOLDER" : "CREATE_SUB_FOLDER",
          template: templateRef,
          isShowHeader: true,
        },
        "create"
      );
    } else if (type === "moveToFolder") {
      this.displayListEvent.emit();
      this.moveToFolderIndex = index;
      this.moveTempRef = templateRef;
      this.moveToFolder = folder;
      if (isMainFolder) {
        this.selectedFolder = this.folders[index];
      } else {
        this.selectedSubFolder = this.subFolderList[index];
        this.moveToFolder.parentFolder = this.selectedFolder._id;
      }
      this.openSubFolderEvent.emit({
        isCheckSubFolder: true,
        isMainFolder: true,
        index,
        id: folder._id,
      });
    } else if (type === "deleteFolder") {
      this.deleteFolderIndex = "";
      this.deleteFolderIndex = index;
      this._folderService.openDialog(
        templateRef,
        { width: "400px", panelClass: "delete-model-main" },
        {
          title: "ARE_YOU_SURE",
          template: templateRef,
          isShowHeader: false,
        }
      );
    }
  }
  getDisplayList() {
    this.displaySubFolderNavList = [];
    this.displayListEvent.emit();
  }
  openSubFolder(fIndex, isMainFolder, parentIndex?) {
    let id;
    let name;
    if (isMainFolder) {
      this.folders.forEach((folder, i) => {
        if (i !== fIndex) {
          folder.isOpen = false;
        }
      });
      this.tempSubFolderIndex = undefined;
      id = this.folders[fIndex]._id;
      name = this.folders[fIndex].folderName;
      this.folders[fIndex].isOpen = !this.folders[fIndex].isOpen;
      this.selectedFolder = this.folders[fIndex];
      this.selectedFolder.selectedIndex = fIndex;
      this.selectedFolderEvent.emit(this.selectedFolder);
    } else {
      this.tempSubFolderIndex = fIndex;
      id = this.subFolderList[fIndex]._id;
      name = this.subFolderList[fIndex].folderName;
      this.selectedSubFolder = this.subFolderList[fIndex];
      this.selectedFolderEvent.emit(this.selectedSubFolder);
    }
    if ((this.folders[fIndex] && this.folders[fIndex].isOpen) || !isMainFolder) {
      this.openSubFolderEvent.emit({
        isCheckSubFolder: false,
        isMainFolder,
        index: fIndex,
        id,
        name,
        parentFolder: !isMainFolder ? this.folders[parentIndex]?._id : null,
      });
    }
    if (isMainFolder) {
      this.subFolderList = [];
    }
  }
  checkDeleteFolder(folder, index, type) {
    this.isDeleteMainFolder = type === "parentFolder";
    this.deleteFolderData = folder;
    if (type === "subFolder") {
      this.deleteFolderData.parentFolder = this.selectedFolder._id;
    }
    const folderDataObject = {
      deleteFolderData: this.deleteFolderData,
      index,
    };
    this.checkFolderForDelete.emit(folderDataObject);
  }
  dismissModal() {
    this.dialog.closeAll();
  }
  handleEnter(e, type) {
    if (e.keyCode === 13) {
      e.preventDefault();
      if (type === "saveFolder") {
        // this.createFolder();
      }
      if (type === "renameFolder") {
        this.editFolder("reName");
      }
    }
  }

  displayMainListFunction() {
    this.displayMainList.emit();
  }

  displaySubListFunction(folderId) {
    this.displaySubList.emit(folderId);
  }

  createFolder(event) {
    this.selectedBot = this._botService.getBot();
    if (event) {
      let object: any;
      if (event.data) {
        object = {
          folderName: event.data.folderName,
          botId: this.selectedBot,
        };
      } else {
        object = {
          folderName: event.folderName,
          botId: this.selectedBot,
        };
      }

      if (!this.fromDisplay && !this.isSearch) {
        if (!this.isMainFolder) {
          object.parentFolder = this.selectedFolder._id;
        }
      } else if (this.fromDisplay) {
        if (this.displaySubFolderNavList.length !== 0) {
          object.parentFolder = this.displaySubFolderNavList[this.displaySubFolderNavList.length - 1]._id;
        }
      } else if (this.isSearch && !this.fromDisplay) {
        if (this.searchSubFolderNavList.length !== 0) {
          object.parentFolder = this.searchSubFolderNavList[this.searchSubFolderNavList.length - 1]._id;
        }
      }
      this.createFolderEvent.emit(object);
    } else {
      this._translationService.getTranslate("ENTER_FOLDER_NAME").then((res) => {
        this.showErrors(res);
      });
    }
  }
  showErrors(msg) {
    this._notificationService.sendNotification("error", msg);
  }
  editFolder(type) {
    let object;
    let folderId;
    if (type === "move") {
      folderId = this.moveToFolder._id;
      if (this.tempDisplaySelectedFolderIndex === undefined) {
        object = {
          parentFolder: "root",
          botId: this.selectedBot,
        };
      } else {
        object = {
          parentFolder: this.displayFolders[this.tempDisplaySelectedFolderIndex]._id,
          botId: this.selectedBot,
        };
      }
    }
    this.moveFolderEvent.emit({ type, object, folderId });
  }
  openDisplaySubFolder(fIndex) {
    this.moveToFolderIndex = fIndex;
    this.tempDisplaySelectedFolderIndex = fIndex;
    this.displaySubFolderNavList.push(this.displayFolders[fIndex]);
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes.subFolderList) {
      this.subFolderList = changes.subFolderList.currentValue;
      this.subFolderList.forEach((subFolder) => (subFolder.isRename = false));
    }
    if (changes.displayFolders) {
      this.displayFolders = changes.displayFolders.currentValue;
    }
    if (changes.folders) {
      this.selectedFolder = undefined;
    }
  }
  clearDialog() {
    this.deleteFolderIndex = "";
    this.deleteFolderData = undefined;
    this.deleteList = [];
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  onActionClick(index: number, event, type) {
    if (type === "parentFolder") {
      this.folders.forEach((folder) => (folder.isOpen = false));
      this._flowBuilderService.selectedFolderActionIndex = index;
    } else {
      this._flowBuilderService.selectedSubFolderIndex = index;
    }
    event.stopPropagation();
  }

  onDeSelectFolder() {
    if (!this.isOpenModal) {
      this.folders.forEach((folder) => (folder.isOpen = false));
    }
  }
  saveData(type, event, folderIndex, subFolderIndex?) {
    let id;
    const obj = {
      botId: this._botService.getBot(),
      folderName: event.target.value,
      parentFolderId: null,
      lastName: this.lastFolderName,
    };
    if (type === "folder") {
      this.folders[folderIndex].isRename = false;
      if (event.target.value === this.lastFolderName || event.target.value.trim() === "") {
        this.folders[folderIndex].folderName = this.lastFolderName;
        return;
      }
      id = this.folders[folderIndex]._id;
    } else if (type === "subFolder") {
      this.subFolderList[subFolderIndex].isRename = false;
      if (event.target.value === this.lastFolderName || event.target.value.trim() === "") {
        this.subFolderList[subFolderIndex].folderName = this.lastFolderName;
        return;
      }
      id = this.subFolderList[subFolderIndex]._id;
      obj.parentFolderId = this.folders[folderIndex]._id;
    }
    if (this.type !== "nlp") {
      this._flowBuilderService
        .updateFolder(id, obj)
        .then((res) => {
          this.lastFolderName = undefined;
          if (type === "folder") {
            this.folders[folderIndex].folderName = event.target.value;
          } else if (type === "subFolder") {
            this.subFolderList[subFolderIndex].folderName = event.target.value;
          }
          this._notificationService.sendNotification("success", "Folder Name Updated Successfully!");
        })
        .catch((err) => {
          console.log(err);
          this._notificationService.sendNotification("error", err.error.description ? err.error.description : "Something Went Wrong!");
          if (type === "folder") {
            this.folders = JSON.parse(JSON.stringify(this.folders));
            this.folders[folderIndex].folderName = this.lastFolderName;
          } else if (type === "subFolder") {
            this.subFolderList = JSON.parse(JSON.stringify(this.subFolderList));
            this.subFolderList[subFolderIndex].folderName = this.lastFolderName;
          }
        });
    } else {
      this.folderRenameEvent.emit({ id, obj });
    }
  }

  renameFolder(type, index) {
    if (type === "parent") {
      this.folders[index].isRename = true;
      setTimeout(() => {
        document.getElementById("folder-" + index).focus();
      }, 10);
    } else {
      this.subFolderList[index].isRename = true;
      setTimeout(() => {
        document.getElementById("subFolder-" + index).focus();
      }, 10);
    }
  }

  saveLastName(event) {
    this.lastFolderName = event.target.value;
  }

  setFolderColors() {
    this.folders.forEach((folder, i) => {
      if (i % 4 === 0) {
        folder.class = "yellow";
      } else if (i % 4 === 1) {
        folder.class = "red";
      } else if (i % 4 === 2) {
        folder.class = "green";
      } else {
        folder.class = "purple";
      }
    });
  }
}
