import { AfterViewInit, Component, OnInit } from "@angular/core";
import { NavigationCancel, NavigationEnd, NavigationStart, Router } from "@angular/router";
import { FirebaseService } from "./core/services/firebase.service";

const confirm = "confirm";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
})
export class AppComponent implements AfterViewInit, OnInit {
  title = "antonic-backpanel";
  loading: boolean;
  currentUrl = "";

  constructor(readonly router: Router, readonly firebaseService: FirebaseService) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentUrl = event.url;
      }
    });
    window.onbeforeunload = (beforeUnloadEvent) => {
      if (this.currentUrl.indexOf("dashboard/nlp/intent/edit/") !== -1) {
        beforeUnloadEvent = beforeUnloadEvent || window.event;
        if (beforeUnloadEvent) {
          beforeUnloadEvent.returnValue = confirm;
        }
        return confirm;
      }
    };
  }
  ngOnInit() {
    this.firebaseService.requestPermission();
    this.firebaseService.receiveMessage();
  }

  ngAfterViewInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        if (event.url === "/dashboard" || event.url.includes("preview") || event.url.includes("link")) {
          this.loading = true;
        }
      } else if (event instanceof NavigationEnd || event instanceof NavigationCancel) {
        if (event.url === "/dashboard" || event.url.includes("preview") || event.url.includes("link")) {
          this.loading = false;
        }
      }
    });
  }
}
