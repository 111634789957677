<div class="bg-design">
  <div class="after-border-bottom">
    <div class="main-heading" *ngIf="config.showHeading">
      {{ "FOLDER" | translate }}
      <div
        class="c-p new-Folder-wrap"
        aria-hidden="false"
        aria-label="add"
        *ngIf="!botService.isTester()"
        (click)="openDialog(createFolderTemplate, 'createFolder')"
      >
        <img src="../../../../assets/images/plus-icons.svg" class="img-fluid mr-3" alt="" srcset="" />
        <span>{{ "ADD_NEW" | translate }}</span>
      </div>
    </div>
  </div>
  <ng-container *ngIf="folderData.folders">
    <div
      *ngIf="folderData.folders.length !== 0"
      class="main-folders-list"
      [ngStyle]="{ padding: config.showHeading ? '15px 20px' : '0px' }"
      [ngClass]="{
        'main-folders-list-half': config.viewType === 'half'
      }"
    >
      <div
        class="col-md-12 p-0"
        *ngFor="let folder of folderData.folders; index as fIndex"
        [ngClass]="{
          activeView: (folderData.selectedFolder && folder._id === folderData.selectedFolder._id) || folder.isOpen
        }"
      >
        <div id="folder-main" [class]="folder.class">
          <div class="d-flex align-center pl-10" (click)="openFolder(folder, fIndex)">
            <div class="drop-down-arrow" [ngClass]="{ 'drop-down-arrow--rotate': folder.isOpen }">
              <img src="../../../../assets/images/arror-right.svg" class="img-fluid" alt="" />
            </div>
            <div id="folders">
              <div class="icon-text">
                <p>{{ folder.folderName | shortName }}</p>
              </div>
            </div>
            <div class="folder-name" [matTooltip]="'CLICK_TO_OPEN' | translate">
              <span class="nlp-folderName" *ngIf="!folder.isRename">{{ folder.folderName }}</span>
              <input
                *ngIf="folder.isRename"
                [id]="'folder-' + fIndex"
                type="text"
                class="edit-Flow_name w-100"
                [(ngModel)]="folder.folderName"
                (click)="$event.stopPropagation()"
                (focusin)="folder.oldFolderName = folder.folderName"
                (keyup.enter)="renameFolder(folder, fIndex, 'renameFolder')"
                (blur)="renameFolder(folder, fIndex, 'renameFolder')"
              />
              <div class="folder-count-badge" *ngIf="config.showHeading && folder.count">
                {{ folder.count }}
              </div>
            </div>
          </div>
          <mat-icon
            *ngIf="config.showHeading && !botService.isTester()"
            (click)="$event.stopPropagation()"
            class="align-right-icon"
            aria-hidden="false"
            aria-label="add"
            [mat-menu-trigger-for]="parentMenu"
            >more_vert
          </mat-icon>
          <mat-menu #parentMenu>
            <div class="folder-action-icon-div-main d-flex align-center" (click)="this.onClickRenameFolder('parent', fIndex)">
              <img src="../../../../assets/images/action-icon/pencil.svg" class="img-fluid mr-3" alt="" />
              <p class="mb-0">{{ "RENAME" | translate }}</p>
            </div>
            <div class="folder-action-icon-div-main d-flex align-center" (click)="moveFolder(folder, fIndex, 'moveFolder')">
              <img src="../../../../assets/images/move.svg" class="img-fluid mr-3" alt="" />
              <p class="mb-0">{{ "MOVE" | translate }}</p>
            </div>
            <div class="folder-action-icon-div-main d-flex align-center" (click)="deleteFolder(folder, fIndex, 'folder')">
              <img src="../../../../assets/images/delete-icon.svg" class="img-fluid mr-3" alt="" />
              <p class="mb-0 text-danger-p">{{ "DELETE" | translate }}</p>
            </div>
          </mat-menu>
        </div>

        <!-- subfolder  -->
        <ul id="subFolderWrap" [class]="folder.class" *ngIf="folder.isOpen">
          <ng-container *ngIf="folderData.subFolders.length">
            <li
              class="sub-folder d-flex align-center c-p"
              *ngFor="let subFolder of folderData.subFolders; index as SIndex"
              (click)="openSubFolder(subFolder, SIndex)"
              [ngClass]="{
                activeViewLi: folderData?.selectedSubFolder?._id === subFolder._id
              }"
            >
              <div class="" id="folders">
                <div class="icon-text">
                  <p>{{ subFolder.folderName | shortName }}</p>
                </div>
              </div>
              <div class="folder-name" [matTooltip]="'CLICK_TO_OPEN' | translate">
                <span class="nlp-folderName" *ngIf="!subFolder.isRename">{{ subFolder.folderName }}</span>
                <input
                  *ngIf="subFolder.isRename"
                  [id]="'subFolder-' + SIndex"
                  type="text"
                  class="edit-Flow_name w-100"
                  [(ngModel)]="subFolder.folderName"
                  (click)="$event.stopPropagation()"
                  (focusin)="subFolder.oldFolderName = subFolder.folderName"
                  (blur)="renameFolder(subFolder, SIndex, 'renameSubFolder')"
                  (keyup.enter)="renameFolder(subFolder, SIndex, 'renameSubFolder')"
                />
              </div>
              <mat-icon
                *ngIf="config.showHeading && !botService.isTester()"
                (click)="$event.stopPropagation()"
                class="align-right-icon c-p"
                aria-hidden="false"
                aria-label="add"
                [mat-menu-trigger-for]="subMenu"
                >more_vert
              </mat-icon>
              <mat-menu #subMenu>
                <div class="folder-action-icon-div-main d-flex align-center" (click)="this.onClickRenameFolder('child', SIndex)">
                  <img src="../../../../assets/images/action-icon/pencil.svg" class="img-fluid mr-3" alt="" />
                  <p class="mb-0">{{ "RENAME" | translate }}</p>
                </div>
                <div class="folder-action-icon-div-main d-flex align-center" (click)="moveFolder(subFolder, SIndex, 'moveSubFolder')">
                  <img src="../../../../assets/images/move.svg" class="img-fluid mr-3" alt="" />
                  <p class="mb-0">{{ "MOVE" | translate }}</p>
                </div>
                <div class="folder-action-icon-div-main d-flex align-center" (click)="deleteFolder(subFolder, SIndex, 'subFolder')">
                  <img src="../../../../assets/images/delete-icon.svg" class="img-fluid mr-3" alt="" />
                  <p class="mb-0 text-danger-p">{{ "DELETE" | translate }}</p>
                </div>
              </mat-menu>
            </li>
          </ng-container>
          <li *ngIf="config.showHeading && !botService.isTester()">
            <div class="create-folder" aria-hidden="false" aria-label="add" (click)="openDialog(createFolderTemplate, 'createSubFolder')">
              <img src="../../../../assets/images/plus-icons.svg" class="img-fluid mr-3" alt="" srcset="" />
              <span>{{ "ADD_NEW_SUB_FOLDER" | translate }}</span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </ng-container>
</div>

<!--new Folder template -->
<ng-template #createFolderTemplate>
  <modal-create-folder (createFolderEvent)="createFolder($event)"></modal-create-folder>
</ng-template>
