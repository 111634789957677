import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, TemplateRef } from "@angular/core";
import { IFolder, IFolderFlowMoveCopyData } from "../../../models/flow-list";
import { MatDialog } from "@angular/material/dialog";
import { DialogComponent } from "../dialog/dialog.component";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-move-model-v2",
  templateUrl: "./move-model-v2.component.html",
  styleUrls: ["../modal-common.scss"],
})
export class MoveModelV2Component implements OnChanges {
  @Input() moveFolderData: IFolderFlowMoveCopyData;
  @Output() moveUpdate = new EventEmitter<any>();
  dialogReference;
  isButtonClicked = false;
  environment = environment;

  constructor(readonly dialog: MatDialog) {}

  openDialog(template: TemplateRef<any>) {
    this.dialogReference = this.dialog.open(DialogComponent, {
      height: "auto",
      width: "600px",
      data: {
        title: "CREATE_FOLDER",
        template,
        isShowHeader: true,
      },
    });
  }

  onSelectFolder(folder, index) {
    folder.index = index;
    this.moveFolderData.selectedFolder = folder;
    if (!this.moveFolderData.config.allowNestedFolderSelection) {
      this.moveFolderData.folderNavigation = [folder];
    } else {
      const folderIndex = this.moveFolderData.folderNavigation.findIndex((moveFolderData) => moveFolderData._id === folder._id);
      if (folderIndex === -1) {
        this.moveFolderData.folderNavigation.push(folder);
      }
      this.moveFolderData.config.disableAddFolder = this.moveFolderData.folderNavigation.length === this.moveFolderData.config.nestedFolder;
      this.moveUpdate.emit({
        type: "openSubFolder",
        openSubFolder: folder,
      });
    }
  }

  backToRootFolder() {
    this.isButtonClicked = false;
    this.moveFolderData.selectedFolder = null;
    this.moveFolderData.folderNavigation = [];
    this.moveFolderData.config.disableAddFolder = false;
    if (this.moveFolderData.config.allowNestedFolderSelection) {
      this.moveUpdate.emit({
        type: "backToRoot",
      });
    }
  }

  backToParentFolder(navigation: IFolder, navigationIndex: number) {
    this.isButtonClicked = false;
    this.moveFolderData.selectedFolder = navigation;
    this.moveFolderData.folderNavigation.splice(navigationIndex + 1);
    this.moveFolderData.config.disableAddFolder = this.moveFolderData.folderNavigation.length === this.moveFolderData.config.nestedFolder;
    this.moveUpdate.emit({
      type: "openSubFolder",
      openSubFolder: navigation,
    });
  }

  createFolder(event) {
    this.moveUpdate.emit({
      type: "createFolder",
      createFolder: event,
    });
    this.dialogReference.close();
  }
  moveToFolder() {
    this.isButtonClicked = true;
    this.moveUpdate.emit({
      type: "moveFolder",
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.moveFolderData && changes.moveFolderData.currentValue && changes.moveFolderData.currentValue.folders) {
      this.setFolderColors(this.moveFolderData.folders);
    }
  }

  setFolderColors(folders) {
    folders.forEach((folder, i) => {
      if (i % 4 === 0) {
        folder.class = "yellow";
      } else if (i % 4 === 1) {
        folder.class = "red";
      } else if (i % 4 === 2) {
        folder.class = "green";
      } else {
        folder.class = "purple";
      }
    });
  }
}
