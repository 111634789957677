<div class="main-screen">
  <div class="w-100">
    <img (click)="showUserList()" src="assets/images/back-arrow.svg" alt="" class="mt-40 mb-10 pt-10 img-fluid c-p" />
    <h1 class="user-profile-title text-left mb-10 w-100">
      {{ "USER_PROFILE_DETAILS" | translate }}
    </h1>
  </div>
  <div class="d-flex h-100 w-100 user-profile-container">
    <div class="w-70 p-20 user-profile-left overflow-y">
      <app-common-bot-user-details
        [isTranScriptURL]="isTranscriptUrl"
        [selectedUser]="selectedUser"
        [IsProfile]="true"
        (updateVariables)="updateVariableStatus($event)"
      >
      </app-common-bot-user-details>
    </div>
    <div class="w-30 user-profile-right-main">
      <div
        class="user-chat-main"
        infiniteScroll
        id="chat-area"
        [scrollWindow]="false"
        [infiniteScrollDistance]="1"
        [infiniteScrollUpDistance]="1"
        [fromRoot]="true"
        (scrolledUp)="onUserChatMainScrollUp()"
        (scroll)="onUserChatMainScrollDown()"
      >
        <div class="username-header">
          <p>{{ selectedUser.name }} {{ "CONVERSATION" | translate }}</p>
        </div>
        <ng-container *ngIf="selectedUser.transcript && selectedUser.transcript.length !== 0">
          <app-common-conversation
            [userDetails]="selectedUser"
            [transcript]="selectedUser.transcript"
            [botIcon]="botIcon"
            (dateLabel)="changeLabel($event)"
            [parentComponent]="'user-profile'"
          ></app-common-conversation>
        </ng-container>
      </div>
    </div>
  </div>
</div>
<div *ngIf="isDataLoading" class="d-flex align-items-center just-center w-100 h-100">
  <app-loader></app-loader>
</div>
