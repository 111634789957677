<div class="language-percentage-container">
  <div class="language-percentage-block ml-20" *ngFor="let card of analyticsCardData">
    <div>
      <div class="language-percentage" *ngIf="card.value || card.value === 0">{{ card.value }}</div>
      <div class="language-percentage" *ngIf="card.percentage || card.percentage === 0">{{ card.percentage }}%</div>
      <div class="language-name">
        {{ card.name | translate }}
      </div>
    </div>

    <div class="language-image-circle" [ngStyle]="{ 'background-color': '#' + card.bgColor ? card.bgColor : 'fff' }">
      <img [src]="isImagePathFull ? card.imageName : 'assets/images/' + card.imageName" alt="" />
    </div>
  </div>
</div>
