import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "ordinal" })
export class OrdinalPipe implements PipeTransform {
  transform(int) {
    switch (int % 10) {
      case 1:
        return int + "st";
      case 2:
        return int + "nd";
      case 3:
        return int + "rd";
      default:
        return int + "th";
    }
  }
}
