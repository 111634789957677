export enum languageImageEnum {
  "da" = "assets/images/denmark.svg",
  "en-au" = "assets/images/australia.svg",
  "en-us" = "assets/images/united-states.svg",
  "en-ca" = "assets/images/canada.svg",
  "en-gb" = "assets/images/united-kingdom.svg",
  "en-in" = "assets/images/india.svg",
  "fr" = "assets/images/france.svg",
  "fr-ca" = "assets/images/canada.svg",
  "fr-fr" = "assets/images/france.svg",
  "de" = "assets/images/germany.svg",
  "hi" = "assets/images/india.svg",
  "nl" = "assets/images/netherlands.svg",
  "id" = "assets/images/indonesia.svg",
  "it" = "assets/images/italy.svg",
  "ja" = "assets/images/japan.svg",
  "no" = "assets/images/norway.svg",
  "pl" = "assets/images/poland.svg",
  "pt-br" = "assets/images/brazil.svg",
  "ru" = "assets/images/russia.svg",
  "es" = "assets/images/spain.svg",
  "es-419" = "assets/images/united-states.svg",
  "es-es" = "assets/images/spain.svg",
  "sv" = "assets/images/sweden.svg",
  "th" = "assets/images/thailand.svg",
  "tr" = "assets/images/turkey.svg",
  "uk" = "assets/images/ukraine.svg",
  "en" = "assets/images/united-kingdom.svg",
  "ko" = "assets/images/south-korea.svg",
  "pt" = "assets/images/portugal.svg",
  "zh-hk" = "assets/images/china.svg",
  "zh-cn" = "assets/images/portugal.svg",
  "zh-tw" = "assets/images/portugal.svg",
}
export enum GenerateLanguageEnum {
  "zh-hk" = "Chinese - Cantonese",
  "zh-cn" = "Chinese - Simplified",
  "zh-tw" = "Chinese - Traditional",
  "da" = "Danish",
  "nl" = "Dutch",
  "en" = "English",
  "en-au" = "English - Australia",
  "en-ca" = "English - Canada",
  "en-gb" = "English - Great Britain",
  "en-in" = "English - India",
  "en-us" = "English - US",
  "fr" = "French",
  "fr-ca" = "French - Canada",
  "fr-fr" = "French - France",
  "de" = "German",
  "hi" = "Hindi",
  "id" = "Indonesian",
  "it" = "Italian",
  "ja" = "Japanese",
  "ko" = "Korean",
  "no" = "Norwegian",
  "pl" = "Polish",
  "pt" = "Portuguese - Portugal",
  "pt-br" = "Portuguese - Brazil",
  "ru" = "Russian",
  "es" = "Spanish",
  "es-419" = "Spanish - Latin America",
  "es-es" = "Spanish - Spain",
  "sv" = "Swedish",
  "th" = "Thai",
  "tr" = "Turkish",
  "uk" = "Ukrainian",
}
