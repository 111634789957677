import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { NOTIFICATION_MODULE_NAME_CONST } from "src/app/core/consts/common.const";
import { AuthService } from "../../core/services/auth.service";
import { CommonService } from "../../core/services/common.service";
import { TranslationService } from "../../core/services/translation.service";
import { UserProfileModel } from "../../core/models/user-profile";

@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./../reset-password/reset-password.component.scss"],
})
export class ProfileComponent implements OnInit {
  @ViewChild("uploadProfile") uploadProfile: ElementRef;
  profileSettingForm;
  isDeleteUser = false;
  deleteUserForm;
  isconfirmUser = false;
  userId;
  email;
  selectedLanguage = "en";
  profileImage;
  NOTIFICATION_MODULE_NAME_CONST = NOTIFICATION_MODULE_NAME_CONST;
  notificationModule = Object.keys(NOTIFICATION_MODULE_NAME_CONST);
  isDisable = false;

  constructor(
    readonly _authService: AuthService,
    readonly _translationService: TranslationService,
    readonly _commonService: CommonService
  ) {}

  ngOnInit() {
    const notificationConfigForm = this.notificationModule.reduce((map, current) => {
      map[current] = new FormGroup({
        isEnable: new FormControl({ value: false }),
        isSound: new FormControl({ value: false }),
      });
      return map;
    }, {});
    this.profileSettingForm = new FormGroup({
      email: new FormControl({ value: "", disabled: true }),
      name: new FormControl("", Validators.required),
      lastname: new FormControl("", Validators.required),
      phoneNumber: new FormControl(""),
      notificationConfig: new FormGroup(notificationConfigForm),
    });
    this.deleteUserForm = new FormGroup({
      delete: new FormControl("", Validators.required),
    });

    this.setProfile();
    this.onChanges();
  }

  onChanges(): void {
    this.notificationModule.forEach((notificationType) => {
      this.profileSettingForm.controls.notificationConfig.controls[notificationType].get("isEnable").valueChanges.subscribe({
        next: (valueChange) => {
          if (this.profileSettingForm.controls.notificationConfig.controls[notificationType].controls.isEnable.value) {
            const setValue = notificationType === "handoff";
            this.profileSettingForm.controls.notificationConfig.controls[notificationType].controls.isSound.setValue(setValue);
            this.profileSettingForm.controls.notificationConfig.controls[notificationType].controls.isSound.enable();
          } else {
            this.profileSettingForm.controls.notificationConfig.controls[notificationType].controls.isSound.setValue(false);
            this.profileSettingForm.controls.notificationConfig.controls[notificationType].controls.isSound.disable();
          }
        },
      });
    });
  }

  checkMobileNumber(event) {
    const charCode = event.which ? event.which : event.keyCode;
    return !(charCode > 31 && (charCode < 48 || charCode > 57));
  }

  getUserDetails() {
    const userData = {
      name: {
        first: this.profileSettingForm.value.name,
        last: this.profileSettingForm.value.lastname,
      },
      phoneNumber: this.profileSettingForm.value.phoneNumber,
      bmsLanguage: this.selectedLanguage,
      profileImage: this.profileImage,
      notificationConfig: {},
    };
    userData.notificationConfig = this.notificationModule.reduce((map, current) => {
      map[current] = {
        isEnable: this.profileSettingForm.controls.notificationConfig.controls[current].value.isEnable,
        isSound: this.profileSettingForm.controls.notificationConfig.controls[current].value.isSound,
      };
      return map;
    }, {});
    return userData;
  }

  UpdateProfile() {
    if (this.profileSettingForm.valid) {
      if (this.profileSettingForm.value.phoneNumber) {
        if (this.profileSettingForm.value.phoneNumber.length < 4) {
          this._commonService.errorHandler("PLEASE_ENTER_VALID_MOBILE_NUMBER");
        } else {
          this.isDisable = true;
          const data = {
            email: this.profileSettingForm.value.email,
            ...this.getUserDetails(),
          };
          this.userId = this._authService.getUser().userId;
          this._authService
            .updateUserDetailsById(data, this._authService.getUser().userId)
            .then((d: UserProfileModel) => {
              this._commonService.successNotification("PROFILE_UPDATED_SUCCESSFULLY");
              this.setLocalStorgeUser(d);
              this.setPanelLanguage();
              this.isDisable = false;
            })
            .catch((err) => {
              this._commonService.APIErrorHandler(err);
              this.isDisable = false;
            });
        }
      } else {
        this.isDisable = true;
        const data = {
          email: this.profileSettingForm.value.email,
          ...this.getUserDetails(),
        };
        this.userId = this._authService.getUser().userId;
        this._authService
          .updateUserDetailsById(data, this._authService.getUser().userId)
          .then((d: UserProfileModel) => {
            this._commonService.successNotification("PROFILE_UPDATED_SUCCESSFULLY");
            this.setLocalStorgeUser(d);
            this.setPanelLanguage();
            this.isDisable = false;
          })
          .catch((err) => {
            console.error(err);
            this._commonService.APIErrorHandler(err);
            this.isDisable = false;
          });
      }
    } else {
      if (!this.profileSettingForm.value.name) {
        this._commonService.errorHandler("PLEASE_ENTER_FIRST_NAME");
      } else if (!this.profileSettingForm.value.lastname) {
        this._commonService.errorHandler("PLEASE_ENTER_LAST_NAME");
      }
    }
  }

  setLocalStorgeUser(data: UserProfileModel) {
    const userDetails = this._authService.getUser();
    userDetails.notificationConfig = data.notificationConfig;
    userDetails.bmsLanguage = data.bmsLanguage;
    userDetails.name.first = data.name.first;
    userDetails.name.last = data.name.last;
    userDetails.phoneNumber = data.phoneNumber;
    userDetails.profileImage = data.profileImage;
    this._authService.setUser(userDetails);
  }

  closeModal() {
    this.isDeleteUser = false;
    this.deleteUserForm.reset();
  }

  confirmDeleteUser() {
    if (this.deleteUserForm.valid) {
      if (this.deleteUserForm.value.delete === "delete" || this.deleteUserForm.value.delete === "Delete") {
        this.isDeleteUser = false;
        this.deleteUserForm.reset();
      } else {
        this.isDeleteUser = true;
        this.isconfirmUser = true;
      }
    } else {
      this.isDeleteUser = true;
      this.isconfirmUser = true;
    }
  }

  setProfile() {
    this._authService
      .getUserDetailsById()
      .then((res: any) => {
        const user = res.data;
        this.email = user.email;
        const notificationConfig = this.notificationModule.reduce((map, current) => {
          map[current] = {
            isEnable: user.notificationConfig[current].isEnable,
            isSound: user.notificationConfig[current].isSound,
          };
          return map;
        }, {});
        this.profileSettingForm.setValue({
          email: user.email,
          name: user.name,
          lastname: user.lastname,
          phoneNumber: user.phoneNumber,
          notificationConfig,
        });
        this.selectedLanguage = user.bmsLanguage ? user.bmsLanguage : "en";
        this.profileImage = user.profileImage;
        this.notificationModule.forEach((notificationType) => {
          if (!this.profileSettingForm.controls.notificationConfig.controls[notificationType].controls.isEnable.value) {
            this.profileSettingForm.controls.notificationConfig.controls[notificationType].controls.isSound.disable();
          }
        });
      })
      .catch((err) => {
        this._commonService.APIErrorHandler(err);
      });
  }

  setPanelLanguage() {
    this._translationService.setLanguageToTranslateService(this.selectedLanguage);
  }

  uploadProfileEvent(event: any) {
    const type: any = event.target.files[0].name.split(".");
    if (type[type.length - 1] === "png" || type[type.length - 1] === "jpg" || type[type.length - 1] === "jpge") {
      const fd = new FormData();
      const user = this._authService.getUser();
      fd.append("profileImage", event.target.files[0], user.userId + Date.now() + "." + type[type.length - 1]);
      this._authService
        .uploadProfile(fd)
        .then((res: any) => {
          this.profileImage = res.data.path;
          user.profileImage = this.profileImage;
          this.setLocalStorgeUser(user);
        })
        .catch((err) => {
          this._commonService.APIErrorHandler(err);
        });
    } else {
      this._commonService.errorHandler("PLEASE_UPLOAD_VALID_FILES");
    }
  }

  openFileUpload() {
    this.uploadProfile.nativeElement.click();
  }

  removeProfileImage() {
    this.profileImage = null;
    const user = this._authService.getUser();
    user.profileImage = "";
    this.setLocalStorgeUser(user);
  }
}
