<div class="modal-body selection_modal_body">
  <div class="main-body-content">
    <div class="folder-name-div" *ngIf="type === 'folder'">
      {{ moveFolder.folderName }}
    </div>
    <div class="folder-name-div" *ngIf="type === 'flow'">
      {{ flowName }}
    </div>
    <div class="folder-name-div" *ngIf="type === 'nlp' && moveFolder">
      {{ moveFolder.displayName }}
    </div>
    <div class="moveto-div d-flex heading-nav h-unset">
      <div class="small-heading">
        <span class="c-p">{{ "FOLDER" | translate }}</span>
      </div>
    </div>
    <ng-container *ngIf="subFolderNavList">
      <div class="d-flex">
        <span (click)="getAllFolderList()" class="sub-folder-name c-p">
          {{ "FOLDER" | translate }}
        </span>
        <span class="d-flex c-p outline-none keep-all" *ngFor="let subFolder of subFolderNavList; index as sFIndex">
          <img src="../../../../../assets/images/right-arrow.svg" class="img-fluid ml-3 mr-3" alt="" />
          <span (click)="backToParentFolder(sFIndex)" class="sub-folder-name">{{ subFolder.folderName }}</span>
        </span>
      </div>
    </ng-container>
    <div>
      <div *ngIf="displayFolders.length" class="main-folders-list p-10">
        <div
          class="display-folders"
          *ngFor="let folder of displayFolders; index as fIndex"
          [matTooltip]="folder.folderName"
          (click)="selectFolder(folder.folderName, fIndex)"
          [ngClass]="{
            notAllowed: (moveFolder && folder._id === moveFolder._id) || parentFolderIndex === fIndex,

            'selected-folder': selectedFolderIndex === fIndex
          }"
        >
          <div class="" id="folders" [class]="folder.class">
            <div class="icon-text">
              <p>{{ folder.folderName | shortName }}</p>
            </div>
          </div>
          <span>{{ folder.folderName }}</span>
        </div>
      </div>
    </div>
  </div>

  <div class="move-buttons d-flex justify-content-center">
    <ng-container>
      <button mat-flat-button color="primary" (click)="moveToFolder()" class="move-here">
        {{ "MOVE_HERE" | translate }}
      </button>
    </ng-container>
    <ng-container *ngIf="!disableAddFolder">
      <button
        *ngIf="hideFolderCount !== 2"
        mat-flat-button
        color="primary"
        class="createBtn createBtnForMove"
        (click)="openDialog(createFolderTemplate)"
      >
        <mat-icon aria-hidden="false" aria-label="add">add_circle_outline </mat-icon>
        {{ "FOLDER" | translate }}
      </button>
    </ng-container>
  </div>
</div>
<ng-template #createFolderTemplate>
  <modal-create-folder (createFolderEvent)="createFolder($event)"></modal-create-folder>
</ng-template>
