import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-no-module",
  templateUrl: "./no-module.component.html",
  styleUrls: ["./no-module.component.scss"],
})
export class NoModuleComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
