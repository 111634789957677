import { Component, EventEmitter, Inject, Input, OnInit, Output } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: "modal-delete-folder",
  templateUrl: "./delete-folder-modal.component.html",
  styleUrls: ["../modal-common.scss"],
})
export class DeleteFolderModalComponent {
  @Input() folderName;
  @Input() deleteList;
  @Input() title;
  @Output() deleteFolderEvent = new EventEmitter();

  constructor(public dialog: MatDialog) {}

  closeDialog() {
    this.dialog.closeAll();
  }

  deleteFolder() {
    this.deleteFolderEvent.emit();
  }
}
