import { ChannelHumanName, ChannelIcon, ChannelsEnum } from "../enums/common.enum";

export function getChannelInfo(channel, getName?): string {
  let displayName;
  let icon;
  switch (channel) {
    case ChannelsEnum.FACEBOOK:
      displayName = ChannelHumanName.FACEBOOK;
      icon = ChannelIcon.FACEBOOK;
      break;
    case ChannelsEnum.GOOGLE:
      displayName = ChannelHumanName.GOOGLE;
      icon = ChannelIcon.GOOGLE;
      break;
    case ChannelsEnum.WEBCHAT:
      displayName = ChannelHumanName.WEBCHAT;
      icon = ChannelIcon.WEBCHAT;
      break;
    case ChannelsEnum.TWILIO:
      displayName = ChannelHumanName.TWILIO;
      icon = ChannelIcon.TWILIO;
      break;
    case ChannelsEnum.TWILIOSMS:
      displayName = ChannelHumanName.TWILIOSMS;
      icon = ChannelIcon.TWILIOSMS;
      break;
    case ChannelsEnum.HANGOUTCHAT:
      displayName = ChannelHumanName.HANGOUTCHAT;
      icon = ChannelIcon.HANGOUTCHAT;
      break;
    case ChannelsEnum.WHATSAPP:
      displayName = ChannelHumanName.WHATSAPP;
      icon = ChannelIcon.WHATSAPP;
      break;
    case ChannelsEnum.VOXIMPLANT:
      displayName = ChannelHumanName.VOXIMPLANT;
      icon = ChannelIcon.VOXIMPLANT;
      break;
    case ChannelsEnum.MSTEAM:
      displayName = ChannelHumanName.MSTEAM;
      icon = ChannelIcon.MSTEAM;
      break;
    case ChannelsEnum.AZURE:
      displayName = ChannelHumanName.AZURE;
      icon = ChannelIcon.AZURE;
      break;
    case ChannelsEnum.SLACK:
      displayName = ChannelHumanName.SLACK;
      icon = ChannelIcon.SLACK;
      break;
    case ChannelsEnum.AUDIOCODES:
      displayName = ChannelHumanName.AUDIOCODES;
      icon = ChannelIcon.AUDIOCODES;
      break;
    case ChannelsEnum.DIALOG360:
      displayName = ChannelHumanName.DIALOG360;
      icon = ChannelIcon.DIALOG360;
      break;
    case ChannelsEnum.UNIVERSAL:
      displayName = ChannelHumanName.UNIVERSAL;
      icon = ChannelIcon.UNIVERSAL;
      break;
    case ChannelsEnum.INSTAGRAM:
      displayName = ChannelHumanName.INSTAGRAM;
      icon = ChannelIcon.INSTAGRAM;
      break;
    case ChannelsEnum.TELEGRAM:
      displayName = ChannelHumanName.TELEGRAM;
      icon = ChannelIcon.TELEGRAM;
      break;
  }
  if (getName) {
    return displayName;
  }
  return icon;
}
