import { Component, Inject, OnInit } from "@angular/core";
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from "@angular/material/snack-bar";
import { BotService } from "../../../services/bot.service";
import { TranslationService } from "../../../services/translation.service";
import { getChannelInfo } from "../../../helper/channel.helper";

@Component({
  selector: "app-snack-bar",
  templateUrl: "./snack-bar.component.html",
  styleUrls: ["./snack-bar.component.scss"],
})
export class SnackBarComponent implements OnInit {
  constructor(
    readonly translate: TranslationService,
    readonly botService: BotService,
    public snackBarRef: MatSnackBarRef<SnackBarComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: any
  ) {}

  get channelName() {
    return getChannelInfo(this.data.metaInfo.channelId, true);
  }

  async ngOnInit() {
    if (!this.translate.getTranslationLanguage()) {
      this.translate.setLanguageToTranslateService(JSON.parse(localStorage.getItem("user"))?.bmsLanguage);
    }
    this.data.header = await this.translate.getTranslate(this.data.header);
  }
  onActionBtnClick() {
    this.snackBarRef.dismiss();
    if (this.data?.isHandoffDetails) {
      this.botService.newHandoff.next({ ...this.data.notificationData.data });
    }
  }
}
