import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { routes } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { DragScrollModule } from "ngx-drag-scroll";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MaterialModule } from "./material.module";
import { LoginComponent } from "./login/login.component";
import { AuthService } from "./core/services/auth.service";
import { SearchService } from "./core/services/search.service";
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from "@angular/common/http";
import { AuthGuard } from "./core/guards/auth.guard";
import { RulesService } from "./core/services/rules.service";
import { FlexibleIndicatorService } from "./core/services/flexible-indicator.service";
import { TagsService } from "./core/services/tags.service";
import { TokenInterceptor } from "./core/interceptor/token.interceptor";
import { SettingService } from "./core/services/setting.service";
import { ToastrModule } from "ngx-toastr";
import { RouterModule } from "@angular/router";
import { SharedModule } from "./core/shared.module";
import { SocketIoConfig, SocketIoModule } from "ngx-socket-io";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { UsersService } from "./core/services/users.service";
import { NotifyService } from "./core/services/noyify.service";
import { BotService } from "./core/services/bot.service";
import { TranslationService } from "./core/services/translation.service";
import { APP_BASE_HREF, registerLocaleData } from "@angular/common";
import { CUSTOM_DATE_FORMATS, CustomDatePickerAdapter } from "./core/utils/date-adapter";
import { DateAdapter, MAT_DATE_FORMATS } from "@angular/material/core";
import { CommonService } from "./core/services/common.service";
import { environment } from "../environments/environment";
import { AngularFireMessagingModule } from "@angular/fire/messaging";
import { FirebaseService } from "./core/services/firebase.service";
import { AngularFireModule } from "@angular/fire";
import { SnackBarComponent } from "./core/components/common/snack-bar/snack-bar.component";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import localeEn from "@angular/common/locales/en";
import localeNl from "@angular/common/locales/nl";
import localeNlExtra from "@angular/common/locales/extra/nl";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
const config: SocketIoConfig = {
  url: environment.webSocketUrl,
};
registerLocaleData(localeEn, "en");
registerLocaleData(localeNl, "nl", localeNlExtra);
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [AppComponent, LoginComponent, SnackBarComponent],
  entryComponents: [SnackBarComponent],
  imports: [
    ScrollingModule,
    DragScrollModule,
    BrowserModule,
    RouterModule.forRoot(routes),
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    SharedModule,
    SocketIoModule.forRoot(config),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireMessagingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    AuthService,
    AuthGuard,
    UsersService,
    NotifyService,
    BotService,
    RulesService,
    SettingService,
    FlexibleIndicatorService,
    TagsService,
    SearchService,
    TranslationService,
    CommonService,
    FirebaseService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    { provide: DateAdapter, useClass: CustomDatePickerAdapter },
    { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
    { provide: APP_BASE_HREF, useValue: "/" },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
