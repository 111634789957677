<div class="header-bot-user-details text-center" *ngIf="IsProfile">
  <div class="images-details">
    <img *ngIf="selectedUser.profile" [src]="selectedUser.profile" alt="" />
    <p *ngIf="!selectedUser.profile" [attr.data-letters]="selectedUser.data_letters" class="user-name"></p>
    <h4>{{ selectedUser.name }}</h4>
    <p>
      <img [src]="channelIcon" alt="" width="30" />
      {{ channelName }}
    </p>
  </div>
  <ul>
    <li>
      <span> {{ "FIRST_SEEN" | translate }}</span>
      <p>{{ selectedUser.firstSeen | date: "d MMM y, h:mm:ss a" }}</p>
    </li>
    <li>
      <span> {{ "LAST_SEEN" | translate }}</span>
      <p>{{ selectedUser.lastSeen | date: "d MMM y, h:mm:ss a" }}</p>
    </li>
    <li>
      <span>{{ "LAST_90_DAYS" | translate }}</span>
      <p>{{ selectedUser.visit }} {{ "VISITS" | translate }}, {{ selectedUser.action }} {{ "ACTIONS" | translate }}</p>
    </li>
  </ul>
</div>

<div class="user-all-data-left" [ngClass]="{ IsProfileng: IsProfile }">
  <ul>
    <!--Tag Start-->
    <li class="light-blue border-radius-10 p-25">
      <div class="user-tag-title">
        <ul>
          <li>
            <h4>{{ "TAG" | translate }}</h4>
          </li>
        </ul>
      </div>
      <div class="user-tag-data">
        <ul class="flex-wrap mb-10">
          <li *ngFor="let tag of selectedUser.tags; let tagIndex = index">
            <div class="mb-10" [ngClass]="tag.className">
              {{ tag.tag }}
              <span class="closebtn" (click)="onTagSelect(true, tagIndex)">&times;</span>
            </div>
          </li>
          <li *ngIf="!_botService.isReviewer()">
            <app-add-button [buttonText]="'Add Tags'" class="d-flex" (click)="openDialog(selectTag)"></app-add-button>
          </li>
        </ul>
      </div>
    </li>
    <!--Tag End-->

    <!--User field Start-->
    <li class="p-25" *ngIf="userFi.length">
      <div class="user-fields">
        <ul>
          <li>
            <h4>{{ "USER_FIELDS" | translate }}</h4>
          </li>
          <li *ngIf="!_botService.isReviewer()">
            <img
              *ngIf="!isEditUserField && userFi.length"
              src="../../../../assets/images/icon-pen.svg"
              width="25"
              class="img-fluid w-auto icon-user c-p"
              alt=""
              (click)="editUserFields()"
            />
            <img
              *ngIf="isEditUserField"
              src="../../../../assets/images/save-icon.svg"
              width="25"
              class="img-fluid w-auto icon-user c-p"
              alt=""
              (click)="updateFI('userFi')"
            />
            <img
              *ngIf="isEditUserField"
              src="../../../../assets/images/close.svg"
              width="20"
              class="img-fluid ml-10 icon-user c-p"
              alt=""
              (click)="editUserFields()"
            />
          </li>
        </ul>
      </div>
      <div class="user-fields-data flex-wrap">
        <ul *ngFor="let userFi of userFi">
          <li>
            <div *ngIf="userFi.name !== 'transcriptUrl' || !isTranScriptURL">
              <img [src]="getUserFiIcons(userFi.name)" width="25" class="img-fluid w-auto icon" alt="" />
              <div class="content w-100">
                <p>{{ userFi.name }}</p>
                <h4
                  [popper]="info"
                  [popperTrigger]="'hover'"
                  [popperPlacement]="'bottom'"
                  [popperPreventOverflow]="false"
                  [popperPositionFixed]="false"
                  [popperApplyClass]="'custom-popper, fi-info'"
                  [popperApplyArrowClass]="'d-none'"
                  [popperHideOnMouseLeave]="false"
                  *ngIf="!isEditUserField && userFi.name !== 'timezone'"
                  class="text-muted text-ellipsis c-p"
                >
                  <a class="text-info" target="_blank" *ngIf="userFi.name === 'transcriptUrl'" [href]="userFi.value">{{
                    "CLICK_HERE" | translate
                  }}</a>
                  <div *ngIf="userFi.name !== 'transcriptUrl'">{{ userFi.value }}</div>
                  <popper-content #info>
                    <div class="p-5 fi_text">{{ userFi.value }}</div>
                  </popper-content>
                </h4>
                <div *ngIf="isEditUserField && userFi.name !== 'timezone'">
                  <input
                    class="input"
                    type="text"
                    [disabled]="!userFi.isEditableByAgent"
                    [(ngModel)]="userFi.value"
                    (keypress)="validateNumber($event, userFi.type)"
                    *ngIf="isEditUserField && (userFi.type === 'string' || userFi.type === 'number')"
                  />
                  <input
                    class="input"
                    type="text"
                    [disabled]="!userFi.isEditableByAgent"
                    [(ngModel)]="userFi.value"
                    *ngIf="isEditUserField && userFi.type === 'date'"
                    (click)="picker.open()"
                    [placeholder]="'SELECT_DATE' | translate"
                    readonly
                    [matDatepicker]="picker"
                  />
                  <input
                    class="input"
                    type="text"
                    [disabled]="!userFi.isEditableByAgent"
                    [(ngModel)]="userFi.value"
                    *ngIf="isEditUserField && userFi.type === 'dateAndTime'"
                    (click)="pickerTime.open()"
                    [placeholder]="'SELECT_DATE' | translate"
                    readonly
                    [owlDateTimeTrigger]="pickerTime"
                    [owlDateTime]="pickerTime"
                  />
                  <owl-date-time #pickerTime></owl-date-time>
                  <mat-datepicker #picker></mat-datepicker>
                  <div *ngIf="isEditUserField && userFi.type === 'boolean'">
                    <mat-select class="border-gray" [(ngModel)]="userFi.value">
                      <mat-option [value]="true">
                        {{ "TRUE" | translate }}
                      </mat-option>
                      <mat-option [value]="true">
                        {{ "FALSE" | translate }}
                      </mat-option>
                    </mat-select>
                  </div>
                </div>

                <div *ngIf="userFi.name === 'timezone'">
                  <mat-select [ngClass]="{ 'border-gray': isEditUserField }" [(ngModel)]="userFi.value" [disabled]="!isEditUserField">
                    <mat-option *ngFor="let timezone of timezoneList" [value]="timezone.value">
                      {{ timezone.displayName }}
                    </mat-option>
                  </mat-select>
                </div>
              </div>
            </div>
          </li>
          <li></li>
        </ul>
      </div>
    </li>
    <!--User Field End-->

    <!--emotional start-->

    <li class="light-blue border-radius-10 p-25" *ngIf="userExperienceFi.length">
      <div class="user-emotional-analytics">
        <ul class="d-flex align-items-center justify-content-between w-100">
          <li>
            <h4 class="m-0">{{ "EMOTION_ANALYTICS" | translate }}</h4>
          </li>
          <li *ngIf="!_botService.isReviewer()">
            <img
              *ngIf="!isEditEmotions && userExperienceFi.length"
              src="../../../../assets/images/icon-pen.svg"
              width="25"
              class="img-fluid w-auto icon-user c-p"
              alt=""
              (click)="editEmotionAnalytics()"
            />
            <img
              *ngIf="isEditEmotions"
              src="../../../../assets/images/save-icon.svg"
              width="25"
              class="img-fluid w-auto icon-user c-p"
              alt=""
              (click)="updateFI('emotions')"
            />
            <img
              *ngIf="isEditEmotions"
              src="../../../../assets/images/close.svg"
              width="20"
              class="img-fluid ml-10 icon-user c-p"
              alt=""
              (click)="editEmotionAnalytics()"
            />
          </li>
        </ul>
      </div>
      <div class="user-emotional-data flex-wrap other-fields-data">
        <ul *ngFor="let emotions of userExperienceFi">
          <li>
            <div class="content w-100">
              <p>{{ emotions.name | titlecase }}</p>
              <h4 *ngIf="!isEditEmotions" class="text-muted text-ellipsis c-p" [matTooltip]="emotions.value">
                {{ emotions.value }}
              </h4>
              <input
                class="input"
                type="text"
                [disabled]="!emotions.isEditableByAgent"
                [(ngModel)]="emotions.value"
                (keypress)="validateNumber($event, emotions.type)"
                *ngIf="isEditEmotions && (emotions.type === 'string' || emotions.type === 'number')"
              />
              <input
                class="input"
                type="text"
                [disabled]="!emotions.isEditableByAgent"
                [(ngModel)]="emotions.value"
                *ngIf="isEditEmotions && emotions.type === 'date'"
                (click)="picker.open()"
                [placeholder]="'SELECT_DATE' | translate"
                readonly
                [matDatepicker]="picker"
              />
              <input
                class="input"
                type="text"
                [disabled]="!emotions.isEditableByAgent"
                [(ngModel)]="emotions.value"
                *ngIf="isEditEmotions && emotions.type === 'dateAndTime'"
                (click)="pickerTime.open()"
                [placeholder]="'SELECT_DATE' | translate"
                readonly
                [owlDateTimeTrigger]="pickerTime"
                [owlDateTime]="pickerTime"
              />
              <owl-date-time #pickerTime></owl-date-time>
              <mat-datepicker #picker></mat-datepicker>
              <div *ngIf="isEditEmotions && emotions.type === 'boolean'">
                <mat-select class="border-gray" [(ngModel)]="emotions.value">
                  <mat-option [value]="true">
                    {{ "TRUE" | translate }}
                  </mat-option>
                  <mat-option [value]="true">
                    {{ "FALSE" | translate }}
                  </mat-option>
                </mat-select>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </li>
    <!--emotional end-->

    <!--Other field Start-->
    <li class="p-25" *ngIf="otherFi.length">
      <div class="other-fields">
        <ul>
          <li>
            <h4>{{ "OTHER_FIELDS" | translate }}</h4>
          </li>
          <li *ngIf="!_botService.isReviewer()">
            <img
              *ngIf="!isEditOtherFI && otherFi.length"
              src="../../../../assets/images/icon-pen.svg"
              width="25"
              class="img-fluid w-auto icon-user c-p"
              alt=""
              (click)="editOtherFields()"
            />
            <img
              *ngIf="isEditOtherFI"
              src="../../../../assets/images/save-icon.svg"
              width="25"
              class="img-fluid w-auto icon-user c-p"
              alt=""
              (click)="updateFI('otherFi')"
            />
            <img
              *ngIf="isEditOtherFI"
              src="../../../../assets/images/close.svg"
              width="20"
              class="img-fluid ml-10 icon-user c-p"
              alt=""
              (click)="editOtherFields()"
            />
          </li>
        </ul>
      </div>
      <div class="other-fields-data flex-wrap">
        <ul *ngFor="let field of otherFi">
          <li>
            <div>
              <p>{{ field.name }}</p>
              <h4 *ngIf="!isEditOtherFI" class="text-ellipsis c-p" [matTooltip]="field.value">
                {{ field.value }}
              </h4>
              <input
                type="text"
                class="input"
                maxlength="20"
                [disabled]="!field.isEditableByAgent"
                [(ngModel)]="field.value"
                *ngIf="
                  isEditOtherFI &&
                  field.type !== 'string' &&
                  field.type !== 'number' &&
                  field.type !== 'date' &&
                  field.type !== 'dateAndTime' &&
                  field.type !== 'boolean'
                "
              />

              <input
                class="input"
                type="text"
                [disabled]="!field.isEditableByAgent"
                [(ngModel)]="field.value"
                (keypress)="validateNumber($event, field.type)"
                *ngIf="isEditOtherFI && (field.type === 'string' || field.type === 'number')"
              />
              <input
                class="input"
                type="text"
                [disabled]="!field.isEditableByAgent"
                [(ngModel)]="field.value"
                *ngIf="isEditOtherFI && field.type === 'date'"
                (click)="picker.open()"
                [placeholder]="'SELECT_DATE' | translate"
                readonly
                [matDatepicker]="picker"
              />
              <input
                class="input"
                type="text"
                [disabled]="!field.isEditableByAgent"
                [(ngModel)]="field.value"
                *ngIf="isEditOtherFI && field.type === 'dateAndTime'"
                (click)="pickerTime.open()"
                [placeholder]="'SELECT_DATE' | translate"
                readonly
                [owlDateTimeTrigger]="pickerTime"
                [owlDateTime]="pickerTime"
              />
              <owl-date-time #pickerTime></owl-date-time>
              <mat-datepicker #picker></mat-datepicker>
              <div *ngIf="isEditOtherFI && field.type === 'boolean'">
                <mat-select class="border-gray" [(ngModel)]="field.value">
                  <mat-option [value]="true">
                    {{ "TRUE" | translate }}
                  </mat-option>
                  <mat-option [value]="true">
                    {{ "FALSE" | translate }}
                  </mat-option>
                </mat-select>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </li>

    <!--Other field End-->

    <!--NLP field Start-->
    <li class="p-25" *ngIf="nlpFi.length">
      <div class="nlp-analytics">
        <ul>
          <li>
            <h4 class="m-0">{{ "NLP_FIELDS" | translate }}</h4>
          </li>

          <li *ngIf="!_botService.isReviewer()">
            <img
              *ngIf="!isEditNLPField"
              src="../../../../assets/images/icon-pen.svg"
              width="25"
              class="img-fluid w-auto icon-user c-p"
              alt=""
              (click)="editNlpAnalytics()"
            />
            <img
              *ngIf="isEditNLPField"
              src="../../../../assets/images/save-icon.svg"
              width="25"
              class="img-fluid w-auto icon-user c-p"
              alt=""
              (click)="updateFI('nlpField')"
            />
            <img
              *ngIf="isEditNLPField"
              src="../../../../assets/images/close.svg"
              width="20"
              class="img-fluid ml-10 icon-user c-p"
              alt=""
              (click)="editNlpAnalytics()"
            />
          </li>
        </ul>
      </div>
      <div class="nlp-data flex-wrap" *ngIf="nlpFi.length">
        <ul *ngFor="let nlpField of nlpFi">
          <li>
            <div>
              <p>{{ nlpField.name }}</p>
              <h4 *ngIf="!isEditNLPField" class="text-ellipsis c-p" [matTooltip]="nlpField.value">
                {{ nlpField.value }}
              </h4>
              <input
                type="text"
                class="input"
                maxlength="20"
                [disabled]="!nlpField.isEditableByAgent"
                [(ngModel)]="nlpField.value"
                *ngIf="
                  isEditNLPField &&
                  nlpField.type !== 'string' &&
                  nlpField.type !== 'number' &&
                  nlpField.type !== 'date' &&
                  nlpField.type !== 'dateAndTime' &&
                  nlpField.type !== 'boolean'
                "
              />

              <input
                class="input"
                type="text"
                [disabled]="!nlpField.isEditableByAgent"
                [(ngModel)]="nlpField.value"
                (keypress)="validateNumber($event, nlpField.type)"
                *ngIf="isEditNLPField && (nlpField.type === 'string' || nlpField.type === 'number')"
              />
              <input
                class="input"
                type="text"
                [disabled]="!nlpField.isEditableByAgent"
                [(ngModel)]="nlpField.value"
                *ngIf="isEditNLPField && nlpField.type === 'date'"
                (click)="picker.open()"
                [placeholder]="'SELECT_DATE' | translate"
                readonly
                [matDatepicker]="picker"
              />
              <input
                class="input"
                type="text"
                [disabled]="!nlpField.isEditableByAgent"
                [(ngModel)]="nlpField.value"
                *ngIf="isEditNLPField && nlpField.type === 'dateAndTime'"
                (click)="pickerTime.open()"
                [placeholder]="'SELECT_DATE' | translate"
                readonly
                [owlDateTimeTrigger]="pickerTime"
                [owlDateTime]="pickerTime"
              />
              <owl-date-time #pickerTime></owl-date-time>
              <mat-datepicker #picker></mat-datepicker>
              <div *ngIf="isEditNLPField && nlpField.type === 'boolean'">
                <mat-select class="border-gray" [(ngModel)]="nlpField.value">
                  <mat-option [value]="true">
                    {{ "TRUE" | translate }}
                  </mat-option>
                  <mat-option [value]="true">
                    {{ "FALSE" | translate }}
                  </mat-option>
                </mat-select>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </li>

    <!--NLP field END-->
    <li class="p-25" *ngIf="selectedUser.noteOfPreviewUser">
      <div class="f-14 font-500">
        {{ "SUMMARY_NOTES" | translate }}
      </div>
      <div class="mt-10 f-13 p-14 note-text-area">
        {{ selectedUser.noteOfPreviewUser }}
      </div>
    </li>
  </ul>
</div>
<!--Add tag -->
<ng-template #selectTag>
  <div class="modal-header d-flex">
    <h4 class="m-0 dialog-header">
      {{ "ASSIGN_NEW_TAG" | translate }}
    </h4>
    <div class="ml-auto c-p" [mat-dialog-close]="true">
      <mat-icon class="material-icons move-close"> close </mat-icon>
    </div>
  </div>
  <div class="p-20 pb-0">
    <mat-form-field appearance="none" class="w-100">
      <mat-select
        [placeholder]="'SELECT_TAG' | translate"
        class="select-tag border-gray"
        [(ngModel)]="selectedTag"
        panelClass="tagSelectPanel"
      >
        <mat-option *ngIf="tagList.length === 0">{{ "NO_TAG_AVAILABLE" | translate }}</mat-option>
        <ng-container *ngFor="let tag of tagList">
          <mat-option *ngIf="!tag.isTrackable" [value]="tag._id"> {{ tag.tag }}</mat-option>
        </ng-container>
        <mat-option>
          <div class="p-8 c-p d-flex align-items-center" (click)="openDialog(createTags, 'createTag')">
            <mat-icon>add</mat-icon>
            {{ "ADD_TAG" | translate }}
          </div>
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <mat-dialog-actions class="d-flex just-center">
    <button class="mr-3" mat-button color="accent" [mat-dialog-close]="false" mat-dialog-close>
      {{ "CANCEL" | translate }}
    </button>

    <button class="mr-3" mat-button color="accent" (click)="onTagSelect(false)" mat-dialog-close>
      {{ "SAVE" | translate }}
    </button>
  </mat-dialog-actions>
</ng-template>
<!--Create New tag -->
<ng-template #createTags>
  <div class="modal-header d-flex">
    <h4 class="m-0 dialog-header">
      {{ "CREATE_NEW_TAG" | translate }}
    </h4>
    <div class="ml-auto c-p" [mat-dialog-close]="true">
      <mat-icon class="material-icons move-close"> close </mat-icon>
    </div>
  </div>
  <div class="p-20 pb-0">
    <div class="f-14 mb-5">{{ "ENTER_NAME" | translate }}</div>
    <form [formGroup]="tagForm">
      <input
        maxlength="20"
        class="input pl-10"
        matInput
        formControlName="tag"
        autocomplete="off"
        [placeholder]="'ENTER_NAME' | translate"
      />
    </form>
  </div>
  <mat-dialog-actions class="d-flex just-center">
    <button class="mr-3" mat-button color="accent" [mat-dialog-close]="false" mat-dialog-close>
      {{ "CANCEL" | translate }}
    </button>
    <button (click)="createTag()" mat-button color="accent" class="createBtn" type="button">
      {{ "CREATE" | translate }}
    </button>
  </mat-dialog-actions>
</ng-template>
