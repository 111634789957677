<div
  class="flow-main-search d-flex align-center just-space-bet"
  (clickOutside)="showSearchWrap = false"
  [ngClass]="{ 'search-main-box': showSearchWrap }"
>
  <input
    *ngIf="showSearchWrap"
    autofocus
    class="search-flow w-100"
    (keyup)="keyUpEvent($event)"
    (keydown)="doSearch($event)"
    [(ngModel)]="search"
    [placeholder]="placeholder"
  />
  <img
    src="../../../../../assets/images/search.svg"
    class="img-fluid mr-4 c-p"
    alt=""
    srcset=""
    (click)="showSearchWrap ? searchOnIcon() : ''; showSearchWrap = true"
  />
</div>
