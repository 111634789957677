<div class="badges">
  <div
    class="status f-12 line-18"
    [ngClass]="{
      success: status === 'success',
      warning: status === 'warning',
      danger: status === 'danger',
      stop: status === 'stop',
      completed: status === 'completed'
    }"
  >
    <p>{{ text }}</p>
  </div>
</div>
