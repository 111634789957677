<ng-container *ngIf="!isRecordsLoaded">
  <li class="loading-parent d-flex overflow-hidden mb-10" *ngFor="let row of rows" [class.inside-table]="insideTable">
    <div
      class="ghost loading"
      *ngFor="let item of columns; let last = last; let index = index"
      [class.mr-20]="!last"
      [class.last-action-cell]="last && lastAction"
      [style.width]="colsWidth[index] + '%'"
    ></div>
  </li>
</ng-container>
<p class="no-more-user" *ngIf="isRecordEnd">
  {{ "NO_MORE_RECORDS_FOUND" | translate }}
</p>
