import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Urls } from "../utils/urls";
import { AuthService } from "./auth.service";
import { IDownloadReport, IPaginateResults } from "../models/user-profile";

@Injectable()
export class UsersService {
  constructor(private http: HttpClient, private _creds: AuthService) {}
  searchKeyword;
  isSearch;
  filterSet: any;
  FiValue;
  channels;
  lastPage;
  getUserList(options, obj) {
    return new Promise((resolve, reject) => {
      return this.http.post(Urls.users + options, obj).subscribe(
        (data) => {
          resolve(data);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  getUserDetailsById(id) {
    return new Promise((resolve, reject) => {
      return this.http.get(Urls.users + "/" + id).subscribe(
        (data) => {
          resolve(data);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  getShowUserTranscriptDetailsById(id, page) {
    return new Promise((resolve, reject) => {
      return this.http.get(Urls.users + "/" + id + "/transcript?page=" + page + "&limit=50").subscribe(
        (data) => {
          resolve(data);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }
  deleteUserProfiling(id) {
    return new Promise((resolve, reject) => {
      return this.http.delete(Urls.gdpr + "dataDeletion/" + id).subscribe(
        (data) => {
          resolve(data);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }
  getFiData(id) {
    return new Promise((resolve, reject) => {
      return this.http.get(Urls.users + "/userFI/" + id).subscribe(
        (data) => {
          resolve(data);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }
  updateFi(RequestBody) {
    return new Promise((resolve, reject) => {
      return this.http.put(Urls.users + "/", RequestBody).subscribe(
        (data) => {
          resolve(data);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }
}
