import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { MatSelect } from "@angular/material/select";

@Component({
  selector: "app-add-mapping-popper",
  templateUrl: "./add-mapping-popper.component.html",
})
export class AddMappingPopperComponent implements OnInit {
  @Input() flowObject;
  @Input() step;
  @Input() flexibleIndicatorList;
  @Input() FIType: string;
  @Input() listId: string;
  @Output() addInputMappingBlank = new EventEmitter<any>();
  @Output() addInputMappingChanged = new EventEmitter<any>();
  @Output() scrollBottom = new EventEmitter<any>();
  @Output() breakContentSync = new EventEmitter<any>();
  @Output() openFIDialog = new EventEmitter<any>();
  @Output() deleteInputMapping = new EventEmitter<any>();
  @Output() closePopup = new EventEmitter<any>();
  @ViewChild("matSelect") matSelect: MatSelect;

  infoMessage: string;

  ngOnInit(): void {
    switch (this.FIType) {
      case "number": {
        this.infoMessage = "ONLY_NUMBER_FLEXIBLE_INDICATOR_SUPPORT";
        break;
      }
      case "dateAndTime": {
        this.infoMessage = "ONLY_DATE_AND_TIME_FLEXIBLE_INDICATOR_SUPPORT";
        break;
      }
      case "array": {
        this.infoMessage = "ONLY_ARRAY_FLEXIBLE_INDICATOR_SUPPORT";
        break;
      }
    }
  }

  /**
   * disable mat option click outside close for input mapping
   */
  removeBackDrop() {
    setTimeout(() => {
      const cdkBackDrop = document.getElementsByClassName("cdk-overlay-transparent-backdrop");
      for (let i = 0; i < cdkBackDrop.length; i++) {
        cdkBackDrop[i].classList.add("d-none");
      }
    }, 20);
  }
}
