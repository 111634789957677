import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

class DialogOverviewExampleDialog {}
@Component({
  selector: "app-dialog",
  templateUrl: "./dialog.component.html",
  styleUrls: ["../modal-common.scss"],
})
export class DialogComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<DialogOverviewExampleDialog>, @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit() {}

  dismissModal() {
    this.dialogRef.close();
  }
}
