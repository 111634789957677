<div class="modal-header">
  <p>{{ "SELECT_FLOW" | translate }}</p>
  <mat-icon class="material-icons move-close" (click)="dismissModal(dialogRef)"> close </mat-icon>
</div>
<div class="modal-body selection_modal_body position-relative">
  <div class="moveto-div d-flex heading-nav justify-content-between">
    <div class="small-heading">
      <span class="c-p" (click)="getFlowBuilderList()">{{ "FLOW" | translate }}</span>
      <ng-container *ngIf="displaySubFolderNavList">
        <span class="c-p outline-none" *ngFor="let subFolder of displaySubFolderNavList; index as sFIndex">
          {{ "  >  " }}<span (click)="backToParentDisplayFolder('anyBack', sFIndex)">{{ subFolder.folderName }}</span></span
        >
      </ng-container>
    </div>
    <div class="d-flex border-gray language-selection-div" *ngIf="_settingService.languages.length && _settingService.languages.length > 1">
      <img height="18px" width="18px" alt="" [src]="_settingService.getFlag(selectedLanguage)" />
      <mat-select class="pl-10" [(ngModel)]="selectedLanguage" (selectionChange)="getFlowBuilderList()">
        <mat-option [value]="language" *ngFor="let language of _settingService.languages; index as lIndex"
          ><img height="15px" width="15px" alt="" [src]="_settingService.getFlag(language)" />
          {{ _settingService.generateLanguage(language) }}
        </mat-option>
      </mat-select>
    </div>
  </div>
  <ng-container *ngIf="!isLoading">
    <div class="main-body-content small-height">
      <div class="folder-name-div">{{ "FOLDERS" | translate }}</div>
      <div>
        <div *ngIf="displayFolders.length !== 0" class="main-folders-list p-10">
          <div
            class="display-folders"
            *ngFor="let folder of displayFolders; index as fIndex"
            [matTooltip]="folder.folderName"
            (click)="openDisplaySubFolder(fIndex)"
          >
            <div id="folders" [class]="folder.class">
              <div class="icon-text">
                <p>{{ folder.folderName | shortName }}</p>
              </div>
            </div>
            <span>{{ folder.folderName }}</span>
          </div>
        </div>
      </div>
      <div class="folder-name-div">{{ "FLOW" | translate }}</div>
      <div>
        <div *ngIf="displayFlow.length !== 0" class="main-folders-list p-10">
          <ng-container *ngFor="let flow of displayFlow; index as iIndex">
            <div
              [matTooltip]="flow.flowName"
              class="display-folders"
              (click)="selectedFlowIndex = iIndex"
              [ngClass]="{
                'border-intent': displayFlow[selectedFlowIndex] !== undefined && flow['_id'] === displayFlow[selectedFlowIndex]._id
              }"
            >
              <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M19.601 1H6.92675C5.96154 1 5.17633 1.78521 5.17633 2.75042V31.2496C5.17633 32.2148 5.96154 33 6.92675 33H27.0736C28.0388 33 28.8241 32.2148 28.8241 31.2496V9.63934C28.8241 9.17198 28.642 8.73248 28.3116 8.40179L20.8191 1.49333C20.4908 1.1749 20.0593 1 19.601 1ZM26.636 30.812H7.36436V3.18802H17.8329V9.05718C17.8329 10.3532 18.8868 11.4077 20.1822 11.4077H26.636V30.812ZM20.1822 9.21968C20.0934 9.21968 20.0211 9.14674 20.0211 9.05718V4.45066L25.1218 9.21953L20.1822 9.21968ZM11.1378 13.9279H23.0261V16.1159H11.1378V13.9279ZM11.1378 17.5746H23.0261V19.7626H11.1378V17.5746ZM11.1378 21.2211H23.0261V23.4092H11.1378V21.2211ZM11.1378 24.8678H17.9207V27.0559H11.1378V24.8678Z"
                  fill="var(--primaryColor)"
                />
                <rect x="0.5" y="0.5" width="33" height="33" stroke="white" />
              </svg>
              <span>{{ flow.flowName }}</span>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="move-buttons">
      <button (click)="setSelection()" mat-button color="primary" class="createBtn primary-btn" [disabled]="selectedFlowIndex === null">
        {{ "SELECT" | translate }}
      </button>
    </div>
  </ng-container>
</div>
<div *ngIf="isLoading" class="d-flex align-items-center just-center w-100 h-100">
  <app-loader></app-loader>
</div>
