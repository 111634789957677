<div class="d-flex notification_container" [ngStyle]="{ 'border-color': data?.color }" (click)="onActionBtnClick()">
  <div class="d-flex align-items-center justify-content-center mr-10 snack-bar-icon" [ngClass]="[data.class]">
    <mat-icon class="material-icons" fontSet="material-icons-round">{{ data.icon }}</mat-icon>
  </div>
  <div class="snack-bar-content">
    <div class="snack-bar-header font-500 f-12 w-100">
      {{ data.header }}
    </div>
    <ng-container *ngIf="data && data.isHandoffDetails; else NODATA">
      <div class="snack-bar-details font-500 f-11 mt-6 c-p w-100">
        <ng-container *ngIf="data.metaInfo.tags.length">
          <div class="color-buttons d-flex">
            <ng-container *ngFor="let tag of data.metaInfo.tags">
              <div class="align-items-center justify-content-center button-color">{{ tag.tagName }}</div>
            </ng-container>
          </div>
        </ng-container>
        <div class="snack-bar-details font-500 f-12 mt-4 c-p w-100">
          <span class="pl-9 pr-9 snack-bar-meta-info">{{ data.metaInfo.time | date: "medium" }} </span>
          <span class="snack-bar-meta-info"
            ><img class="pr-2" alt="" src="assets/images/notification/flowIcon.svg" /> {{ channelName }}
          </span>
        </div>
      </div>
    </ng-container>
    <ng-template #NODATA>
      <div class="snack-bar-details font-500 f-11 mt-6 c-p w-100">
        {{ data.details }}
      </div>
    </ng-template>
  </div>
  <div class="snack-bar-close-icon">
    <mat-icon (click)="snackBarRef.dismiss()">close</mat-icon>
  </div>
</div>
