<div class="right-side--upper-block">
  <div class="d-flex align-center justify-content-between">
    <div class="d-flex overflow-auto w-55">
      <div class="main-heading c-p mr-0" (click)="backToParentFolder('oneBack')">
        {{ "FOLDER" | translate }}
      </div>
      <ng-container *ngIf="subFolderNavList">
        <span class="d-flex c-p outline-none keep-all" *ngFor="let subFolder of subFolderNavList; index as sFIndex">
          <img src="../../../../../assets/images/right-arrow.svg" class="img-fluid ml-3 mr-3" alt="" />
          <span (click)="backToParentFolder('anyBack', sFIndex)" class="sub-folder-name white-space-no-wrap">{{
            subFolder.folderName
          }}</span>
        </span>
      </ng-container>
    </div>
    <div
      class="d-flex border-gray language-selection-div ml-10"
      *ngIf="_settingService.languages.length && _settingService.languages.length > 1"
    >
      <img height="18px" width="18px" alt="" [src]="_settingService.getFlag(selectedLanguageFlowLanguage)" />
      <mat-select class="pl-10" [(ngModel)]="selectedLanguageFlowLanguage" (selectionChange)="getFlowBuilderList()">
        <mat-option [value]="language" *ngFor="let language of _settingService.languages; index as lIndex"
          ><img height="15px" width="15px" alt="" [src]="_settingService.getFlag(language)" />
          {{ _settingService.generateLanguage(language) }}
        </mat-option>
      </mat-select>
    </div>
  </div>
  <div class="folder-subfolder-container">
    <folder-subfolder
      [showBlock]="showFolderSubFolderBlock"
      [folders]="folders"
      [subFolderList]="subFolderList"
      (openSubFolderEvent)="openSubFolder($event)"
      (selectedFolderEvent)="setSelectedFolder($event)"
      [type]="'nlp'"
    ></folder-subfolder>
  </div>
</div>
<div class="right-side--lower-block overflow-hidden">
  <div class="new-chat-sub-title-row">
    <div class="page-subtitle">{{ "FLOW" | translate }}</div>

    <div class="d-flex align-center">
      <app-search [search]="search" [placeholder]="'SEARCH' | translate" (searchList)="searchList($event)"></app-search>
      <img
        class="view-icon mr-10"
        [ngClass]="{ 'view-selected': gridViewSelected }"
        src="../../../../../assets/images/grid.svg"
        alt=""
        (click)="selectGridView()"
      />

      <img
        class="ml-10 view-icon"
        [ngClass]="{ 'view-selected': !gridViewSelected }"
        src="../../../../../assets/images/list-icon.svg"
        alt=""
        (click)="selectListView()"
      />
    </div>
  </div>

  <!-- if grid view selected -->
  <ng-container *ngIf="gridViewSelected">
    <div class="flow-container">
      <div
        class="flow-grid-block"
        *ngFor="let flow of flowBuilders; index as flowIndex"
        [ngClass]="flow._id === currentSelectedFlow ? 'selected-flow' : ''"
        (click)="emitFlowSelectEvent(flow._id, flow.flowName)"
      >
        <img [src]="flow.thumbnail ? flow.thumbnail : 'assets/images/flow-thumb.png'" alt="No thumb Found" />
        <div class="grid-flow-detail">
          <div class="details">
            <div class="page-subtitle">
              {{ flow.flowName }}
            </div>
            <div>Edited {{ flow.updatedAt | date: "d MMM y":"":selectedLanguage }}</div>
          </div>
          <img [src]="channelIcon" alt="" />
        </div>
      </div>
    </div>
  </ng-container>

  <!-- if list view selected -->
  <ng-container class="flow-container" *ngIf="!gridViewSelected">
    <div class="flow-container-main">
      <div
        class="flow-list-view"
        *ngFor="let flow of flowBuilders; index as flowIndex"
        [ngClass]="flow._id === currentSelectedFlow ? 'selected-flow' : ''"
        (click)="emitFlowSelectEvent(flow._id, flow.flowName)"
      >
        <div class="grid-flow-detail">
          <div class="details">
            <div class="page-subtitle">
              {{ flow.flowName }}
            </div>
          </div>
          <img [src]="channelIcon" alt="" />
        </div>
      </div>
    </div>
  </ng-container>
</div>
