import { CHANNELS_TYPE } from "./live-chat";

export const userFiArray = [
  "name",
  "first_name",
  "last_name",
  "locale",
  "timezone",
  "gender",
  "contactNumber",
  "ipAddress",
  "browser",
  "device",
  "country",
  "city",
  "region",
  "latitude",
  "longitude",
  "metroCode",
  "transcriptUrl",
];

export const nlpFiArray = [
  "lastIntent",
  "user_prefer_language",
  "lastQuery",
  "entity1",
  "entity2",
  "entity3",
  "entity4",
  "entity5",
  "context1",
  "context2",
  "context3",
  "context4",
  "context5",
  "triggeredNLP",
  "nlpScore",
];

export const userExperienceArray = [
  "emotion",
  "mood",
  "openness",
  "neuroticism",
  "satisfaction",
  "extroversion",
  "agreeableness",
  "conscientiousness",
];

export const suggestionFI = [
  "timezone",
  "latitude",
  "longitude",
  "locale",
  "gender",
  "user_prefer_language",
  "browser",
  "device",
  "country",
  "city",
  "region",
  "lastIntent",
  "entity1",
  "entity2",
  "entity3",
  "entity4",
  "entity5",
];

export const countryList = {
  AD: "Andorra",
  AE: "United Arab Emirates",
  AF: "Afghanistan",
  AG: "Antigua and Barbuda",
  AI: "Anguilla",
  AL: "Albania",
  AM: "Armenia",
  AO: "Angola",
  AQ: "Antarctica",
  AR: "Argentina",
  AS: "American Samoa",
  AT: "Austria",
  AU: "Australia",
  AW: "Aruba",
  AX: "Åland Islands",
  AZ: "Azerbaijan",
  BA: "Bosnia and Herzegovina",
  BB: "Barbados",
  BD: "Bangladesh",
  BE: "Belgium",
  BF: "Burkina Faso",
  BG: "Bulgaria",
  BH: "Bahrain",
  BI: "Burundi",
  BJ: "Benin",
  BL: "Saint Barthelemy",
  BM: "Bermuda",
  BN: "Brunei Drusilla",
  BO: "Bolivia, Plurinational State of",
  BQ: "Bonaire, Sint Eustatius and Saba",
  BR: "Brazil",
  BS: "Bahamas",
  BT: "Bhutan",
  BV: "Bouvet Island",
  BW: "Botswana",
  BY: "Belarus",
  BZ: "Belize",
  CA: "Canada",
  CC: "Cocos (Keeling) Islands",
  CD: "Congo, Democratic Republic of the",
  CF: "Central African Republic",
  CG: "Congo",
  CH: "Switzerland",
  CI: "Côte d'Ivoire",
  CK: "Cook Islands",
  CL: "Chile",
  CM: "Cameroon",
  CN: "China",
  CO: "Colombia",
  CR: "Costa Rica",
  CU: "Cuba",
  CV: "Cabo Verde",
  CW: "Curaçao",
  CX: "Christmas Island",
  CY: "Cyprus",
  CZ: "Czechia",
  DE: "Germany",
  DJ: "Djibouti",
  DK: "Denmark",
  DM: "Dominica",
  DO: "Dominican Republic",
  DZ: "Algeria",
  EC: "Ecuador",
  EE: "Estonia",
  EG: "Egypt",
  EH: "Western Sahara",
  ER: "Eritrea",
  ES: "Spain",
  ET: "Ethiopia",
  FI: "Finland",
  FJ: "Fiji",
  FK: "Falkland Islands (Malvinas)",
  FM: "Micronesia, Federated States of",
  FO: "Faroe Islands",
  FR: "France",
  GA: "Gabon",
  GB: "United Kingdom of Great Britain and Northern Ireland",
  GD: "Grenada",
  GE: "Georgia",
  GF: "French Guiana",
  GG: "Guernsey",
  GH: "Ghana",
  GI: "Gibraltar",
  GL: "Greenland",
  GM: "Gambia",
  GN: "Guinea",
  GP: "Guadeloupe",
  GQ: "Equatorial Guinea",
  GR: "Greece",
  GS: "South Georgia and the South Sandwich Islands",
  GT: "Guatemala",
  GU: "Guam",
  GW: "Guinea-Bissau",
  GY: "Guyana",
  HK: "Hong Kong",
  HM: "Heard Island and McDonald Islands",
  HN: "Honduras",
  HR: "Croatia",
  HT: "Haiti",
  HU: "Hungary",
  ID: "Indonesia",
  IE: "Ireland",
  IL: "Israel",
  IM: "Isle of Man",
  IN: "India",
  IO: "British Indian Ocean Territory",
  IQ: "Iraq",
  IR: "Iran, Islamic Republic of",
  IS: "Iceland",
  IT: "Italy",
  JE: "Jersey",
  JM: "Jamaica",
  JO: "Jordan",
  JP: "Japan",
  KE: "Kenya",
  KG: "Kyrgyzstan",
  KH: "Cambodia",
  KI: "Kiribati",
  KM: "Comoros",
  KN: "Saint Kitts and Nevis",
  KP: "Korea, Democratic People's Republic of",
  KR: "Korea, Republic of",
  KW: "Kuwait",
  KY: "Cayman Islands",
  KZ: "Kazakhstan",
  LA: "Lao People's Democratic Republic",
  LB: "Lebanon",
  LC: "Saint Lucia",
  LI: "Liechtenstein",
  LK: "Sri Lanka",
  LR: "Liberia",
  LS: "Lesotho",
  LT: "Lithuania",
  LU: "Luxembourg",
  LV: "Latvia",
  LY: "Libya",
  MA: "Morocco",
  MC: "Monaco",
  MD: "Moldova, Republic of",
  ME: "Montenegro",
  MF: "Saint Martin, (French part)",
  MG: "Madagascar",
  MH: "Marshall Islands",
  MK: "North Macedonia",
  ML: "Mali",
  MM: "Myanmar",
  MN: "Mongolia",
  MO: "Macao",
  MP: "Northern Mariana Islands",
  MQ: "Martinique",
  MR: "Mauritania",
  MS: "Montserrat",
  MT: "Malta",
  MU: "Mauritius",
  MV: "Maldives",
  MW: "Malawi",
  MX: "Mexico",
  MY: "Malaysia",
  MZ: "Mozambique",
  NA: "Namibia",
  NC: "New Caledonia",
  NE: "Niger",
  NF: "Norfolk Island",
  NG: "Nigeria",
  NI: "Nicaragua",
  NL: "Netherlands",
  NO: "Norway",
  NP: "Nepal",
  NR: "Nauru",
  NU: "Niue",
  NZ: "New Zealand",
  OM: "Oman",
  PA: "Panama",
  PE: "Peru",
  PF: "French Polynesia",
  PG: "Papua New Guinea",
  PH: "Philippines",
  PK: "Pakistan",
  PL: "Poland",
  PM: "Saint Pierre and Miquelon",
  PN: "Pitcairn",
  PR: "Puerto Rico",
  PS: "Palestine, State of",
  PT: "Portugal",
  PW: "Palau",
  PY: "Paraguay",
  QA: "Qatar",
  RE: "Réunion",
  RO: "Romania",
  RS: "Serbia",
  RU: "Russian Federation",
  RW: "Rwanda",
  SA: "Saudi Arabia",
  SB: "Solomon Islands",
  SC: "Seychelles",
  SD: "Sudan",
  SE: "Sweden",
  SG: "Singapore",
  SH: "Saint Helena, Ascension and Tristan da Cunha",
  SI: "Slovenia",
  SJ: "Svalbard and Jan Mayen",
  SK: "Slovakia",
  SL: "Sierra Leone",
  SM: "San Marino",
  SN: "Senegal",
  SO: "Somalia",
  SR: "Suriname",
  SS: "South Sudan",
  ST: "Sao Tome and Principe",
  SV: "El Salvador",
  SX: "Sint Maarten, (Dutch part)",
  SY: "Syrian Arab Republic",
  SZ: "Eswatini",
  TC: "Turks and Caicos Islands",
  TD: "Chad",
  TF: "French Southern Territories",
  TG: "Togo",
  TH: "Thailand",
  TJ: "Tajikistan",
  TK: "Tokelau",
  TL: "Timor-Leste",
  TM: "Turkmenistan",
  TN: "Tunisia",
  TO: "Tonga",
  TR: "Turkey",
  TT: "Trinidad and Tobago",
  TV: "Tuvalu",
  TW: "Taiwan, Province of China",
  TZ: "Tanzania, United Republic of",
  UA: "Ukraine",
  UG: "Uganda",
  UM: "United States Minor Outlying Islands",
  US: "United States of America",
  UY: "Uruguay",
  UZ: "Uzbekistan",
  VA: "Holy See",
  VC: "Saint Vincent and the Grenadines",
  VE: "Venezuela, Bolivarian Republic of",
  VG: "Virgin Islands, British",
  VI: "Virgin Islands, U.S.",
  VN: "Viet Nam",
  VU: "Vanuatu",
  WF: "Wallis and Futuna",
  WS: "Samoa",
  YE: "Yemen",
  YT: "Mayotte",
  ZA: "South Africa",
  ZM: "Zambia",
  ZW: "Zimbabwe",
};

export const timeZoneList = [
  {
    displayName: "(UTC +14) LINT - Kiritimati",
    value: 14,
  },
  {
    displayName: "(UTC +13:45) CHADT - Chatham Islands",
    value: 13.75,
  },
  {
    displayName: "(UTC +13) NZDT - Auckland",
    value: 13,
  },
  {
    displayName: "(UTC +12) ANAT - Anadyr",
    value: 12,
  },
  {
    displayName: "(UTC +11) AEDT - Melbourne",
    value: 11,
  },
  {
    displayName: "(UTC +10:30)    ACDT - Adelaide",
    value: 10.5,
  },
  {
    displayName: "(UTC +10) AEST - Brisbane",
    value: 10,
  },
  {
    displayName: "(UTC +9:30) ACST - Darwin",
    value: 9.5,
  },
  {
    displayName: "(UTC +9) JST - Tokyo",
    value: 9,
  },
  {
    displayName: "(UTC +9:30) ACST - Darwin",
    value: 9.5,
  },
  {
    displayName: "(UTC +8:45) ACWST - Eucla",
    value: 8.75,
  },
  {
    displayName: "(UTC +8) CST - Beijing",
    value: 8,
  },
  {
    displayName: "(UTC +7) WIB - Jakarta",
    value: 7,
  },
  {
    displayName: "(UTC +6:30) MMT - Yangon",
    value: 6.5,
  },
  {
    displayName: "(UTC +6) BST - Dhaka",
    value: 6,
  },
  {
    displayName: "(UTC +5:45) NPT - Kathmandu",
    value: 5.75,
  },
  {
    displayName: "(UTC +5:30) IST - New Delhi",
    value: 5.5,
  },
  {
    displayName: "(UTC +5) UZT - Tashkent",
    value: 5,
  },
  {
    displayName: "(UTC +4:30) AFT - Kabul",
    value: 4.5,
  },
  {
    displayName: "(UTC +4) GST - Dubai",
    value: 4,
  },
  {
    displayName: "(UTC +3:30) IRST - Tehran",
    value: 3.5,
  },
  {
    displayName: "(UTC +3) MSK - Moscow",
    value: 3,
  },
  {
    displayName: "(UTC +2) EET - Cairo",
    value: 2,
  },
  {
    displayName: "(UTC +1) CET - Brussels",
    value: 1,
  },
  {
    displayName: "(UTC +0) GMT - London",
    value: 0,
  },
  {
    displayName: "(UTC -1) CVT - Praia",
    value: -1,
  },
  {
    displayName: "(UTC -2) GST - King Edward Point",
    value: -2,
  },
  {
    displayName: "(UTC -3) ART - Buenos Aires",
    value: -3,
  },
  {
    displayName: "(UTC -3:30) NST - St. John's",
    value: -3.5,
  },
  {
    displayName: "(UTC -4) VET - Caracas",
    value: -4,
  },
  {
    displayName: "(UTC -5) EST - New York",
    value: -5,
  },
  {
    displayName: "(UTC -6) CST - Mexico City",
    value: -6,
  },
  {
    displayName: "(UTC -7) MST - Calgary",
    value: -7,
  },
  {
    displayName: "(UTC -8) PST - Los Angeles",
    value: -8,
  },
  {
    displayName: "(UTC -9) AKST - Anchorage",
    value: -9,
  },
  {
    displayName: "(UTC -9:30) MART - Taiohae",
    value: -9.5,
  },
  {
    displayName: "(UTC -10) HST - Honolulu",
    value: -10,
  },
  {
    displayName: "(UTC -12) AoE - Baker Island",
    value: -11,
  },
  {
    displayName: "(UTC -12) AoE - Baker Island",
    value: -12,
  },
];
export interface IPaginateResults {
  docs: any;
  limit: number;
  page: number;
  pages: number;
  total: number;
}

export interface IDownloadReport {
  botUsers: Array<{
    FIs: Record<string, any>;
    botChannel: string;
    createdAt: Date;
    _id: string;
  }>;
  fieldsToConsider: Array<string>;
}

export interface IFlexibleIndicator {
  displayInProfile: boolean;
  enableMasking: boolean;
  field: string;
  isEditableByAgent: boolean;
  type: string;
  _id: string;
}

export interface ITag {
  isTrackable: boolean;
  tag: string;
  _id: string;
}
export interface ITagResponse {
  status: string;
  tags: ITag[];
}

export interface IFilterObject {
  botIds: string;
  filter: string;
  options?: {
    page: number;
    limit: number;
    sort: any;
  };
  isDownload?: boolean;
  channelIds: Array<CHANNELS_TYPE>;
  date: {
    endDate: string;
    startDate: string;
  };
  advanceFilter: Array<{
    type: string;
    key?: string;
    value: any;
    operator?: string;
  }>;
}

/**
 * IFIResponseFormat - bot user flexible indicators that define type,values,_id etc parameters
 */
export interface IFIResponseFormat {
  _id: string;
  field?: string;
  name?: string;
  value: any;
  isEditableByAgent: boolean;
  format?: string;
  type?: string;
}

export interface Name {
  first: string;
  last: string;
}

export interface Notification {
  isEnable: boolean;
  isSound: boolean;
}
export interface NotificationConfig {
  handoff: Notification;
  nlp: Notification;
  analytics: Notification;
}

export interface UserProfileModel {
  _id: string;
  name: Name;
  email: string;
  phoneNumber: string;
  bmsLanguage: string;
  isSideMenuOpen: boolean;
  profileImage: string;
  notificationConfig: NotificationConfig;
}
