import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { PasswordInputTypeEnum } from "../core/enums/login.enum";
import { AuthService } from "../core/services/auth.service";
import { NotifyService } from "../core/services/noyify.service";
import { FirebaseService } from "../core/services/firebase.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  forgetPassForm: FormGroup;
  resetPassForm: FormGroup;
  status = "login";
  returnUrl;
  remember;
  id;
  token;
  passwordInputTypeEnum = PasswordInputTypeEnum;
  enterPassword = false;
  reEnterPassword = false;
  enterPasswordType = PasswordInputTypeEnum.PASSWORD;
  reEnterPasswordType = PasswordInputTypeEnum.PASSWORD;

  constructor(
    readonly route: ActivatedRoute,
    readonly router: Router,
    readonly _notificationService: NotifyService,
    readonly _authService: AuthService
  ) {}

  ngOnInit() {
    this.remember = this._authService.getRememberMe();
    if (this.remember) {
      if (localStorage.getItem("user") && localStorage.getItem("token")) {
        this.router.navigate(["dashboard"]);
      } else {
        this.checkURLForResetPassword();
      }
    } else {
      localStorage.clear();
      this.checkURLForResetPassword();
    }
    this.initializationForms();
  }

  doLogin() {
    if (this.loginForm.valid) {
      const data = {
        FCMToken: FirebaseService.fcmToken,
      };
      const authorization = `Basic ${btoa(`${this.loginForm.value.email.toLowerCase()}:${this.loginForm.value.password}`)}`;
      this._authService
        .login(data, authorization)
        .then(() => {
          this.router.navigate(["dashboard"]);
        })
        .catch((err) => {
          this._notificationService.sendNotification(
            "error",
            err.error && err.error.description ? err.error.description : "Something went wrong!"
          );
        });
    } else {
      this._notificationService.sendNotification("error", "Please enter all the required details.");
    }
  }

  toggleRemember(event) {
    this.remember = event.checked;
    this._authService.setRememberMe(this.remember);
  }

  sendMail() {
    if (this.forgetPassForm.valid) {
      this._authService
        .forgotPassword(this.forgetPassForm.value)
        .then(() => {
          this._notificationService.sendNotification("success", "Reset link is sent to your Email!");
          this.router.navigate([""]);
        })
        .catch((error) => {
          const errorMessage = error.error && error.error.message ? error.error.message : "Internal Server Error";
          this._notificationService.sendNotification("error", errorMessage);
        });
    } else {
      this._notificationService.sendNotification("error", "Please Enter a valid Email.");
    }
  }

  resetPass() {
    if (this.resetPassForm.value.password.trim() && this.resetPassForm.value.confirmPassword.trim()) {
      if (this.resetPassForm.value.password === this.resetPassForm.value.confirmPassword) {
        const data = {
          password: this.resetPassForm.value.password.trim(),
        };
        this._authService
          .resetPassword(data, this.id)
          .then(() => {
            this._notificationService.sendNotification("success", "The Password is Reset Successfully.");
            this.router.navigate(["login"]);
          })
          .catch((error) => {
            const errorMessage = error.error && error.error.description ? error.error.description : "Internal Server Error";
            this._notificationService.sendNotification("error", errorMessage);
          });
        this.resetPassForm.reset();
      } else {
        this._notificationService.sendNotification("error", "Confirm Password does not matches.");
      }
    } else {
      this._notificationService.sendNotification("error", "Please enter proper password.");
    }
  }

  checkForEmail(form) {
    if (form === "forgot") {
      if (this.loginForm.controls.email.value.trim()) {
        this._authService.email = this.loginForm.controls.email.value.trim();
      }
    }
    if (form === "login") {
      if (this.forgetPassForm.controls.email.value.trim()) {
        this._authService.email = this.forgetPassForm.controls.email.value.trim();
      }
    }
  }

  checkURLForResetPassword() {
    if (this.route.snapshot.params.id && this.route.snapshot.routeConfig.path.split("/")[0] === "reset") {
      this.status = "reset";
      this.id = this.route.snapshot.params.id;
      this.token = this.route.snapshot.queryParams.token;
      this._authService
        .resetGetCall(this.id)
        .then()
        .catch(() => {
          this._notificationService.sendNotification("error", "Token is not valid or expired");
          this.router.navigate(["/", "login"]);
        });
    } else {
      this.returnUrl = this.route.snapshot.queryParams.returnUrl || "/";
    }
  }

  initializationForms() {
    this.loginForm = new FormGroup({
      email: new FormControl(this._authService.email ? this._authService.email : "", [Validators.required]),
      password: new FormControl("", [Validators.required]),
    });
    this.forgetPassForm = new FormGroup({
      email: new FormControl(this._authService.email ? this._authService.email : "", [Validators.required, Validators.email]),
    });
    this.resetPassForm = new FormGroup({
      password: new FormControl("", [Validators.required]),
      confirmPassword: new FormControl("", [Validators.required]),
    });
    if (this.router.url === "/forgot") {
      this.status = "forgot";
    }
  }

  showPassword(type: string): void {
    switch (type) {
      case "enter":
        this.enterPassword = !this.enterPassword;
        if (this.enterPassword) {
          this.enterPasswordType = this.passwordInputTypeEnum.TEXT;
        } else {
          this.enterPasswordType = this.passwordInputTypeEnum.PASSWORD;
        }
        break;
      case "reEnter":
        this.reEnterPassword = !this.reEnterPassword;
        if (this.reEnterPassword) {
          this.reEnterPasswordType = this.passwordInputTypeEnum.TEXT;
        } else {
          this.reEnterPasswordType = this.passwordInputTypeEnum.PASSWORD;
        }
    }
  }
}
