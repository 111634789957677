import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { IFolderViewConfig } from "../../models/folder.view";
import { IFolder, IFolderData, TFolderViewEvents } from "../../models/flow-list";
import { DialogComponent } from "../modals/dialog/dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { CommonService } from "../../services/common.service";
import { BotService } from "../../services/bot.service";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "app-folder-view",
  templateUrl: "./folder-view.component.html",
  styleUrls: ["./folder-view.component.scss"],
})
export class FolderViewComponent implements OnChanges {
  @Input() config: IFolderViewConfig;
  @Input() folderData: IFolderData;
  @Output() folderUpdate = new EventEmitter<any>();
  createFolderType: TFolderViewEvents["type"];
  constructor(
    public dialog: MatDialog,
    readonly _commonService: CommonService,
    readonly botService: BotService,
    readonly router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.folderData && changes.folderData.currentValue && changes.folderData.currentValue.folders) {
      this.setFolderColors(this.folderData.folders);
    }
    if (changes.folderData && changes.folderData.currentValue && changes.folderData.currentValue.subFolders) {
      this.setFolderColors(this.folderData.subFolders);
    }
  }

  openDialog(templateRef, type) {
    switch (type) {
      case "createFolder": {
        this.createFolderType = "createFolder";
        this.dialog.open(DialogComponent, {
          height: "auto",
          width: "600px",
          data: {
            title: "CREATE_FOLDER",
            template: templateRef,
            isShowHeader: true,
          },
        });
        break;
      }
      case "createSubFolder": {
        this.createFolderType = "createSubFolder";
        this.dialog.open(DialogComponent, {
          height: "auto",
          width: "600px",
          data: {
            title: "CREATE_SUB_FOLDER",
            template: templateRef,
            isShowHeader: true,
          },
        });
        break;
      }
    }
  }

  setFolderColors(folders) {
    folders.forEach((folder, i) => {
      if (i % 4 === 0) {
        folder.class = "yellow";
      } else if (i % 4 === 1) {
        folder.class = "red";
      } else if (i % 4 === 2) {
        folder.class = "green";
      } else {
        folder.class = "purple";
      }
    });
  }

  openFolder(folder, index) {
    if (this.router.url.includes("/dashboard/nlp")) {
      this.router.navigate(["dashboard", "nlp"], {
        queryParams: { inFolder: folder._id },
      });
    }
    if (folder.isOpen) {
      folder.isOpen = false;
      this.commonEventEmitter({
        type: "openFolder",
        openFolder: folder,
      });
      this.folderData.selectedSubFolder = null;
    } else {
      this.folderData.folders.forEach((currentFolder) => (currentFolder.isOpen = false));
      folder.isOpen = true;
      this.folderData.selectedFolder = folder;
      if (this.folderData.selectedSubFolder) {
        this.folderData.selectedSubFolder._id = "";
      }
      folder.index = index;
      this.commonEventEmitter({
        type: "openFolder",
        openFolder: folder,
      });
    }
  }

  createFolder(event) {
    let folder: TFolderViewEvents;
    switch (this.createFolderType) {
      case "createFolder": {
        folder = {
          type: "createFolder",
          createFolder: event,
        };
        break;
      }
      case "createSubFolder": {
        folder = {
          type: "createSubFolder",
          createSubFolder: event,
        };
        break;
      }
    }
    this.commonEventEmitter(folder);
    this.createFolderType = null;
    this.dialog.closeAll();
  }

  deleteFolder(folder, index, type) {
    folder.index = index;
    folder.type = type;
    switch (type) {
      case "folder": {
        const deleteFolderEventData = {
          type: "deleteFolder",
          deleteFolder: folder,
        } as const;
        this.commonEventEmitter(deleteFolderEventData);
        break;
      }
      case "subFolder": {
        const deleteFolderEventData = {
          type: "deleteSubFolder",
          deleteSubFolder: folder,
        } as const;
        this.commonEventEmitter(deleteFolderEventData);
        break;
      }
    }
  }

  onClickRenameFolder(type, index) {
    if (type === "parent") {
      this.folderData.folders[index].isRename = true;
      setTimeout(() => {
        document.getElementById("folder-" + index).focus();
      }, 10);
    } else {
      this.folderData.subFolders[index].isRename = true;
      setTimeout(() => {
        document.getElementById("subFolder-" + index).focus();
      }, 10);
    }
  }

  renameFolder(folder: IFolder, index: number, type: string) {
    if (folder.oldFolderName === folder.folderName) {
      folder.isRename = false;
      return;
    }
    folder.index = index;
    folder.type = type;
    switch (type) {
      case "renameFolder": {
        this.commonEventEmitter({
          type: "renameFolder",
          renameFolder: folder,
        });
        folder.isRename = false;
        break;
      }
      case "renameSubFolder": {
        this.commonEventEmitter({
          type: "renameSubFolder",
          renameSubFolder: folder,
        });
        folder.isRename = false;
        break;
      }
    }
  }

  moveFolder(folder, index, type) {
    folder.index = index;
    folder.type = type;
    switch (type) {
      case "moveFolder": {
        this.commonEventEmitter({
          type: "moveFolder",
          moveFolder: folder,
        });
        break;
      }
      case "moveSubFolder": {
        this.commonEventEmitter({
          type: "moveSubFolder",
          moveSubFolder: folder,
        });
        break;
      }
    }
  }

  openSubFolder(folder: IFolder, index: number) {
    folder.parentFolder = this.folderData.selectedFolder._id;
    folder.index = index;
    if (this.router.url.includes("/dashboard/nlp?inFolder")) {
      this.router.navigate(["dashboard", "nlp"], {
        queryParams: { inFolder: folder.parentFolder, inSubFolder: folder._id },
      });
    }
    this.commonEventEmitter({
      type: "openSubFolder",
      openSubFolder: folder,
    });
  }

  commonEventEmitter(eventObject: TFolderViewEvents) {
    this.folderUpdate.emit(eventObject);
  }
}
