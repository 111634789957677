import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-action-button",
  templateUrl: "./action-button.component.html",
  styleUrls: ["./action-button.component.scss"],
})
export class ActionButtonComponent {
  @Output() actionEvent = new EventEmitter<any>();

  getActionEvent(type: string): void {
    this.actionEvent.emit(type);
  }
}
