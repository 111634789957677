export enum FlowStepTypeEnum {
  TEXT = "text",
  QUESTION = "question",
  MEDIA = "media",
  CARD = "card",
  OPERATION = "operation",
  RANDOMIZE = "randomize",
  CAROUSELS = "carousels",
  DELAY = "delay",
  MCQ = "mcq",
  DIALOG = "dialog",
  HANDOFF = "handOff",
  FI = "FI",
  API = "api",
  EMOTION_DETECTION = "emotionDetection",
  CONDITION = "condition",
  SENTIMENT = "sentiment",
  DYNAMIC_BLOCK = "dynamicBlock",
  TRIGGER = "trigger", // default when none( of sms, whatsapp, email) is selected
  TRIGGER_WHATSAPP = "triggerWhatsapp", // karix whatsapp trigger
  TRIGGER_TWILIO_WHATSAPP = "triggerTwilioWhatsapp", // twilio whatsapp trigger
  TRIGGER_SMS = "triggerSms",
  TRIGGER_EMAIL = "triggerEmail",
  TRIGGER_DIALOG360 = "triggerDialog360",
  LOCATION = "location",
  LIST = "list",
  BUTTON_WITH_IMAGE = "buttonWithImage",
  SLIDER = "slider", // rating
  REVIEW = "review", // rating
  FORM = "form",
  MULTISELECT = "multiselect",
  CHECKLIST = "checklist",
  SCHEDULER = "scheduler",
  END_CONVERSATION = "endConversation",
  GOOGLE_PERMISSION = "googlePermission",
  GREETINGS = "greetings",
  TAG = "tag",
  BOOKING = "booking",
  CLEAR_FI = "clearFI",
  PROGRESSBAR = "progressBar",
  GOOGLE_SHEET = "googleSheet",
  VOICE_CONFIG = "voiceConfig",
  ACTION = "action",
  FLOW = "flow",
  IMAGE = "image",
  VIDEO = "video",
  AUDIO = "audio",
  ADD = "add",
  REMOVE = "remove",
  TEMPLATE = "template",
  INTERACTIVE_BUTTON = "interactiveButton",
  INTERACTIVE_LIST = "interactiveList",
  DYNAMIC_CAROUSEL = "dynamicCarousels",
  DYNAMIC_MEDIA = "dynamicMedia",
  DYNAMIC_QUICK_REPLY = "dynamicQuickReply",
  BROADCAST = "broadcast",
  EXTENSION = "extension",
  CONTACTINFO = "contactInfo",
  SELFDELETE = "selfDelete",
}

export enum stepTypeIconEnum {
  REVIEW = `<svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4.64786 9.0909H4.5251L4.54992 9.21112C4.9181 10.9942 6.51835 12.3383 8.42969 12.3383C10.3175 12.3383 11.8966 10.9932 12.2597 9.21087L12.2842 9.0909H12.1618H4.64786ZM3.80859 7.76797H13C13.3654 7.76797 13.6617 8.06424 13.6617 8.42969C13.6617 11.3145 11.3145 13.6617 8.42969 13.6617C5.53267 13.6617 3.14688 11.309 3.14688 8.42969C3.14688 8.06424 3.44315 7.76797 3.80859 7.76797Z" fill="#2A2B2E" stroke="white" stroke-width="0.2"/>
<path d="M6.90625 6.09453H5.38281C4.98975 6.09453 4.67109 5.77587 4.67109 5.38281C4.67109 4.98975 4.98975 4.67109 5.38281 4.67109H6.90625C7.29931 4.67109 7.61797 4.98975 7.61797 5.38281C7.61797 5.77587 7.29931 6.09453 6.90625 6.09453Z" fill="#2A2B2E" stroke="white" stroke-width="0.1"/>
<path d="M11.4766 6.09453H9.95312C9.56007 6.09453 9.24141 5.77587 9.24141 5.38281C9.24141 4.98975 9.56007 4.67109 9.95312 4.67109H11.4766C11.8696 4.67109 12.1883 4.98975 12.1883 5.38281C12.1883 5.77587 11.8696 6.09453 11.4766 6.09453Z" fill="#2A2B2E" stroke="white" stroke-width="0.1"/>
<path d="M17.5703 9.19141C17.3028 9.19141 17.0357 9.20486 16.7702 9.23005C16.7952 8.96655 16.8086 8.69964 16.8086 8.42969C16.8086 3.78153 13.0498 0 8.42969 0C3.79742 0 0 3.79829 0 8.42969C0 13.0498 3.78153 16.8086 8.42969 16.8086C8.69964 16.8086 8.96655 16.7952 9.23005 16.7702C9.20486 17.0357 9.19141 17.3028 9.19141 17.5703C9.19141 22.2185 12.9502 26 17.5703 26C22.2026 26 26 22.2017 26 17.5703C26 12.9502 22.2185 9.19141 17.5703 9.19141ZM1.52344 8.42969C1.52344 4.62155 4.62155 1.52344 8.42969 1.52344C12.2098 1.52344 15.2852 4.62155 15.2852 8.42969C15.2852 12.2098 12.2098 15.2852 8.42969 15.2852C4.62155 15.2852 1.52344 12.2098 1.52344 8.42969ZM17.5703 24.4766C13.7902 24.4766 10.7148 21.3784 10.7148 17.5703C10.7148 17.1992 10.7452 16.8293 10.804 16.4654C13.5182 15.6621 15.6621 13.5182 16.4654 10.804C16.8293 10.7452 17.1992 10.7148 17.5703 10.7148C21.3784 10.7148 24.4766 13.7902 24.4766 17.5703C24.4766 21.3784 21.3784 24.4766 17.5703 24.4766Z" fill="var(--primaryColor)"/>
<path d="M17.5703 9.19141C17.3028 9.19141 17.0357 9.20486 16.7702 9.23005C16.7952 8.96655 16.8086 8.69964 16.8086 8.42969C16.8086 3.78153 13.0498 0 8.42969 0C3.79742 0 0 3.79829 0 8.42969C0 13.0498 3.78153 16.8086 8.42969 16.8086C8.69964 16.8086 8.96655 16.7952 9.23005 16.7702C9.20486 17.0357 9.19141 17.3028 9.19141 17.5703C9.19141 22.2185 12.9502 26 17.5703 26C22.2026 26 26 22.2017 26 17.5703C26 12.9502 22.2185 9.19141 17.5703 9.19141ZM1.52344 8.42969C1.52344 4.62155 4.62155 1.52344 8.42969 1.52344C12.2098 1.52344 15.2852 4.62155 15.2852 8.42969C15.2852 12.2098 12.2098 15.2852 8.42969 15.2852C4.62155 15.2852 1.52344 12.2098 1.52344 8.42969ZM17.5703 24.4766C13.7902 24.4766 10.7148 21.3784 10.7148 17.5703C10.7148 17.1992 10.7452 16.8293 10.804 16.4654C13.5182 15.6621 15.6621 13.5182 16.4654 10.804C16.8293 10.7452 17.1992 10.7148 17.5703 10.7148C21.3784 10.7148 24.4766 13.7902 24.4766 17.5703C24.4766 21.3784 21.3784 24.4766 17.5703 24.4766Z" stroke="white"/>
<path d="M21.4492 21.1472C21.1552 21.4105 20.7054 21.3836 20.4441 21.0921L17.5703 18.382C19.0679 18.382 20.5018 19.0236 21.5042 20.1422C21.7665 20.4349 21.7419 20.8849 21.4492 21.1472Z" fill="#2A2B2E" stroke="white" stroke-width="0.1"/>
<path d="M16.0469 16.7586H14.5234C14.1304 16.7586 13.8117 16.4399 13.8117 16.0469C13.8117 15.6538 14.1304 15.3352 14.5234 15.3352H16.0469C16.4399 15.3352 16.7586 15.6538 16.7586 16.0469C16.7586 16.4399 16.4399 16.7586 16.0469 16.7586Z" fill="#2A2B2E" stroke="white" stroke-width="0.1"/>
<path d="M20.6172 16.7586H19.0938C18.7007 16.7586 18.382 16.4399 18.382 16.0469C18.382 15.6538 18.7007 15.3352 19.0938 15.3352H20.6172C21.0102 15.3352 21.3289 15.6538 21.3289 16.0469C21.3289 16.4399 21.0102 16.7586 20.6172 16.7586Z" fill="#2A2B2E" stroke="white" stroke-width="0.1"/>
</svg>`,
  SCHEDULER = `<svg width="23" height="20" viewBox="0 0 23 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.23385 9.69727H4.84857C4.48995 9.69727 4.19922 9.988 4.19922 10.3466C4.19922 10.7052 4.48995 10.996 4.84857 10.996H6.23385C6.59247 10.996 6.8832 10.7052 6.8832 10.3466C6.8832 9.988 6.59247 9.69727 6.23385 9.69727Z" fill="#2A2B2E"/>
<path d="M6.23385 12.4668H4.84857C4.48995 12.4668 4.19922 12.7575 4.19922 13.1161C4.19922 13.4748 4.48995 13.7655 4.84857 13.7655H6.23385C6.59247 13.7655 6.8832 13.4748 6.8832 13.1161C6.8832 12.7575 6.59247 12.4668 6.23385 12.4668Z" fill="#2A2B2E"/>
<path d="M6.23385 15.2383H4.84857C4.48995 15.2383 4.19922 15.529 4.19922 15.8876C4.19922 16.2462 4.48995 16.537 4.84857 16.537H6.23385C6.59247 16.537 6.8832 16.2462 6.8832 15.8876C6.8832 15.529 6.59247 15.2383 6.23385 15.2383Z" fill="#2A2B2E"/>
<path d="M11.7749 9.69727H10.3896C10.031 9.69727 9.74023 9.988 9.74023 10.3466C9.74023 10.7052 10.031 10.996 10.3896 10.996H11.7749C12.1335 10.996 12.4242 10.7052 12.4242 10.3466C12.4242 9.988 12.1335 9.69727 11.7749 9.69727Z" fill="#2A2B2E"/>
<path d="M11.7749 12.4668H10.3896C10.031 12.4668 9.74023 12.7575 9.74023 13.1161C9.74023 13.4748 10.031 13.7655 10.3896 13.7655H11.7749C12.1335 13.7655 12.4242 13.4748 12.4242 13.1161C12.4242 12.7575 12.1335 12.4668 11.7749 12.4668Z" fill="black"/>
<path d="M11.7749 15.2383H10.3896C10.031 15.2383 9.74023 15.529 9.74023 15.8876C9.74023 16.2462 10.031 16.537 10.3896 16.537H11.7749C12.1335 16.537 12.4242 16.2462 12.4242 15.8876C12.4242 15.529 12.1335 15.2383 11.7749 15.2383Z" fill="#2A2B2E"/>
<path d="M17.3164 9.69727H15.9311C15.5725 9.69727 15.2817 9.988 15.2817 10.3466C15.2817 10.7052 15.5725 10.996 15.9311 10.996H17.3164C17.675 10.996 17.9657 10.7052 17.9657 10.3466C17.9657 9.988 17.675 9.69727 17.3164 9.69727Z" fill="#2A2B2E"/>
<path d="M17.3164 12.4668H15.9311C15.5725 12.4668 15.2817 12.7575 15.2817 13.1161C15.2817 13.4748 15.5725 13.7655 15.9311 13.7655H17.3164C17.675 13.7655 17.9657 13.4748 17.9657 13.1161C17.9657 12.7575 17.675 12.4668 17.3164 12.4668Z" fill="#2A2B2E"/>
<path d="M17.3164 15.2383H15.9311C15.5725 15.2383 15.2817 15.529 15.2817 15.8876C15.2817 16.2462 15.5725 16.537 15.9311 16.537H17.3164C17.675 16.537 17.9657 16.2462 17.9657 15.8876C17.9657 15.529 17.675 15.2383 17.3164 15.2383Z" fill="#2A2B2E"/>
<path d="M20.2164 1.7316H18.658V0.649351C18.658 0.290736 18.3673 0 18.0087 0C17.65 0 17.3593 0.290736 17.3593 0.649351V1.7316H11.7316V0.649351C11.7316 0.290736 11.4409 0 11.0823 0C10.7236 0 10.4329 0.290736 10.4329 0.649351V1.7316H4.80519V0.649351C4.80519 0.290736 4.51446 0 4.15584 0C3.79723 0 3.50649 0.290736 3.50649 0.649351V1.7316H1.94805C0.873896 1.7316 0 2.6055 0 3.67965V18.0519C0 19.1261 0.873896 20 1.94805 20H20.2164C21.2906 20 22.1645 19.1261 22.1645 18.0519C22.1645 17.6328 22.1645 4.04437 22.1645 3.67965C22.1645 2.6055 21.2906 1.7316 20.2164 1.7316ZM1.2987 3.67965C1.2987 3.3216 1.59 3.0303 1.94805 3.0303H3.50649V4.11255C3.50649 4.47117 3.79723 4.76191 4.15584 4.76191C4.51446 4.76191 4.80519 4.47117 4.80519 4.11255V3.0303H10.4329V4.11255C10.4329 4.47117 10.7236 4.76191 11.0823 4.76191C11.4409 4.76191 11.7316 4.47117 11.7316 4.11255V3.0303H17.3593V4.11255C17.3593 4.47117 17.65 4.76191 18.0087 4.76191C18.3673 4.76191 18.658 4.47117 18.658 4.11255V3.0303H20.2164C20.5745 3.0303 20.8658 3.3216 20.8658 3.67965V6.23377H1.2987V3.67965ZM20.2164 18.7013H1.94805C1.59 18.7013 1.2987 18.41 1.2987 18.0519V7.53247H20.8658V18.0519C20.8658 18.41 20.5745 18.7013 20.2164 18.7013Z" fill="var(--primaryColor)"/>
  </svg>`,
  SLIDER = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 2.71875C0 2.20093 0.419678 1.78125 0.9375 1.78125H3.5625V0.9375C3.5625 0.419678 3.98218 0 4.5 0C5.01782 0 5.4375 0.419678 5.4375 0.9375V1.78125H23.0625C23.5803 1.78125 24 2.20093 24 2.71875C24 3.23657 23.5803 3.65625 23.0625 3.65625H5.4375V4.5C5.4375 5.01782 5.01782 5.4375 4.5 5.4375C3.98218 5.4375 3.5625 5.01782 3.5625 4.5V3.65625H0.9375C0.419678 3.65625 0 3.23657 0 2.71875ZM23.0625 20.3438H20.4375V19.5C20.4375 18.9822 20.0178 18.5625 19.5 18.5625C18.9822 18.5625 18.5625 18.9822 18.5625 19.5V20.3438H0.9375C0.419678 20.3438 0 20.7634 0 21.2812C0 21.7991 0.419678 22.2188 0.9375 22.2188H18.5625V23.0625C18.5625 23.5803 18.9822 24 19.5 24C20.0178 24 20.4375 23.5803 20.4375 23.0625V22.2188H23.0625C23.5803 22.2188 24 21.7991 24 21.2812C24 20.7634 23.5803 20.3438 23.0625 20.3438ZM23.0625 11.0625H12.9375V10.2188C12.9375 9.70093 12.5178 9.28125 12 9.28125C11.4822 9.28125 11.0625 9.70093 11.0625 10.2188V11.0625H0.9375C0.419678 11.0625 0 11.4822 0 12C0 12.5178 0.419678 12.9375 0.9375 12.9375H11.0625V13.7812C11.0625 14.2991 11.4822 14.7188 12 14.7188C12.5178 14.7188 12.9375 14.2991 12.9375 13.7812V12.9375H23.0625C23.5803 12.9375 24 12.5178 24 12C24 11.4822 23.5803 11.0625 23.0625 11.0625Z" fill="var(--primaryColor)"/>
<path d="M12.9375 11.0625H23.0625C23.5803 11.0625 24 11.4822 24 12C24 12.5178 23.5803 12.9375 23.0625 12.9375H12.9375V13.7812C12.9375 14.2991 12.5178 14.7188 12 14.7188C11.4822 14.7188 11.0625 14.2991 11.0625 13.7812V12.9375H0.9375C0.419678 12.9375 0 12.5178 0 12C0 11.4822 0.419678 11.0625 0.9375 11.0625H11.0625V10.2188C11.0625 9.70093 11.4822 9.28125 12 9.28125C12.5178 9.28125 12.9375 9.70093 12.9375 10.2188V11.0625Z" fill="var(--primaryColor)"/>
<path d="M23.0625 11.0625H12.9375V10.2188C12.9375 9.70093 12.5178 9.28125 12 9.28125C11.4822 9.28125 11.0625 9.70093 11.0625 10.2188V11.0625H0.9375C0.419678 11.0625 0 11.4822 0 12C0 12.5178 0.419678 12.9375 0.9375 12.9375H11.0625V13.7812C11.0625 14.2991 11.4822 14.7188 12 14.7188C12.5178 14.7188 12.9375 14.2991 12.9375 13.7812V12.9375H23.0625C23.5803 12.9375 24 12.5178 24 12C24 11.4822 23.5803 11.0625 23.0625 11.0625Z" fill="#2A2B2E"/>
<path d="M12.9375 11.0625H23.0625C23.5803 11.0625 24 11.4822 24 12C24 12.5178 23.5803 12.9375 23.0625 12.9375H12.9375V13.7812C12.9375 14.2991 12.5178 14.7188 12 14.7188C11.4822 14.7188 11.0625 14.2991 11.0625 13.7812V12.9375H0.9375C0.419678 12.9375 0 12.5178 0 12C0 11.4822 0.419678 11.0625 0.9375 11.0625H11.0625V10.2188C11.0625 9.70093 11.4822 9.28125 12 9.28125C12.5178 9.28125 12.9375 9.70093 12.9375 10.2188V11.0625Z" fill="#2A2B2E"/>
</svg>
`,
  PROGRESSBAR = `<?xml version="1.0"?>
<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:svgjs="http://svgjs.com/svgjs" version="1.1" width="24" height="24" x="0" y="0" viewBox="0 0 16 16" style="enable-background:new 0 0 512 512" xml:space="preserve" class=""><g>
<path xmlns="http://www.w3.org/2000/svg" fill="var(--primaryColor)" d="M0 5v6h16v-6h-16zM15 10h-14v-4h14v4z" data-original="#444444" style="" class=""/>
<path xmlns="http://www.w3.org/2000/svg" fill="var(--primaryColor)" d="M2 7h7v2h-7v-2z" data-original="#444444" style="" class=""/>
</g></svg>
`,
}
