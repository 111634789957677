import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormControl, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NotifyService } from "../../core/services/noyify.service";
import { AuthService } from "../../core/services/auth.service";
import { MatDialog } from "@angular/material/dialog";
import { TranslationService } from "../../core/services/translation.service";
import { SettingService } from "../../core/services/setting.service";
import { CommonService } from "../../core/services/common.service";

@Component({
  selector: "app-reset-password",
  templateUrl: "./reset-password.component.html",
  styleUrls: ["./reset-password.component.scss"],
})
export class ResetPasswordComponent implements OnInit {
  dialogRef;
  resetpassword;
  forgotPass = {
    oldPassword: "",
    newPassword: "",
    isLogoutOfOtherDevices: false,
  };
  confirmPassword;
  isforgotPass = false;
  forgotpassword;
  isClicked = false;
  forgotPasswordData = {
    email: "",
  };
  isDisable: boolean = false;

  constructor(
    readonly dialog: MatDialog,
    private _authService: AuthService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private _notificationService: NotifyService,
    private router: Router,
    private _translationService: TranslationService,
    private settingService: SettingService,
    private commonService: CommonService
  ) {}

  ngOnInit() {
    this.resetpassword = this.formBuilder.group({
      oldPassword: new FormControl("", [Validators.required]),
      newPassword: new FormControl("", [Validators.required]),
      confirmPassword: new FormControl("", [Validators.required]),
      isLogoutOfOtherDevices: new FormControl(false),
    });
    this.forgotpassword = this.formBuilder.group({
      forgotId: new FormControl("", [
        Validators.required,
        Validators.pattern(
          /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
        ),
      ]),
    });
  }

  async forgotPassword() {
    this.forgotPass.oldPassword = this.resetpassword.value.oldPassword;
    this.forgotPass.newPassword = this.resetpassword.value.newPassword;
    this.forgotPass.isLogoutOfOtherDevices = this.resetpassword.value.isLogoutOfOtherDevices;
    this.confirmPassword = this.resetpassword.value.confirmPassword;
    if (this.forgotPass.oldPassword.trim() && this.forgotPass.newPassword.trim() && this.confirmPassword.trim()) {
      if (this.resetpassword.valid) {
        if (this.forgotPass.newPassword === this.confirmPassword) {
          try {
            this.isDisable = true;
            await this._authService.setforgotPassword(this.forgotPass);
            this.commonService.successNotification("PASSWORD_RESET_SUCCESSFULLY");
            this.isDisable = false;
            if (this.resetpassword.value.isLogoutOfOtherDevices === true) {
              this.resetpassword.reset();
              this.router.navigate([""]);
              this._authService.logout();
            } else {
              this.resetpassword.reset();
              this.router.navigate(["dashboard/"]);
            }
          } catch (err) {
            this.commonService.APIErrorHandler(err);
            this.isDisable = false;
          }
        } else {
          this.commonService.errorHandler("CONFIRM_PASSWORD_DOES_NOT_MATCHES");
        }
      }
    } else {
      this.commonService.errorHandler("PLEASE_ENTER_PROPER_PASSWORD");
    }
  }

  openDialog(templateRef) {
    this.forgotpassword.reset();
    this.dialogRef = this.dialog.open(templateRef, {
      width: "600px",
    });
  }

  closeModal() {
    this.dialog.closeAll();
    this.isforgotPass = false;
    this.forgotpassword.reset();
  }

  async confirmPass(): Promise<void> {
    this.isClicked = true;
    this.forgotPasswordData.email = this._authService.getUser().email;
    try {
      await this._authService.forgotPassword(this.forgotPasswordData);
      this.commonService.successNotification("RESET_LINK_IS_SENT_TO_YOUR_EMAIL");
      this.logout();
      this.dialog.closeAll();
      this.isforgotPass = false;
      this.forgotpassword.reset();
    } catch (err) {
      this.commonService.APIErrorHandler(err);
    }
  }
  async logout(): Promise<void> {
    try {
      await this._authService.logout();
      this.dialog.closeAll();
      this.settingService.resetFlag();
      this.router.navigate(["login"]);
    } catch (err) {
      this.commonService.APIErrorHandler(err);
    }
  }
}
