import { Injectable, OnInit } from "@angular/core";
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from "@angular/common/http";

import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { Router } from "@angular/router";
import { FlexibleIndicatorService } from "../services/flexible-indicator.service";
import { AuthService } from "../services/auth.service";
import { BotService } from "../services/bot.service";
import { Urls } from "../utils/urls";

@Injectable()
export class TokenInterceptor implements HttpInterceptor, OnInit {
  constructor(
    public router: Router,
    private FiService: FlexibleIndicatorService,
    private authService: AuthService,
    private botservice: BotService
  ) {}

  ngOnInit(): void {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // while switching the bot , botid is passed in that route so it is excluded from interceptor
    if (request.url.includes(`${Urls.baseUrl}org/bots/details`)) {
      request = request.clone({
        setHeaders: {
          authorization: this.authService.getToken(),
        },
      });
      return next.handle(request);
    }

    if (
      !request.url.includes("directline/conversations") &&
      !request.url.includes("users/changeForgotPassword") &&
      !request.url.includes("login")
    ) {
      // default token
      let token = this.authService.getToken();
      let botid = this.botservice.getBot();

      // Set Token For Preview
      if (this.router.url.includes("preview") && localStorage.getItem("previewAuthToken")) {
        token = localStorage.getItem("previewAuthToken");
      }
      request = request.clone({
        setHeaders: {
          authorization: `${token}`,
          botId: botid,
        },
      });
    }
    return next.handle(request).pipe(
      tap(
        () => {},
        (err: any) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              this.authService
                .logout()
                .then(() => {
                  this.router.navigate(["login"]);
                })
                .catch((err) => {
                  this.router.navigate(["login"]);
                  localStorage.clear();
                });
            }
          }
        }
      )
    );
  }
}
