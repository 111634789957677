<ng-container *ngFor="let message of transcriptArr; index as i">
  <ng-container *ngIf="message.type === 'conversationDate'">
    <div class="d-flex align-center just-center sticky">
      <div
        class="conversation-date1"
        *ngIf="i === 0"
        [ngStyle]="{
          top: parentComponent === 'handoff' ? '160px' : '115px',
          opacity: isShowSticky ? '1' : '0'
        }"
      >
        {{ transcriptArr[0].date === "Today" ? transcriptArr[0].date : (transcriptArr[0].date | date: "d MMM y") }}
      </div>
      <div *ngIf="i !== 0" class="conversation-date mt-10" id="date0">
        {{ message.date === "Today" ? message.date : (message.date | date: "d MMM y") }}
      </div>
    </div>
  </ng-container>
  <!--Message from user-->
  <ng-container *ngIf="message.from === 'User'">
    <div class="w-80 d-flex just-start pt-5 pr-20">
      <div class="d-flex just-start align-end chat-send-msg-main pl-20 pt-40 align-end mb-35">
        <div class="chat-send-profile">
          <img height="30px" width="30px" [src]="userDetails.profile ? userDetails.profile : 'assets/images/demo_person.jpg'" alt="" />
        </div>
        <!--  message types start-->
        <!--  send type - text-->
        <ng-container *ngIf="message.type === 'text'">
          <div class="user-bubble position-relative">
            <div class="user-bubble-agent-msg">
              <span class="user-bubble-agent-msg-text">
                {{ !message?.isTranslate ? message?.text?.message : message?.translatedObj?.message }}
              </span>
              <span class="user-bubble-agent-time">{{ message.createdAt | date: "shortTime" }} </span>
            </div>
            <!-- Transalated type -->
            <div
              class="d-flex align-center position-absolute show-translate f-12 line-21"
              (click)="!message.isMasked ? (message.isTranslate = !message?.isTranslate) : false"
              *ngIf="commonService.isDeeplEnable && message.isTranslated"
            >
              <img src="../../../../assets/images/translate.svg" class="mr-6 translate-icon" alt="translate" />
              {{ message?.isTranslate ? ("SHOW_ORIGINAL_TEXT" | translate) : ("SHOW_TRANSLATED_TEXT" | translate) }}
            </div>
          </div>
        </ng-container>
        <!--  send type - text-->
        <ng-container *ngIf="message.type === 'date'">
          <div class="user-bubble">
            <div class="user-bubble-agent-msg">
              <span class="user-bubble-agent-msg-text">
                {{ message.isMasked ? message.maskedDateResponse : (message.dateResponse | date: "medium") }}
              </span>
              <span class="user-bubble-agent-time">{{ message.createdAt | date: "shortTime" }} </span>
            </div>
          </div>
        </ng-container>
        <!--  send type - multiselect-->
        <ng-container *ngIf="message.type === 'multiselect'">
          <div class="user-bubble">
            <div class="user-bubble-agent-msg" *ngIf="!message?.isTranslate">
              <span class="user-bubble-agent-msg-text">
                {{
                  !message?.isTranslate
                    ? message?.multiselect?.selectedChoices.join(",")
                    : message?.translatedObj?.selectedChoices.join(",")
                }}
              </span>
              <span class="user-bubble-agent-time">{{ message.createdAt | date: "shortTime" }} </span>
            </div>
            <!-- Transalated type -->
            <div
              class="d-flex align-center position-absolute show-translate f-12 line-21"
              (click)="!message.isMasked ? (message.isTranslate = !message?.isTranslate) : false"
              *ngIf="commonService.isDeeplEnable && message.isTranslated"
            >
              <img src="../../../../assets/images/translate.svg" class="mr-6 translate-icon" alt="translate" />
              {{ message?.isTranslate ? ("SHOW_ORIGINAL_TEXT" | translate) : ("SHOW_TRANSLATED_TEXT" | translate) }}
            </div>
          </div>
        </ng-container>
        <!--  send type - review-->
        <ng-container *ngIf="message.type === 'review'">
          <div class="user-bubble">
            <div class="user-bubble-agent-msg">
              <span class="user-bubble-agent-msg-text">
                {{ !message?.isTranslate ? message?.review?.text : message?.translatedObj?.text }}
              </span>
              <span class="user-bubble-agent-time">{{ message.createdAt | date: "shortTime" }} </span>
            </div>
            <!-- Transalated type -->
            <div
              class="d-flex align-center position-absolute show-translate f-12 line-21"
              (click)="!message.isMasked ? (message.isTranslate = !message?.isTranslate) : false"
              *ngIf="commonService.isDeeplEnable && message.isTranslated"
            >
              <img src="../../../../assets/images/translate.svg" class="mr-6 translate-icon" alt="translate" />
              {{ message?.isTranslate ? ("SHOW_ORIGINAL_TEXT" | translate) : ("SHOW_TRANSLATED_TEXT" | translate) }}
            </div>
          </div>
        </ng-container>
        <!--  send type - file-->
        <ng-container *ngIf="message.type === 'attachment'">
          <div class="user-bubble" *ngIf="message.attachment.docType !== 'audio' && message.attachment.docType !== 'video'">
            <div class="user-bubble-agent-msg">
              <div
                class="bot-bubble-agent-doc mb-10"
                *ngIf="
                  message.attachment.docType === 'pdf' ||
                  message.attachment.docType === 'ppt' ||
                  message.attachment.docType === 'doc' ||
                  message.attachment.docType === 'xls' ||
                  message.attachment.docType === 'other'
                "
              >
                <div class="d-flex align-center bot-bubble-agent-doc-detail">
                  <img
                    *ngIf="message.attachment.docType !== 'other'"
                    [alt]="message.type"
                    [src]="'assets/images/' + message.attachment.docType + '.svg'"
                  />
                  <mat-icon *ngIf="message.attachment.docType === 'other'">description</mat-icon>
                  <span>{{
                    message.attachment.contentUrl.split("/")[message.attachment.contentUrl.split("/").length - 1]
                      ? message.attachment.contentUrl.split("/")[message.attachment.contentUrl.split("/").length - 1]
                      : "Document"
                  }}</span>
                </div>
                <a
                  class="outline-none"
                  rel="noopener"
                  target="_blank"
                  download
                  [href]="message.attachment.contentUrl"
                  matTooltip="Click to download"
                >
                  <img src="../../../../assets/images/green-download.svg" alt="" class="img-fluid" />
                </a>
              </div>
              <div class="bot-bubble-agent-doc mb-10" *ngIf="message.attachment.docType === 'image'">
                <img
                  class="bot-bubble-agent-image"
                  [src]="message?.attachment?.contentUrl"
                  [ngStyle]="{
                    border: message.caption?.length ? 'none' : ''
                  }"
                  alt=""
                />
              </div>
              <span class="user-bubble-agent-time">{{ message.createdAt | date: "shortTime" }} </span>
            </div>
          </div>

          <ng-container *ngIf="message.attachment.docType === 'video'">
            <div [ngClass]="message.caption?.length ? 'receive-msg-card common-display-user-detail' : 'bot-bubble bot-bubble-image'">
              <div class="bot-bubble-agent-img">
                <video
                  class="bot-bubble-agent-image"
                  [ngClass]="message.caption?.length ? 'w-100' : ''"
                  controls
                  [src]="message?.attachment?.contentUrl"
                ></video>
                <div *ngIf="message.caption?.length" class="caption-css">
                  <span>
                    {{ message.caption }}
                  </span>
                  <span class="user-bubble-agent-time">{{ message.createdAt | date: "shortTime" }} </span>
                </div>
                <span *ngIf="!message.caption?.length" class="user-bubble-agent-time color-video-audio"
                  >{{ message.createdAt | date: "shortTime" }}
                </span>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="message.attachment.docType === 'audio'">
            <div class="bot-bubble bot-bubble-image">
              <div class="bot-bubble-agent-img">
                <audio class="audio-preview bot-bubble-agent-audio" controls *ngIf="message?.attachment?.contentUrl">
                  <source [src]="message?.attachment?.contentUrl" type="audio/mpeg" />
                  Your browser does not support the audio element.
                </audio>
                <span class="bot-bubble-agent-time right-27 bottom-1">{{ message.createdAt | date: "shortTime" }} </span>
              </div>
            </div>
          </ng-container>
        </ng-container>
        <!--  send type - file-->

        <!--  send type - slider-->
        <ng-container *ngIf="message.type === 'slider'">
          <div class="user-bubble">
            <div class="user-bubble-agent-msg">
              <span class="user-bubble-agent-msg-text">
                {{ !message?.isTranslate ? message?.slider?.text : message?.translatedObj?.text }}
              </span>
              <span class="user-bubble-agent-time">{{ message.createdAt | date: "shortTime" }} </span>
            </div>
            <!-- Transalated type -->
            <div
              class="d-flex align-center position-absolute show-translate f-12 line-21"
              (click)="!message.isMasked ? (message.isTranslate = !message?.isTranslate) : false"
              *ngIf="commonService.isDeeplEnable && message.isTranslated"
            >
              <img src="../../../../assets/images/translate.svg" class="mr-6 translate-icon" alt="translate" />
              {{ message?.isTranslate ? ("SHOW_ORIGINAL_TEXT" | translate) : ("SHOW_TRANSLATED_TEXT" | translate) }}
            </div>
          </div>
        </ng-container>
        <!--  message types end-->

        <ng-container *ngIf="message.type === 'location'">
          <div class="receive-msg-card common-display-user-detail">
            <a
              [href]="'https://www.google.com/maps/search/?api=1&query=' + message.location.latitude + ',' + message.location.longitude"
              target="_blank"
              matTooltip="Click to see location"
              class="bot-bubble-agent-img"
            >
              <img alt="" class="bot-bubble-agent-image" src="../../../../assets/images/location.png" />
              <div *ngIf="message.location.label">
                <a
                  [href]="
                    'https://www.google.com/maps/search/?api=1&query=' +
                    message.location.label +
                    '/@' +
                    message.location.latitude +
                    ',' +
                    message.location.longitude
                  "
                  target="_blank"
                  class="location-label"
                  >{{ message.location.label }}</a
                >
              </div>
              <p *ngIf="message.location.address" class="location-body">
                {{ message.location.address }}
              </p>
            </a>
          </div>
        </ng-container>

        <ng-container *ngIf="message.type === messageTypeEnum.CONTACTINFO">
          <div class="user-bubble">
            <div class="user-bubble-agent-msg">
              <div class="user-bubble-agent-msg-text">
                <div class="Contact">
                  <div class="Avatar d-flex align-center just-center white-space-no-wrap size-large no-photo">
                    {{ message.contactInfo.first_name.slice(0, 1) | uppercase
                    }}{{ message.contactInfo?.last_name?.slice(0, 1) | uppercase }}
                  </div>
                  <div class="contact-info p-1 white-space-no-wrap overflow-hidden pl-0">
                    <div class="contact-name mb-1 font-500">{{ message.contactInfo.first_name }} {{ message.contactInfo.last_name }}</div>
                    <div class="contact-phone font-500">{{ message.contactInfo.phone_number }}</div>
                  </div>
                </div>
              </div>
              <span class="bot-bubble-agent-time"> {{ message.createdAt | date: "shortTime" }}</span>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-container>
  <!--Message from bot-->
  <ng-container *ngIf="message.from === 'Bot' && message.type">
    <div class="w-100 d-flex just-end pt-10 pl-20">
      <div
        class="d-flex just-end align-items-start chat-send-msg-main pr-20 align-end mb-35"
        [ngStyle]="{ width: message.type === 'liveChatConfigMessage' ? '100%' : '' }"
      >
        <ng-container
          *ngIf="message.type === 'text' && (!message.text.buttons || (message.text.buttons && message.text.buttons.length === 0))"
        >
          <div class="bot-bubble">
            <div class="bot-bubble-agent-msg">
              <span
                class="bot-bubble-agent-msg-text"
                [innerHTML]="!message?.isTranslate ? message?.text?.message : message?.translatedObj?.message"
              >
              </span>
              <span class="bot-bubble-agent-time"> {{ message.createdAt | date: "shortTime" }}</span>
            </div>
            <!-- Transalated type -->
            <div
              class="d-flex align-center position-absolute show-translate f-12 line-21"
              (click)="message.isTranslate = !message?.isTranslate"
              *ngIf="commonService.isDeeplEnable && message.isTranslated"
            >
              <img src="../../../../assets/images/translate.svg" class="mr-6 translate-icon" alt="translate" />
              {{ message?.isTranslate ? ("SHOW_ORIGINAL_TEXT" | translate) : ("SHOW_TRANSLATED_TEXT" | translate) }}
            </div>
          </div>
        </ng-container>
        <!--  receive type - text-->
        <!--  receive type - schedule-->
        <ng-container *ngIf="message.type === 'scheduler'">
          <div class="bot-bubble">
            <div class="bot-bubble-agent-msg">
              <div>
                <span class="bot-bubble-agent-msg-text">
                  {{ !message?.isTranslate ? message?.scheduler?.dateText : message?.translatedObj?.dateText }} -
                  {{
                    !message?.isTranslate
                      ? (message?.scheduler?.selectedTime | date: "fullDate")
                      : (message?.translatedObj?.selectedTime | date: "fullDate")
                  }}
                </span>
              </div>
              <div>
                <span class="bot-bubble-agent-msg-text">
                  {{ !message?.isTranslate ? message?.scheduler?.timeText : message?.translatedObj?.timeText }} -
                  {{
                    !message?.isTranslate
                      ? (message?.scheduler?.selectedTime | date: "shortTime")
                      : (message?.translatedObj?.selectedTime | date: "shortTime")
                  }}
                </span>
              </div>

              <span class="bot-bubble-agent-time"> {{ message.createdAt | date: "shortTime" }}</span>
            </div>
            <!-- Transalated type -->
            <div
              class="d-flex align-center position-absolute show-translate f-12 line-21"
              (click)="message.isTranslate = !message?.isTranslate"
              *ngIf="commonService.isDeeplEnable && message.isTranslated"
            >
              <img src="../../../../assets/images/translate.svg" class="mr-6 translate-icon" alt="translate" />
              {{ message?.isTranslate ? ("SHOW_ORIGINAL_TEXT" | translate) : ("SHOW_TRANSLATED_TEXT" | translate) }}
            </div>
          </div>
        </ng-container>
        <!--  receive type - schedule-->
        <!--  receive type - text with buttons-->
        <ng-container *ngIf="message.type === 'text' && message.text.buttons && message.text.buttons.length !== 0">
          <div class="receive-msg-card position-relative common-display common-display-user-detail">
            <div class="bot-bubble border-top-left-radius-10">
              <div class="bot-bubble-agent-msg">
                <span class="user-bubble-agent-msg-text">
                  {{ !message?.isTranslate ? message?.text?.message : message?.translatedObj?.message }}
                </span>
              </div>
              <!-- Transalated type -->
            </div>
            <ng-container *ngIf="message?.text?.buttons && message?.text?.buttons.length !== 0">
              <div class="receive-msg-buttons">
                <button
                  class="receive-msg-button"
                  [ngClass]="{ 'receive-msg-image-button': button.mediaType }"
                  *ngFor="let button of message?.text?.buttons; index as bI"
                  mat-flat-button
                  color="primary"
                >
                  <ng-container *ngIf="button.mediaType; else onlyButtonTitle">
                    <img [src]="button.mediaURL" alt="button media" />
                    <div>
                      {{ !message?.isTranslate ? button.title : message.translatedObj?.buttons[bI].title }}
                    </div>
                  </ng-container>
                  <ng-template #onlyButtonTitle>
                    {{ button }}
                  </ng-template>
                </button>
              </div>
            </ng-container>
            <div
              class="d-flex align-center position-absolute show-translate f-12 line-21"
              (click)="message.isTranslate = !message?.isTranslate"
              *ngIf="commonService.isDeeplEnable && message.isTranslated"
            >
              <img src="../../../../assets/images/translate.svg" class="mr-6 translate-icon" alt="translate" />
              {{ message?.isTranslate ? ("SHOW_ORIGINAL_TEXT" | translate) : ("SHOW_TRANSLATED_TEXT" | translate) }}
            </div>
          </div>
        </ng-container>
        <!--  receive type - text with buttons-->
        <!--  receive type - image-->
        <ng-container *ngIf="message.type === 'image'">
          <div
            class="position-relative"
            [ngClass]="message.caption?.length ? 'receive-msg-card common-display-user-detail' : 'bot-bubble bot-bubble-image'"
          >
            <div class="bot-bubble-agent-img">
              <img
                class="bot-bubble-agent-image"
                [src]="message?.image?.url"
                [ngStyle]="{
                  border: message.caption?.length ? 'none' : ''
                }"
                alt=""
              />
              <div *ngIf="message.caption?.length" class="caption-css">
                <span>
                  {{ !message?.isTranslate ? message.caption : message.translatedObj.caption }}
                </span>
                <span class="user-bubble-agent-time">{{ message.createdAt | date: "shortTime" }} </span>
              </div>
              <span *ngIf="!message.caption?.length" class="user-bubble-agent-time color-video-audio"
                >{{ message.createdAt | date: "shortTime" }}
              </span>
              <div
                class="d-flex align-center position-absolute show-translate f-12 line-21"
                (click)="message.isTranslate = !message?.isTranslate"
                *ngIf="commonService.isDeeplEnable && message.isTranslated"
              >
                <img src="../../../../assets/images/translate.svg" class="mr-6 translate-icon" alt="translate" />
                {{ message?.isTranslate ? ("SHOW_ORIGINAL_TEXT" | translate) : ("SHOW_TRANSLATED_TEXT" | translate) }}
              </div>
            </div>
            <!-- Transalated type -->
          </div>
        </ng-container>
        <!--  receive type - image-->
        <!--  receive type - video-->
        <ng-container *ngIf="message.type === 'video'">
          <div
            class="position-relative"
            [ngClass]="message.caption?.length ? 'receive-msg-card common-display-user-detail' : 'bot-bubble bot-bubble-image'"
          >
            <div class="bot-bubble-agent-img">
              <video
                class="bot-bubble-agent-image"
                [ngClass]="message.caption?.length ? 'w-100' : ''"
                controls
                [src]="message?.video?.url"
              ></video>
              <div *ngIf="message.caption?.length" class="caption-css">
                <span>
                  {{ !message?.isTranslate ? message.caption : message.translatedObj.caption }}
                </span>
                <span class="user-bubble-agent-time">{{ message.createdAt | date: "shortTime" }} </span>
              </div>
              <span *ngIf="!message.caption?.length" class="user-bubble-agent-time color-video-audio"
                >{{ message.createdAt | date: "shortTime" }}
              </span>
              <div
                class="d-flex align-center position-absolute show-translate f-12 line-21"
                (click)="message.isTranslate = !message?.isTranslate"
                *ngIf="commonService.isDeeplEnable && message.isTranslated"
              >
                <img src="../../../../assets/images/translate.svg" class="mr-6 translate-icon" alt="translate" />
                {{ message?.isTranslate ? ("SHOW_ORIGINAL_TEXT" | translate) : ("SHOW_TRANSLATED_TEXT" | translate) }}
              </div>
            </div>
          </div>
        </ng-container>
        <!--  receive type - video-->
        <!--  receive type - audio-->
        <ng-container *ngIf="message.type === 'audio'">
          <div class="bot-bubble bot-bubble-image position-relative">
            <div class="bot-bubble-agent-img">
              <audio class="audio-preview bot-bubble-agent-audio" controls *ngIf="message?.audio?.url">
                <source [src]="message?.audio?.url" type="audio/mp3" />
                Your browser does not support the audio element.
              </audio>
              <span class="bot-bubble-agent-time right-27 bottom-1">{{ message.createdAt | date: "shortTime" }} </span>
            </div>
            <div
              class="d-flex align-center position-absolute show-translate f-12 line-21"
              (click)="message.isTranslate = !message?.isTranslate"
              *ngIf="commonService.isDeeplEnable && message.isTranslated"
            >
              <img src="../../../../assets/images/translate.svg" class="mr-6 translate-icon" alt="translate" />
              {{ message?.isTranslate ? ("SHOW_ORIGINAL_TEXT" | translate) : ("SHOW_TRANSLATED_TEXT" | translate) }}
            </div>
          </div>
        </ng-container>
        <!--  receive type - audio-->
        <!--  receive type - card-->
        <ng-container *ngIf="message.type === 'card'">
          <div class="receive-msg-card position-relative common-display-user-detail">
            <ng-container>
              <!--we are handle textWithbutton and card type in same  type card -->
              <img *ngIf="message?.card?.image_url?.url" [src]="message?.card?.image_url?.url" alt="" />
              <p class="receive-msg-title" [innerHTML]="!message?.isTranslate ? message?.card?.title : message?.translatedObj?.title"></p>
              <p
                class="receive-msg-subTitle"
                [innerHTML]="!message?.isTranslate ? message?.card?.subtitle : message?.translatedObj?.subtitle"
              ></p>
              <ng-container *ngIf="message.card && message.card.buttons && message.card.buttons.length !== 0">
                <div class="receive-msg-buttons">
                  <div
                    class="receive-msg-button"
                    *ngFor="let button of message.card.buttons; let bI = index"
                    mat-flat-button
                    color="primary"
                  >
                    {{ !message?.isTranslate ? button : message.translatedObj.buttons[bI] }}
                  </div>
                  <span class="bot-bubble-agent-time bot-bubble-agent-time-new mt-5">{{ message.createdAt | date: "shortTime" }} </span>
                </div>
              </ng-container>
            </ng-container>

            <!-- Transalated type -->
            <div
              class="d-flex align-center position-absolute show-translate f-12 line-21"
              (click)="message.isTranslate = !message?.isTranslate"
              *ngIf="commonService.isDeeplEnable && message.isTranslated"
            >
              <img src="../../../../assets/images/translate.svg" class="mr-6 translate-icon" alt="translate" />
              {{ message?.isTranslate ? ("SHOW_ORIGINAL_TEXT" | translate) : ("SHOW_TRANSLATED_TEXT" | translate) }}
            </div>
          </div>
        </ng-container>
        <!--  receive type - card-->
        <!--  receive type - carousel-->
        <ng-container *ngIf="message.type === 'carousel'">
          <div class="position-relative">
            <div class="d-flex align-center w-90 carousel-review-inner">
              <div class="carousel-navigation">
                <mat-icon aria-hidden="false" aria-label="left" (click)="updateIndexOfCarousel(i, 'prev', customCarousel)">
                  keyboard_arrow_left
                </mat-icon>
              </div>
              <drag-scroll #customCarousel class="drag-preview w-100" [drag-disabled]="true" [drag-scroll-disabled]="true">
                <div
                  drag-scroll-item
                  *ngFor="let car of message.carousel; let cIndex = index"
                  id="childCarousel{{ cIndex }}"
                  class="carousel-flex-div w-100"
                >
                  <div class="receive-msg-card position-relative common-display-user-detail w-92 mb-10">
                    <img [src]="car.image_url" alt="" />
                    <div>
                      <p class="receive-msg-title overflow-hidden text-overflow-ellipsis">
                        {{ !message?.isTranslate ? car.title : message.translatedObj[cIndex].title }}
                      </p>
                    </div>
                    <div>
                      <p class="receive-msg-subTitle overflow-hidden text-overflow-ellipsis">
                        {{ !message?.isTranslate ? car.subtitle : message.translatedObj[cIndex].subtitle }}
                      </p>
                    </div>
                    <ng-container *ngIf="car.buttons && car.buttons.length !== 0">
                      <div class="receive-msg-buttons d-grid">
                        <button class="mt-5" *ngFor="let button of car.buttons; index as bI" mat-flat-button color="primary">
                          {{ !message?.isTranslate ? button : message.translatedObj[cIndex].buttons[bI] }}
                        </button>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </drag-scroll>
              <div class="carousel-navigation">
                <mat-icon aria-hidden="false" aria-label="right" (click)="updateIndexOfCarousel(i, 'next', customCarousel)">
                  keyboard_arrow_right
                </mat-icon>
              </div>
            </div>
            <div
              class="d-flex align-center position-absolute show-translate f-12 line-21"
              (click)="message.isTranslate = !message?.isTranslate"
              *ngIf="commonService.isDeeplEnable && message.isTranslated"
            >
              <img src="../../../../assets/images/translate.svg" class="mr-6 translate-icon" alt="translate" />
              {{ message?.isTranslate ? ("SHOW_ORIGINAL_TEXT" | translate) : ("SHOW_TRANSLATED_TEXT" | translate) }}
            </div>
          </div>
        </ng-container>
        <!--  receive type - carousel-->
        <!--  receive type - Buttons with text-->
        <ng-container *ngIf="message.type === 'buttonWithImage'">
          <div class="position-relative">
            <ng-container>
              <div class="list-navigation list-navigation-left" *ngIf="message.buttonWithImage.button.length > 3">
                <mat-icon aria-hidden="false" aria-label="left" (click)="updateIndexOfCarousel(i, 'prev', buttonList)">
                  keyboard_arrow_left
                </mat-icon>
              </div>
              <drag-scroll #buttonList [drag-scroll-disabled]="true" class="drag-scroll-max-width">
                <div drag-scroll-item *ngFor="let btnImage of message.buttonWithImage.button; let cIndex = index">
                  <div class="p-10">
                    <div class="button--image__card">
                      <img alt="" [src]="btnImage.image" class="button--image__card__icon" />
                      <p class="button--image__card__title">
                        {{ !message?.isTranslate ? btnImage.title : message.translatedObj.button[cIndex].title }}
                      </p>
                    </div>
                  </div>
                </div>
              </drag-scroll>
              <div class="list-navigation list-navigation-right" *ngIf="message.buttonWithImage.button.length > 3">
                <mat-icon aria-hidden="false" aria-label="right" (click)="updateIndexOfCarousel(i, 'next', buttonList)">
                  keyboard_arrow_right
                </mat-icon>
              </div>
            </ng-container>
            <div
              class="d-flex align-center position-absolute show-translate f-12 line-21"
              (click)="message.isTranslate = !message?.isTranslate"
              *ngIf="commonService.isDeeplEnable && message.isTranslated"
            >
              <img src="../../../../assets/images/translate.svg" class="mr-6 translate-icon" alt="translate" />
              {{ message?.isTranslate ? ("SHOW_ORIGINAL_TEXT" | translate) : ("SHOW_TRANSLATED_TEXT" | translate) }}
            </div>
          </div>
        </ng-container>
        <!--  receive type - Buttons with text-->
        <!--  receive type - List-->
        <ng-container *ngIf="message.type === 'list'">
          <div class="position-relative">
            <ng-container>
              <ng-container *ngIf="message.list.listType === 'list'">
                <div class="list--text__container">
                  <div class="list--text__option" *ngFor="let list of message.list.listItem; let lIndex = index">
                    <p class="list--text__option__text">
                      {{ !message?.isTranslate ? list.title : message.translatedObj.listItem[lIndex].title }}
                    </p>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="message.list.listType === 'vertical'">
                <div class="list--vertical__container">
                  <div class="list--vertical__card" *ngFor="let option of message.list.listItem; let lIndex = index">
                    <img [src]="option.image" class="list--vertical__card__icon" alt="" />
                    <div class="list--vertical__card__details">
                      <p class="list--vertical__card__title">
                        {{ !message?.isTranslate ? option.title : message.translatedObj.listItem[lIndex].title }}
                      </p>
                      <p class="list--vertical__card__subtitle">
                        {{ !message?.isTranslate ? option.subTitle : message.translatedObj.listItem[lIndex].subTitle }}
                      </p>
                    </div>
                    <div class="list--vertical__card__selection">
                      <p class="list--vertical__card__text">
                        {{ !message?.isTranslate ? option.value : message.translatedObj.listItem[lIndex].value }}
                      </p>
                    </div>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="message.list.listType === 'horizontal'">
                <div class="list--horizontal__container">
                  <div
                    class="list-navigation list-navigation-left"
                    *ngIf="message.list.listItem.length > 3"
                    [ngStyle]="{ opacity: message.list.leftReach ? '0.5' : '1.0' }"
                  >
                    <mat-icon aria-hidden="false" aria-label="left" (click)="updateIndexOfCarousel(i, 'prev', horizontalList)">
                      keyboard_arrow_left
                    </mat-icon>
                  </div>
                  <drag-scroll #horizontalList [drag-scroll-disabled]="true" class="drag-scroll-max-width">
                    <div drag-scroll-item class="list--horizontal__card" *ngFor="let option of message.list.listItem; let lIndex = index">
                      <img alt="" [src]="option.image" class="list--horizontal__card__icon" />
                      <div class="list--horizontal__card__details">
                        <p class="list--horizontal__card__title">
                          {{ !message?.isTranslate ? option.title : message.translatedObj.listItem[lIndex].title }}
                        </p>
                        <p class="list--horizontal__card__text">
                          {{ !message?.isTranslate ? option.value : message.translatedObj.listItem[lIndex].value }}
                        </p>
                      </div>
                    </div>
                  </drag-scroll>
                  <div
                    class="list-navigation list-navigation-right"
                    [ngStyle]="{ opacity: message.list.rightReach ? '0.5' : '1.0' }"
                    *ngIf="message.list.listItem.length > 3"
                  >
                    <mat-icon aria-hidden="false" aria-label="right" (click)="updateIndexOfCarousel(i, 'next', horizontalList)">
                      keyboard_arrow_right
                    </mat-icon>
                  </div>
                </div>
              </ng-container>
            </ng-container>
            <div
              class="d-flex align-center position-absolute show-translate f-12 line-21"
              (click)="message.isTranslate = !message?.isTranslate"
              *ngIf="commonService.isDeeplEnable && message.isTranslated"
            >
              <img src="../../../../assets/images/translate.svg" class="mr-6 translate-icon" alt="translate" />
              {{ message?.isTranslate ? ("SHOW_ORIGINAL_TEXT" | translate) : ("SHOW_TRANSLATED_TEXT" | translate) }}
            </div>
          </div>
        </ng-container>
        <!--  receive type - List-->
        <!--  receive type - Checklist-->
        <ng-container *ngIf="message.type === 'checklist'">
          <div class="position-relative">
            <ng-container *ngIf="!message?.isTranslate">
              <ng-container *ngIf="message.checklist.checkListType === 'withImage'">
                <div class="checklist__container">
                  <div class="checklist__option" *ngFor="let option of message.checklist.checklistItems">
                    <img class="checklist__option__image--small" [src]="option.image" alt="" />
                    <p class="checklist__option__title">{{ option.title }}</p>
                    <p class="checklist__option__value">{{ option.value }}</p>
                    <div class="checkbox--round">
                      <input type="checkbox" disabled [checked]="option.isChecked" [id]="option._id" />
                      <label [for]="option._id"></label>
                    </div>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="message.checklist.checkListType === 'titleOnly'">
                <div class="checklist__container">
                  <div class="checklist__option" *ngFor="let option of message.checklist.checklistItems">
                    <p class="checklist__option__title">{{ option.title }}</p>
                    <div class="checkbox--round">
                      <input type="checkbox" disabled [checked]="option.isChecked" [id]="option._id" />
                      <label [for]="option._id"></label>
                    </div>
                  </div>
                </div>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="message?.isTranslate">
              <ng-container *ngIf="message.translatedObj.checkListType === 'withImage'">
                <div class="checklist__container">
                  <div class="checklist__option" *ngFor="let option of message.translatedObj.checklistItems">
                    <img class="checklist__option__image--small" [src]="option.image" alt="" />
                    <p class="checklist__option__title">{{ option.title }}</p>
                    <p class="checklist__option__value">{{ option.value }}</p>
                    <div class="checkbox--round">
                      <input type="checkbox" disabled [checked]="option.isChecked" [id]="option._id" />
                      <label [for]="option._id"></label>
                    </div>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="message.translatedObj.checkListType === 'titleOnly'">
                <div class="checklist__container">
                  <div class="checklist__option" *ngFor="let option of message.translatedObj.checklistItems">
                    <p class="checklist__option__title">{{ option.title }}</p>
                    <div class="checkbox--round">
                      <input type="checkbox" disabled [checked]="option.isChecked" [id]="option._id" />
                      <label [for]="option._id"></label>
                    </div>
                  </div>
                </div>
              </ng-container>
            </ng-container>
            <div
              class="d-flex align-center position-absolute show-translate f-12 line-21"
              (click)="message.isTranslate = !message?.isTranslate"
              *ngIf="commonService.isDeeplEnable && message.isTranslated"
            >
              <img src="../../../../assets/images/translate.svg" class="mr-6 translate-icon" alt="translate" />
              {{ message?.isTranslate ? ("SHOW_ORIGINAL_TEXT" | translate) : ("SHOW_TRANSLATED_TEXT" | translate) }}
            </div>
          </div>
        </ng-container>
        <!--  receive type - Checklist-->
        <!--  receive type - review-->
        <ng-container *ngIf="message.type === 'review'">
          <div class="review__container position-relative">
            <ng-container>
              <p class="review__title">{{ !message?.isTranslate ? message.review.text : message.translatedObj.text }}</p>
              <div class="review__action">
                <div class="review__box" *ngFor="let rev of message.review.reviewChoices; let rIndex = index">
                  <img [src]="rev.image" class="review__icon" alt="" />
                  <label class="review__label review__text">{{
                    !message?.isTranslate ? rev.caption : message.translatedObj.reviewChoices[rIndex].caption
                  }}</label>
                </div>
              </div>
            </ng-container>
            <!-- Transalated type -->
            <div
              class="d-flex align-center position-absolute show-translate f-12 line-21"
              (click)="message.isTranslate = !message?.isTranslate"
              *ngIf="commonService.isDeeplEnable && message.isTranslated"
            >
              <img src="../../../../assets/images/translate.svg" class="mr-6 translate-icon" alt="translate" />
              {{ message?.isTranslate ? ("SHOW_ORIGINAL_TEXT" | translate) : ("SHOW_TRANSLATED_TEXT" | translate) }}
            </div>
          </div>
        </ng-container>
        <!--  receive type - review-->
        <!--  receive type - Slider text-->
        <ng-container *ngIf="message.type === 'slider'">
          <div class="bot-bubble">
            <div class="bot-bubble-agent-msg">
              <span class="bot-bubble-agent-msg-text">
                {{ !message?.isTranslate ? message?.slider?.text : message?.translatedObj?.text }}
              </span>
              <span class="bot-bubble-agent-time"> {{ message.createdAt | date: "shortTime" }}</span>
            </div>
            <!-- Transalated type -->
            <div
              class="d-flex align-center position-absolute show-translate f-12 line-21"
              (click)="message.isTranslate = !message?.isTranslate"
              *ngIf="commonService.isDeeplEnable && message.isTranslated"
            >
              <img src="../../../../assets/images/translate.svg" class="mr-6 translate-icon" alt="translate" />
              {{ message?.isTranslate ? ("SHOW_ORIGINAL_TEXT" | translate) : ("SHOW_TRANSLATED_TEXT" | translate) }}
            </div>
          </div>
        </ng-container>
        <!--  receive type - Slider text-->
        <!--  receive type - form-->
        <ng-container *ngIf="message.type === 'form'">
          <div class="checklist__container__main form__container__main">
            <div class="form__container">
              <div class="common__form__input" *ngFor="let formElement of message.form.formElements">
                <ng-container *ngIf="formElement.type === 'textInput'">
                  <ng-container *ngIf="formElement.textInput.validation === 'date'">
                    <input
                      [placeholder]="formElement.textInput.placeholder"
                      type="text"
                      readonly
                      [value]="
                        formElement.answerMapping.includes('*') ? formElement.answerMapping : (formElement.answerMapping | date: 'fullDate')
                      "
                      class="common__input"
                    />
                  </ng-container>
                  <ng-container *ngIf="formElement.textInput.validation === 'dateAndTime'">
                    <input
                      [placeholder]="formElement.textInput.placeholder"
                      type="text"
                      readonly
                      [value]="
                        formElement.answerMapping.includes('*') ? formElement.answerMapping : (formElement.answerMapping | date: 'medium')
                      "
                      class="common__input"
                    />
                  </ng-container>
                  <ng-container *ngIf="formElement.textInput.validation !== 'date' && formElement.textInput.validation !== 'dateAndTime'">
                    <input
                      [placeholder]="formElement.textInput.placeholder"
                      type="text"
                      readonly
                      [value]="formElement.answerMapping"
                      class="common__input"
                    />
                  </ng-container>
                </ng-container>
                <!--Radio Button-->
                <ng-container *ngIf="formElement.type === 'radioButtons'">
                  <p class="common__form__label">
                    {{ formElement.label }}
                  </p>
                  <label class="checkBox__container" *ngFor="let option of formElement.radioButtons.items; let oIndex = index">
                    <span>{{ option.name }}</span>
                    <input
                      type="radio"
                      [id]="option._id"
                      [checked]="option.isSelected"
                      class="common__radioButton__input"
                      disabled
                      [value]="option.name" />
                    <label class="common__radioButton" [for]="option._id"></label
                  ></label>
                </ng-container>
                <!--Checkbox -->
                <ng-container *ngIf="formElement.type === 'checkBox'">
                  <p class="common__form__label">
                    {{ formElement.label }}
                  </p>
                  <label class="checkBox__container" *ngFor="let option of formElement.checkBox.items as any; let oIndex = index">
                    <span>{{ option.name }}</span>
                    <input
                      type="checkbox"
                      [id]="option._id"
                      disabled
                      [checked]="option.isSelected"
                      class="common__checkBox__input"
                      [value]="option.name" />
                    <label class="common__checkBox" [id]="option._id"></label
                  ></label>
                </ng-container>
                <!--Select -->
                <ng-container *ngIf="formElement.type === 'dropdown'">
                  <p class="common__form__label">
                    {{ formElement.label }}
                  </p>
                  <select [ngModel]="formElement.answerMapping" disabled>
                    <option [value]="''">{{ "SELECT_OPTION" | translate }}</option>
                    <option *ngFor="let option of formElement.dropdown.items; let oIndex = index" [value]="option">
                      {{ option }}
                    </option>
                  </select>
                </ng-container>
              </div>
            </div>
          </div>
        </ng-container>
        <!--  receive type - form-->
        <!--  receive type - pdf,doc,ppt,xls Start-->
        <ng-container *ngIf="message.type === 'pdf' || message.type === 'doc' || message.type === 'xls' || message.type === 'ppt'">
          <div class="bot-bubble msg-type position-relative">
            <div class="bot-bubble-agent-msg">
              <div class="bot-bubble-agent-doc">
                <div class="d-flex align-center bot-bubble-agent-doc-detail">
                  <img [alt]="message.type" [src]="'assets/images/' + message.type + '.svg'" />
                  <span>{{
                    !message?.isTranslate ? (message.caption ? message.caption : "Document") : message.translatedObj.caption
                  }}</span>
                </div>
                <a
                  class="outline-none"
                  rel="noopener"
                  target="_blank"
                  download
                  [href]="message[message.type].url"
                  matTooltip="Click to download"
                >
                  <img src="../../../../assets/images/green-download.svg" alt="" class="img-fluid" />
                </a>
              </div>
            </div>
            <div
              class="d-flex align-center position-absolute show-translate f-12 line-21"
              (click)="message.isTranslate = !message?.isTranslate"
              *ngIf="commonService.isDeeplEnable && message.isTranslated"
            >
              <img src="../../../../assets/images/translate.svg" class="mr-6 translate-icon" alt="translate" />
              {{ message?.isTranslate ? ("SHOW_ORIGINAL_TEXT" | translate) : ("SHOW_TRANSLATED_TEXT" | translate) }}
            </div>
          </div>
        </ng-container>
        <!--  receive type - pdf,doc,ppt,xls End-->
        <!--  receive type - location-->
        <ng-container *ngIf="message.type === 'location'">
          <div class="receive-msg-card position-relative common-display-user-detail">
            <a
              [href]="'https://www.google.com/maps/search/?api=1&query=' + message.location.latitude + ',' + message.location.longitude"
              target="_blank"
              matTooltip="Click to see location"
              class="bot-bubble-agent-img"
            >
              <img alt="" class="bot-bubble-agent-image" src="../../../../assets/images/location.png" />
              <div *ngIf="message.location.label">
                <a
                  [href]="
                    'https://www.google.com/maps/search/?api=1&query=' +
                    message.location.label +
                    '/@' +
                    message.location.latitude +
                    ',' +
                    message.location.longitude
                  "
                  target="_blank"
                  class="location-label"
                  >{{ message.location.label }}</a
                >
              </div>
              <p *ngIf="message.location.address" class="location-body">
                {{ message.location.address }}
              </p>
            </a>
          </div>
        </ng-container>
        <!--  receive type - location-->
        <!-- CONFIG MESSAGES | START -->
        <ng-container *ngIf="message.type === 'liveChatConfigMessage'">
          <div class="bot-bubble text-center config-message">
            <div class="bot-bubble-agent-msg config-message">
              <span
                class="bot-bubble-agent-msg-text text-black"
                [innerHTML]="!message?.isTranslate ? message?.liveChatConfigMessage : message?.translatedObj.liveChatConfigMessage"
              >
              </span>
            </div>
            <!-- Transalated type -->
            <div
              class="d-flex align-center position-absolute show-translate f-12 line-21"
              (click)="message.isTranslate = !message?.isTranslate"
              *ngIf="commonService.isDeeplEnable && message.isTranslated"
            >
              <img src="../../../../assets/images/translate.svg" class="mr-6 translate-icon" alt="translate" />
              {{ message?.isTranslate ? ("SHOW_ORIGINAL_TEXT" | translate) : ("SHOW_TRANSLATED_TEXT" | translate) }}
            </div>
          </div>
        </ng-container>
        <!-- CONFIG MESSAGES | END -->
        <!-- Interactive Types | START-->
        <div *ngIf="message.type === messageTypeEnum.INTERACTIVE">
          <div class="interactive-type-container p-10 position-relative">
            <ng-container>
              <div class="interactive-type-header mb-10" *ngIf="message.interactive?.header">
                <span *ngIf="message.interactive.header.type === interactiveTypeHeaderConst[0]" class="f-16 font-700">{{
                  !message?.isTranslate ? message.interactive.header.text : message.translatedObj.header.text
                }}</span>
                <span
                  *ngIf="message.interactive.header.type === interactiveTypeHeaderConst[1] && message.interactive.header.image?.link"
                  class="f-16 font-700"
                >
                  <img class="w-100" [src]="message.interactive.header.image.link" alt="" />
                </span>

                <span
                  *ngIf="message.interactive.header.type === interactiveTypeHeaderConst[2] && message.interactive.header.video?.link"
                  class="f-16 font-700"
                >
                  <video class="w-100" controls [src]="message.interactive.header.video?.link"></video>
                </span>
                <span
                  *ngIf="message.interactive.header.type === interactiveTypeHeaderConst[3] && message.interactive.header.document?.link"
                  class="f-16 font-700"
                >
                  <a
                    class="outline-none"
                    rel="noopener"
                    target="_blank"
                    download
                    [href]="message.interactive.header.document.link"
                    [matTooltip]="'DOWNLOAD' | translate"
                  >
                    {{
                      !message?.isTranslate ? message.interactive.header.document.filename : message.translatedObj.header.document.filename
                    }}
                    <img src="../../../../assets/images/green-download.svg" alt="" class="img-fluid" />
                  </a>
                </span>
              </div>
              <div class="interactive-type-body mb-5" *ngIf="message.interactive?.body">
                {{ !message?.isTranslate ? message.interactive.body.text : message.translatedObj?.body.text }}
              </div>
              <div class="interactive-type-footer f-12 f-300" *ngIf="message.interactive?.footer">
                {{ !message?.isTranslate ? message.interactive.footer.text : message.translatedObj?.footer.text }}
              </div>
              <div class="interactive-type-button-container" *ngIf="message.interactive?.action?.buttons">
                <div class="interactive-type-button" *ngFor="let button of message.interactive.action.buttons; let bIndex = index">
                  {{
                    !message?.isTranslate
                      ? button[button.type].title
                      : message.translatedObj?.action?.buttons[bIndex][message.translatedObj?.action?.buttons[bIndex].type].title
                  }}
                </div>
              </div>
              <div class="interactive-type-button" *ngIf="message.translatedObj?.type === 'list'">
                {{ !message?.isTranslate ? message.interactive.action?.button : message.translatedObj?.action?.button }}
              </div>
            </ng-container>

            <!-- Transalated type -->
            <div
              class="d-flex align-center position-absolute show-translate f-12 line-21"
              (click)="message.isTranslate = !message?.isTranslate"
              *ngIf="commonService.isDeeplEnable && message.isTranslated"
            >
              <img src="../../../../assets/images/translate.svg" class="mr-6 translate-icon" alt="translate" />
              {{ message?.isTranslate ? ("SHOW_ORIGINAL_TEXT" | translate) : ("SHOW_TRANSLATED_TEXT" | translate) }}
            </div>
          </div>
        </div>
        <!-- Interactive Types | END-->
        <!-- Contact Type | START-->
        <div *ngIf="message.type === messageTypeEnum.CONTACT">
          <div class="contact-container mb-10" *ngFor="let contact of message.contacts">
            <div class="p-20 f-16 font-600">
              {{ contact.name.formatted_name }}
            </div>
            <div class="d-flex align-center mt-5">
              <div class="contact-button w-100 p-10">
                {{ "INVITE" | translate }}
              </div>
              <div class="contact-button w-100 p-10">
                {{ "ADD_CONTACT" | translate }}
              </div>
            </div>
          </div>
        </div>
        <!-- Contact Type | END-->

        <ng-container *ngIf="message.type === messageTypeEnum.CONTACTINFO">
          <div class="bot-bubble">
            <div class="bot-bubble-agent-msg">
              <div class="bot-bubble-agent-msg-text">
                <div class="Contact">
                  <div class="Avatar d-flex align-center just-center white-space-no-wrap size-large no-photo">
                    {{ message.contactInfo.first_name.slice(0, 1) | uppercase
                    }}{{ message.contactInfo?.last_name?.slice(0, 1) | uppercase }}
                  </div>
                  <div class="contact-info p-1 white-space-no-wrap overflow-hidden pl-0">
                    <div class="contact-name mb-1 font-500">{{ message.contactInfo.first_name }} {{ message.contactInfo.last_name }}</div>
                    <div class="contact-phone font-500">{{ message.contactInfo.phone_number }}</div>
                  </div>
                </div>
              </div>
              <span class="bot-bubble-agent-time"> {{ message.createdAt | date: "shortTime" }}</span>
            </div>
          </div>
        </ng-container>

        <div *ngIf="message.type === 'template'">
          <div class="position-relative">
            <ng-container>
              <ng-container *ngFor="let component of message.template.components">
                <ng-container *ngIf="component.type !== 'BUTTON'; else buttonTemplate">
                  <div class="interactive-type-container p-10">
                    <div class="interactive-type-header mb-10" *ngIf="component.type === 'HEADER'">
                      <span *ngIf="component.format === 'TEXT'" class="f-16 font-700">{{ message.interactive.header.text }}</span>
                      <span *ngIf="component.format === 'IMAGE' && component.image?.link" class="f-16 font-700">
                        <img class="w-100" [src]="component.image.link" alt="" />
                      </span>

                      <span *ngIf="component.format === 'VIDEO' && component.video?.link" class="f-16 font-700">
                        <video class="w-100" controls [src]="component.video?.link"></video>
                      </span>
                      <span *ngIf="component.format === 'DOCUMENT' && component.document?.link" class="f-16 font-700">
                        <a
                          class="outline-none"
                          rel="noopener"
                          target="_blank"
                          download
                          [href]="component.document.link"
                          [matTooltip]="'DOWNLOAD' | translate"
                        >
                          {{ component.document.filename }}
                          <img src="../../../../assets/images/green-download.svg" alt="" class="img-fluid" />
                        </a>
                      </span>
                    </div>
                    <div class="interactive-type-body mb-5" *ngIf="component.type === 'BODY'">
                      {{ component.text }}
                    </div>
                    <div class="interactive-type-footer f-12 f-300" *ngIf="component.type === 'FOOTER'">
                      {{ component.text }}
                    </div>
                  </div>
                </ng-container>
                <ng-template #buttonTemplate>
                  <ng-container *ngFor="let button of component.buttons">
                    <div class="interactive-type-button">
                      <mat-icon class="mr-10" *ngIf="button.type === 'URL'">launch</mat-icon>
                      <mat-icon class="mr-10" *ngIf="button.type === 'PHONE_NUMBER'">phone_forwarded</mat-icon>
                      {{ button.text }}
                    </div>
                  </ng-container>
                </ng-template>
              </ng-container>
            </ng-container>
          </div>
        </div>

        <!-- message types end-->
        <div class="chat-send-profile" *ngIf="message.type !== 'typing' && message.type !== 'liveChatConfigMessage'">
          <img height="30px" width="30px" [src]="botIcon" alt="" />
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
