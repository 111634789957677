import { Component, Input } from "@angular/core";
import { AnalyticsCardData } from "../../models/common-model";

@Component({
  selector: "app-common-analytics-cards",
  templateUrl: "./common-analytics-cards.component.html",
  styleUrls: ["./common-analytics-cards.component.scss"],
})
export class CommonAnalyticsCardsComponent {
  @Input() analyticsCardData: AnalyticsCardData[] = [];
  @Input() isImagePathFull: boolean;
}
