<section class="login-main">
  <div class="login-header">
    <img src="assets/images/logo-white.svg" alt="" class="img-fluid" />
    <div class="text-center">
      <h4>Antonic.ai</h4>
      <p>The Hybrid Conversational Platform</p>
    </div>
  </div>
  <div class="login-body">
    <div class="row">
      <div class="col-md-6">
        <div class="login-left">
          <h3>Sign in to Antonic.ai</h3>
          <img src="assets/images/login-vector.svg" class="img-fluid mw-100" alt="" />
        </div>
      </div>
      <div class="col-md-6">
        <div class="login-right">
          <form [formGroup]="loginForm" class="pt-30">
            <div class="l-form" *ngIf="status === 'login'">
              <h5 class="info-text mb-12 font-400">Enter details below</h5>
              <div class="login-form-input">
                <label class="font-gray f-14 mb-10">Email Address</label>
                <input class="f-10 w-100 font-600 input" autocomplete="off" type="email" formControlName="email" />
              </div>
              <div class="login-form-input">
                <label class="font-gray mb-10 mt-20">Password</label>
                <div class="position-relative">
                  <input class="f-10 font-600 w-100 input" autocomplete="off" [type]="enterPasswordType" formControlName="password" />
                  <div class="show-password" (click)="showPassword('enter')">
                    <img src="assets/images/password.svg" class="img-fluid c-p" alt="" *ngIf="enterPassword" />
                    <img src="assets/images/eye-outline.svg" class="img-fluid c-p" alt="" *ngIf="!enterPassword" />
                  </div>
                </div>
              </div>

              <div class="d-flex just-space-bet align-center mt-15 flex-wrap">
                <div>
                  <mat-checkbox color="primary" class="f-14 font-600" (change)="toggleRemember($event)">Remember me </mat-checkbox>
                </div>
                <div>
                  <a class="f-14 c-p font-gray f-p" routerLink="/forgot" (click)="checkForEmail('forgot')">Forgot Password?</a>
                </div>
              </div>
              <div class="mt-20">
                <button
                  type="submit"
                  (click)="doLogin()"
                  mat-flat-button
                  class="d-flex just-center align-center font-400 login_button ml-auto pl-40 pr-40 mr-auto"
                >
                  Sign In
                </button>
              </div>
            </div>
          </form>
          <div *ngIf="status === 'forgot'" class="login-form-left-background">
            <div>
              <h1 class="font-gray f-14 font-500 m-0">Don't remember your password?</h1>
            </div>
            <h3 class="font-500 f-18">Please enter your email Id to retrieve your password.</h3>
            <form [formGroup]="forgetPassForm" class="pt-30">
              <div class="login-form-input">
                <label class="font-gray mb-10">Email Address</label>
                <input class="f-10 font-600 input w-100" autocomplete="off" type="email" formControlName="email" />
              </div>
              <div class="mt-20">
                <button
                  type="submit"
                  (click)="sendMail()"
                  mat-flat-button
                  class="d-flex just-center align-center font-400 login_button ml-auto mr-auto"
                >
                  Send Mail
                </button>
              </div>
            </form>
            <div class="d-flex text-underline align-center mt-15 flex-wrap just-end">
              <div>
                <a class="f-14 font-600 c-p" routerLink="/login" (click)="checkForEmail('login')">Go To Login</a>
              </div>
            </div>
          </div>
          <div class="login-form-left-background" *ngIf="status === 'reset'">
            <div>
              <h1 class="font-gray f-14 font-500">Reset your password</h1>
            </div>
            <form [formGroup]="resetPassForm">
              <div class="login-form-input p-10">
                <label class="font-gray mb-10">Password</label>
                <div class="position-relative">
                  <input class="f-10 font-600 input w-100" autocomplete="off" [type]="enterPasswordType" formControlName="password" />
                  <div class="show-password" (click)="showPassword('enter')">
                    <img src="assets/images/password.svg" class="img-fluid c-p" alt="" *ngIf="enterPassword" />
                    <img src="assets/images/eye-outline.svg" class="img-fluid c-p" alt="" *ngIf="!enterPassword" />
                  </div>
                </div>
              </div>
              <div class="login-form-input p-10">
                <label class="font-gray mb-10">Confirm Password</label>
                <div class="position-relative">
                  <input
                    class="f-10 font-600 w-100 input"
                    autocomplete="off"
                    [type]="reEnterPasswordType"
                    formControlName="confirmPassword"
                  />
                  <div class="show-password" (click)="showPassword('reEnter')">
                    <img src="assets/images/password.svg" class="img-fluid c-p" alt="" *ngIf="reEnterPassword" />
                    <img src="assets/images/eye-outline.svg" class="img-fluid c-p" alt="" *ngIf="!reEnterPassword" />
                  </div>
                </div>
              </div>
              <div class="mt-20">
                <button
                  type="submit"
                  (click)="resetPass()"
                  mat-flat-button
                  class="d-flex just-center align-center font-400 login_button ml-auto mr-auto reset_password"
                >
                  RESET PASSWORD
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
