import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { DateAdapter } from "@angular/material/core";
import * as moment from "moment";
import { DateTimeAdapter } from "ng-pick-datetime-ex";
import { OwlDateTimeIntl } from "ng-pick-datetime-ex";

@Injectable()
export class TranslationService {
  constructor(
    private translate: TranslateService,
    private dateAdapter: DateAdapter<Date>,
    private dateTimeAdapter: DateTimeAdapter<any>,
    private owlDateTimeIntl: OwlDateTimeIntl
  ) {}

  getTranslate(text): Promise<string> {
    return new Promise((resolve, reject) => {
      this.translate.get(text).subscribe((res: string) => {
        resolve(res as string);
      });
    });
  }

  setLanguageToTranslateService(language) {
    this.translate.setDefaultLang("en");
    switch (language) {
      case "en":
        this.translate.use("en");
        this.dateAdapter.setLocale("en");
        this.dateTimeAdapter.setLocale("en");
        moment.locale("en");
        break;
      case "nl":
        const monthNameSort = "Jan_Feb_Mrt_Apr_Mei_Jun_Jul_Aug_Sep_Okt_Nov_Dec";
        const weekDayNameSort = ["Zo", "Ma", "Di", "Wo", "Do", "Vr", "Za"];
        this.translate.use("nl");
        this.dateAdapter.setLocale("nl");
        this.dateTimeAdapter.setLocale("nl");
        moment.locale("nl");
        moment.updateLocale("nl", {
          monthsShort: monthNameSort.split("_"),
          weekdaysShort: weekDayNameSort,
        });
        break;
      default:
        this.translate.use("en");
        this.dateAdapter.setLocale("en");
        this.dateTimeAdapter.setLocale("en");
        moment.locale("en");
        break;
    }
    this.setDateTimeButtonTitle();
  }

  setDateTimeButtonTitle() {
    this.getTranslate("APPLY").then((res: string) => {
      this.owlDateTimeIntl.setBtnLabel = res;
    });
    this.getTranslate("CANCEL").then((res: string) => {
      this.owlDateTimeIntl.cancelBtnLabel = res;
    });
  }

  getTranslationLanguage() {
    return this.translate.currentLang;
  }
}
