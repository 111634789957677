import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { IFIResponseFormat, ITag, nlpFiArray, timeZoneList, userExperienceArray, userFiArray } from "../../models/user-profile";
import { BotService } from "../../services/bot.service";
import { CommonService } from "../../services/common.service";
import { SettingService } from "../../services/setting.service";
import { TagsService } from "../../services/tags.service";
import { UsersService } from "../../services/users.service";
import { getChannelInfo } from "../../helper/channel.helper";

@Component({
  selector: "app-common-bot-user-details",
  templateUrl: "./common-bot-user-details.component.html",
  styleUrls: ["./common-bot-user-details.component.scss"],
})
export class CommonBotUserDetailsComponent implements OnInit {
  @Input() isTranScriptURL = false;
  @Input() customDate;
  @Input() IsProfile;
  @Input() selectedUser;
  @Output() updateVariables = new EventEmitter();
  selectedTag: any;
  tagList: ITag[] = [];
  tagClass = ["chip-yellow", "chip-green", "chip-purple", "chip-red", "chip-blue"];
  isEditUserField = false;
  isEditEmotions = false;
  isEditNLPField = false;
  isEditOtherFI = false;
  otherFi: IFIResponseFormat[] = [];
  userExperienceFi: IFIResponseFormat[] = [];
  userFi: IFIResponseFormat[] = [];
  nlpFi: IFIResponseFormat[] = [];
  botIcon: any;
  tagForm;
  userFiArray = userFiArray;
  nlpFiArray = nlpFiArray;
  userExperienceArray = userExperienceArray;
  timezoneList = [];
  private addNewTag: boolean;

  constructor(
    readonly _userService: UsersService,
    readonly _botService: BotService,
    readonly _tagsService: TagsService,
    readonly dialog: MatDialog,
    public router: Router,
    public _settingService: SettingService,
    readonly _commonService: CommonService
  ) {}

  get channelIcon() {
    return getChannelInfo(this.selectedUser.botChannel);
  }

  get channelName() {
    return getChannelInfo(this.selectedUser.botChannel, true);
  }

  ngOnInit(): void {
    if (this.selectedUser._id) {
      this.OpenUser();
      this.getTags();
      this.timezoneList = timeZoneList;
    }
  }

  openDialog(templateRef, type?) {
    if (type === "createTag") {
      this.tagForm = new FormGroup({
        tag: new FormControl("", [Validators.required]),
      });
      this.tagForm.reset();
      this.dialog.open(templateRef, {
        height: "auto",
        width: "600px",
      });
    } else {
      this.selectedTag = "";
      this.dialog.open(templateRef, {
        width: "600px",
      });
    }
  }

  onTagSelect(isRemove, index?) {
    const object: any = {};
    object.botUsers = [];
    object.botUsers[0] = this.selectedUser._id;
    object.type = "addTag";
    if (isRemove) {
      object.type = "removeTag";
      object.removeTags = [this.selectedUser.tags[index]._id];
    } else {
      const isAnyError = this.validateTagAddRemove();
      if (isAnyError) {
        return;
      }
    }
    object.addTags = [];
    object.addTags[0] = this.selectedTag;
    object.isFromBdk = false;
    this._tagsService
      .assignTags(this._botService.getBot(), object)
      .then(() => {
        if (isRemove) {
          this.selectedUser.tags.splice(index, 1);
          this._commonService.successNotification("TAG_REMOVED_SUCCESSFULLY");
        } else {
          let name = "";
          this.tagList.forEach((tag) => {
            if (tag._id === this.selectedTag) {
              name = tag.tag;
            }
          });
          this.selectedUser.tags.push({
            tag: name,
            _id: this.selectedTag,
            className: this.tagClass[this._commonService.getRandomInt(0, 4)],
          });
          this.getTags();
          this._commonService.successNotification("TAG_ADDED_SUCCESSFULLY");
        }
        this.dialog.closeAll();
        this.selectedTag = "";
      })
      .catch((err) => {
        this._commonService.APIErrorHandler(err);
      });
    this.addNewTag = false;
    this.closeDialog();
  }

  validateTagAddRemove() {
    if (this.selectedTag === undefined) {
      this.addNewTag = false;
      this.closeDialog();
      this._commonService.errorHandler("NO_TAG_AVAILABLE");
      return true;
    } else if (this.selectedTag.trim() === "") {
      this._commonService.errorHandler("PLEASE_SELECT_TAG");
      return true;
    } else {
      let isTagAlready = 0;
      this.selectedUser.tags.forEach((tag) => {
        if (tag._id === this.selectedTag) {
          isTagAlready = 1;
        }
      });
      if (isTagAlready === 1) {
        this._commonService.errorHandler("TAG_ALREADY_ADDED");
        return true;
      }
    }
    return false;
  }
  closeDialog() {
    this.dialog.closeAll();
  }

  getTags() {
    this._tagsService
      .getTagsList(this._botService.getBot())
      .then((result: any) => {
        if (result) {
          this.tagList = result.tags;
        } else {
          this.tagList = [];
        }
      })
      .catch((err) => {
        this._commonService.APIErrorHandler(err);
      });
  }

  editUserFields() {
    this.isEditUserField = !this.isEditUserField;
  }

  editEmotionAnalytics() {
    this.isEditEmotions = !this.isEditEmotions;
  }

  editNlpAnalytics() {
    this.isEditNLPField = !this.isEditNLPField;
  }

  editOtherFields() {
    this.isEditOtherFI = !this.isEditOtherFI;
  }

  updateFI(type) {
    const fiDataToUpdate: any = {};
    switch (type) {
      case "userFi":
        fiDataToUpdate.fis = this.userFi;
        this.isEditUserField = !this.isEditUserField;
        break;
      case "emotions":
        fiDataToUpdate.fis = this.userExperienceFi;
        this.isEditEmotions = !this.isEditEmotions;
        break;
      case "otherFi":
        this.isEditOtherFI = !this.isEditOtherFI;
        fiDataToUpdate.fis = this.otherFi;
        break;
      case "nlpField":
        this.isEditNLPField = !this.isEditNLPField;
        fiDataToUpdate.fis = this.nlpFi;
        break;
    }
    this._userService
      .updateFi(fiDataToUpdate)
      .then(() => {
        this._commonService.successNotification("USER_FIELD_UPDATE");
      })
      .catch((err) => {
        this._commonService.APIErrorHandler(err);
      });
  }

  getUserFiIcons(name) {
    switch (name) {
      case "latitude":
      case "longitude":
        return `assets/images/icon-lat-long.svg`;
      case "country":
      case "region":
      case "locale":
        return `assets/images/icon-region.svg`;
      case "timezone":
        return `assets/images/icon-timezon.svg`;
      case "browser":
        return `assets/images/icon-browser.svg`;
      case "device":
        return `assets/images/icon-device.svg`;
      default:
        return null;
    }
  }

  createTag() {
    if (this.tagForm.valid) {
      if (this.tagForm.value.tag.trim() === "") {
        this._commonService.errorHandler("ENTER_VALID_NAME");
        return;
      }
      this._tagsService
        .addTag(this.tagForm.value)
        .then((tagCreateResponse) => {
          this.dialog.closeAll();
          this._commonService.successNotification("TAG_CREATED_SUCCESSFULLY");
          this.getTags();
          this.selectedTag = tagCreateResponse._id;
          this.onTagSelect(false);
          this.dialog.closeAll();
        })
        .catch((err) => {
          this._commonService.APIErrorHandler(err);
        });
    } else {
      if (!this.tagForm.value.tag) {
        this._commonService.errorHandler("PLEASE_ENTER_NAME");
      }
    }
  }

  OpenUser() {
    this.userFi = [];
    this.otherFi = [];
    this.userExperienceFi = [];
    this.nlpFi = [];
    this._userService
      .getUserDetailsById(this.selectedUser._id)
      .then((res: any) => {
        this.updateVariables.emit({ type: "getUserTranscript", totalPage: res.totalPages });
        this.selectedUser.botChannel = res.botChannel;
        this.checkAndSetName(res.flexibleIndicator);

        if (!this.selectedUser.name && this.selectedUser.first_name && this.selectedUser.last_name) {
          this.selectedUser.name = `${this.selectedUser.first_name}  ${this.selectedUser.last_name}`;
          this.selectedUser.data_letters = `${this.selectedUser.first_name.substr(0, 1)}${this.selectedUser.last_name
            .substr(0, 1)
            .toUpperCase()}`;
        } else if (!this.selectedUser.name && this.selectedUser.first_name && !this.selectedUser.last_name) {
          this.selectedUser.name = this.selectedUser.first_name;
          this.selectedUser.data_letters = this.selectedUser.first_name.substr(0, 2).toUpperCase();
        } else if (!this.selectedUser.name && !this.selectedUser.first_name && this.selectedUser.last_name) {
          this.selectedUser.name = this.selectedUser.last_name;
          this.selectedUser.data_letters = this.selectedUser.last_name.substr(0, 2).toUpperCase();
        } else if (!this.selectedUser.name && !this.selectedUser.first_name && !this.selectedUser.last_name) {
          this.selectedUser.name = `${this.selectedUser.botChannel} User`;
          this.selectedUser.data_letters = `${this.selectedUser.botChannel.substr(0, 1)} U`.toUpperCase();
        } else {
          this.selectedUser.data_letters = this.selectedUser?.name?.substr(0, 2).toUpperCase();
        }
        this.selectedUser.tags = res.tags;
        this.selectedUser.tags.map((tag) => {
          tag.className = this.tagClass[this._commonService.getRandomInt(0, 4)];
          return tag;
        });
        this.selectedUser.visit = res.visit;
        this.selectedUser.action = res.action;
        this.selectedUser.profile = null;
        for (let fi = 0; fi < res.flexibleIndicator.length; fi++) {
          const currentFI = res.flexibleIndicator[fi];
          this.checkAndSetFirstAndLastInteraction(currentFI);
          this.checkAndSetUserProfile(currentFI);
          this.handleUserFis(currentFI);
        }
        this.updateVariables.emit({ type: "loader", status: false });
      })
      .catch((err) => {
        this._commonService.APIErrorHandler(err);
      });
  }

  checkAndSetName(flexibleIndicatorArray) {
    if (!this.selectedUser.first_name) {
      const firstNameIndex = flexibleIndicatorArray.findIndex((flexibleIndicator) => flexibleIndicator.field === "first_name");
      if (firstNameIndex !== -1) {
        this.selectedUser.first_name = flexibleIndicatorArray[firstNameIndex].value;
      }
    }
    if (!this.selectedUser.last_name) {
      const lastNameIndex = flexibleIndicatorArray.findIndex((flexibleIndicator) => flexibleIndicator.field === "last_name");
      if (lastNameIndex !== -1) {
        this.selectedUser.last_name = flexibleIndicatorArray[lastNameIndex].value;
      }
    }
    if (!this.selectedUser.name) {
      const nameIndex = flexibleIndicatorArray.findIndex((flexibleIndicator) => flexibleIndicator.field === "name");
      if (nameIndex !== -1) {
        this.selectedUser.name = flexibleIndicatorArray[nameIndex].value;
      }
    }
  }

  checkAndSetFirstAndLastInteraction(currentFI) {
    if (currentFI.field === "first_interacted_at") {
      this.selectedUser.firstSeen = currentFI.value;
    } else if (currentFI.field === "last_interacted_at") {
      this.selectedUser.lastSeen = currentFI.value;
    }
  }
  checkAndSetUserProfile(currentFI) {
    if (currentFI.field === "profile_pic") {
      if (currentFI.value) {
        this.selectedUser.profile = currentFI.value;
      } else {
        this.selectedUser.profile = null;
      }
    }
  }
  handleUserFis(currentFI) {
    if (currentFI.field !== "profile_pic" && currentFI.field !== "first_interacted_at" && currentFI.field !== "last_interacted_at") {
      if (this.userFiArray.includes(currentFI.field)) {
        this.userFi.push({
          type: currentFI.type,
          _id: currentFI._id,
          name: currentFI.field,
          value: currentFI.value,
          isEditableByAgent: currentFI.isEditableByAgent,
        });
      } else if (this.nlpFiArray.includes(currentFI.field)) {
        this.nlpFi.push({
          type: currentFI.type,
          _id: currentFI._id,
          name: currentFI.field,
          value: currentFI.value,
          isEditableByAgent: currentFI.isEditableByAgent,
        });
      } else if (this.userExperienceArray.includes(currentFI.field)) {
        this.userExperienceFi.push({
          type: currentFI.type,
          _id: currentFI._id,
          name: currentFI.field,
          value: currentFI.value,
          isEditableByAgent: currentFI.isEditableByAgent,
        });
      } else {
        this.otherFi.push({
          type: currentFI.type,
          _id: currentFI._id,
          name: currentFI.field,
          value: currentFI.value,
          isEditableByAgent: currentFI.isEditableByAgent,
        });
      }
    }
  }
  validateNumber(event, type) {
    if (type === "number") {
      return Number.isFinite(Number(event.key));
    } else {
      return true;
    }
  }
}
