// #NEW_CHANNEL_INTEGRATION : when integration new channel handle in this file
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ChannelHumanName, ChannelIcon, ChannelsEnum } from "../enums/common.enum";
import { UploadFileResponse } from "../models/campaign.model";
import { NotifyService } from "./noyify.service";
import { SettingService } from "./setting.service";
import { TranslationService } from "./translation.service";
import { Subject } from "rxjs";

export const NULLISH_STRING_VALUE = ["", null, undefined];
export const NULLISH_VALUE = [null, undefined];

@Injectable()
export class CommonService {
  botcolor = "#5f1eca";
  lightBotColor = "#5f1eca54";
  orgLogo = "assets/images/Antonic.svg";
  isDeeplEnable = false;
  private _selectedChannel: string;
  public scrollToBottom = new Subject();
  constructor(
    readonly http: HttpClient,
    readonly _notifyService: NotifyService,
    readonly _translationService: TranslationService,
    readonly _settingsService: SettingService
  ) {}

  errorHandler(errorMessage) {
    this._translationService.getTranslate(errorMessage).then((res) => {
      this._notifyService.sendNotification("error", res);
    });
  }

  infoHandler(message) {
    this._translationService.getTranslate(message).then((res) => {
      this._notifyService.sendNotification("info", res);
    });
  }

  async getTranslate(text): Promise<string> {
    return new Promise((resolve, reject) => {
      this._translationService.getTranslate(text).then((res) => {
        resolve(res);
      });
    });
  }

  generateBadRequestError(exceptionCode) {
    return { error: { error_code: exceptionCode } };
  }

  APIErrorHandler(error, isWarning?: boolean) {
    console.error(error);
    let errorMessage = "INTERNAL_SERVER_ERROR";
    if (error.error && error.error.error_code) {
      errorMessage = error.error.error_code;
    }
    this._translationService.getTranslate(errorMessage).then((res) => {
      this._notifyService.sendNotification(isWarning ? "warning" : "error", res);
    });
  }

  successNotification(message) {
    this._translationService.getTranslate(message).then((res) => {
      this._notifyService.sendNotification("success", res);
    });
  }

  getRandomInt(min, max) {
    const randomBuffer = new Uint32Array(1);

    window.crypto.getRandomValues(randomBuffer);

    const randomNumber = randomBuffer[0] / (0xffffffff + 1);

    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(randomNumber * (max - min + 1)) + min;
  }
  getChannelsDropDownList() {
    const channels = [];
    this._settingsService.EnableChannels.forEach((channel) => {
      switch (channel) {
        case ChannelsEnum.FACEBOOK:
          channels.push({
            value: ChannelsEnum.FACEBOOK,
            name: ChannelHumanName.FACEBOOK,
            icon: ChannelIcon.FACEBOOK,
          });
          break;
        case ChannelsEnum.WHATSAPP:
          channels.push({
            value: ChannelsEnum.WHATSAPP,
            name: ChannelHumanName.WHATSAPP,
            icon: ChannelIcon.WHATSAPP,
          });
          break;
        case ChannelsEnum.WEBCHAT:
          channels.push({
            value: ChannelsEnum.WEBCHAT,
            name: ChannelHumanName.WEBCHAT,
            icon: ChannelIcon.WEBCHAT,
          });
          break;
        case ChannelsEnum.GOOGLE:
          channels.push({
            value: ChannelsEnum.GOOGLE,
            name: ChannelHumanName.GOOGLE,
            icon: ChannelIcon.GOOGLE,
          });
          break;
        case ChannelsEnum.TWILIO:
          channels.push({
            value: ChannelsEnum.TWILIO,
            name: ChannelHumanName.TWILIO,
            icon: ChannelIcon.TWILIO,
          });
          break;
        case ChannelsEnum.TWILIOSMS:
          channels.push({
            value: ChannelsEnum.TWILIOSMS,
            name: ChannelHumanName.TWILIOSMS,
            icon: ChannelIcon.TWILIOSMS,
          });
          break;
        case ChannelsEnum.HANGOUTCHAT:
          channels.push({
            value: ChannelsEnum.HANGOUTCHAT,
            name: ChannelHumanName.HANGOUTCHAT,
            icon: ChannelIcon.HANGOUTCHAT,
          });
          break;
        case ChannelsEnum.VOXIMPLANT:
          channels.push({
            value: ChannelsEnum.VOXIMPLANT,
            name: ChannelHumanName.VOXIMPLANT,
            icon: ChannelIcon.VOXIMPLANT,
          });
          break;
        case ChannelsEnum.AZURE:
          channels.push({
            value: ChannelsEnum.AZURE,
            name: ChannelHumanName.AZURE,
            icon: ChannelIcon.AZURE,
          });
          break;
        case ChannelsEnum.MSTEAM:
          channels.push({
            value: ChannelsEnum.MSTEAM,
            name: ChannelHumanName.MSTEAM,
            icon: ChannelIcon.MSTEAM,
          });
          break;
        case ChannelsEnum.SLACK:
          channels.push({
            value: ChannelsEnum.SLACK,
            name: ChannelHumanName.SLACK,
            icon: ChannelIcon.SLACK,
          });
          break;
        case ChannelsEnum.AUDIOCODES:
          channels.push({
            value: ChannelsEnum.AUDIOCODES,
            name: ChannelHumanName.AUDIOCODES,
            icon: ChannelIcon.AUDIOCODES,
          });
          break;
        case ChannelsEnum.DIALOG360:
          channels.push({
            value: ChannelsEnum.DIALOG360,
            name: ChannelHumanName.DIALOG360,
            icon: ChannelIcon.DIALOG360,
          });
          break;
        case ChannelsEnum.UNIVERSAL:
          channels.push({
            value: ChannelsEnum.UNIVERSAL,
            name: ChannelHumanName.UNIVERSAL,
            icon: ChannelIcon.UNIVERSAL,
          });
          break;
        case ChannelsEnum.INSTAGRAM:
          channels.push({
            value: ChannelsEnum.INSTAGRAM,
            name: ChannelHumanName.INSTAGRAM,
            icon: ChannelIcon.INSTAGRAM,
          });
          break;
        case ChannelsEnum.TELEGRAM:
          channels.push({
            value: ChannelsEnum.TELEGRAM,
            name: ChannelHumanName.TELEGRAM,
            icon: ChannelIcon.TELEGRAM,
          });
          break;
      }
    });
    channels.sort((a, b) => a.name.localeCompare(b.name));
    return channels;
  }

  getEnableChannels(): Array<string> {
    return this._settingsService.EnableChannels;
  }

  setChannel(channel) {
    localStorage.setItem("selectedChannel", channel);
  }

  getSelectedChannel() {
    return localStorage.getItem("selectedChannel");
  }

  copyToClipboard(value: string): void {
    const el = document.createElement("textarea");
    el.value = value;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    this.successNotification("COPY");
  }

  uploadFile(url: string, fileData: any): Promise<UploadFileResponse> {
    return new Promise((resolve, reject) => {
      this.http.post(url, fileData).subscribe({
        next: (uploadFileResponse: any) => {
          resolve(uploadFileResponse);
        },
        error: (err) => {
          reject(err);
        },
      });
    });
  }

  calPageLimit(id: string, size = 60): number {
    if (document.getElementById(id)) {
      return Math.ceil(document.getElementById(id).clientHeight / size) + 2;
    }
  }

  get selectedChannel() {
    return this._selectedChannel;
  }

  set selectedChannel(channel) {
    this._selectedChannel = channel;
  }

  generateRandomColor(): string {
    const maxVal = 0xffffff;
    let randomNumber = Math.random() * maxVal;
    randomNumber = Math.floor(randomNumber);
    const randColor = randomNumber.toString(16).padStart(6, "0");
    return `#${randColor.toUpperCase()}`;
  }

  getCaretPosition(editableDiv) {
    const doc = document as any;
    var caretPos = 0,
      sel,
      range;
    if (window.getSelection) {
      sel = window.getSelection();
      if (sel.rangeCount) {
        range = sel.getRangeAt(0);
        if (range.commonAncestorContainer.parentNode == editableDiv) {
          caretPos = range.endOffset;
        }
      }
    } else if (doc.selection && doc.selection.createRange) {
      range = doc.selection.createRange();
      if (range.parentElement() == editableDiv) {
        var tempEl = document.createElement("span");
        editableDiv.insertBefore(tempEl, editableDiv.firstChild);
        var tempRange = range.duplicate();
        tempRange.moveToElementText(tempEl);
        tempRange.setEndPoint("EndToEnd", range);
        caretPos = tempRange.text.length;
      }
    }
    return caretPos;
  }
}
