export enum ChannelsEnum {
  FACEBOOK = "facebook",
  WEBCHAT = "webchat",
  WHATSAPP = "whatsapp",
  TWILIO = "twilio",
  TWILIOSMS = "twilioSms",
  HANGOUTCHAT = "hangoutsChat",
  GOOGLE = "google",
  VOXIMPLANT = "voximplant",
  MSTEAM = "msteams",
  AZURE = "msteamsAzure",
  SLACK = "slack",
  AUDIOCODES = "audioCodes",
  DIALOG360 = "dialog360",
  UNIVERSAL = "universal",
  INSTAGRAM = "instagram",
  TELEGRAM = "telegram",
}

export enum listTypeEnum {
  VERTICAL = "vertical",
  LIST = "list",
  HORIZONTAL = "horizontal",
  CAROUSEL = "carousel",
}

export enum userNotificationTimeEnum {
  TODAY = "today",
  WEEK = "week",
  MONTH = "month",
  LATER = "later",
}

export enum NotificationTypeEnum {
  CHAT_ASSIGN = "chatAssign",
  NEW_REQUEST = "newRequest",
  DOCUMENT_TRAIN = "documentTrain",
  NLPTRAIN = "NLPTrain",
  UPDATE_QUALITY_SCORE = "updateQualityScore",
  LLM_TRAINING_MATERIAL = "llmDocFailedDescription",
}

export enum ChannelHumanName {
  FACEBOOK = "Facebook Messenger",
  WEBCHAT = "Webchat",
  WHATSAPP = "Whatsapp by Karix",
  TWILIO = "Whatsapp by Twilio",
  TWILIOSMS = "SMS by Twilio",
  HANGOUTCHAT = "Hangouts Chat",
  GOOGLE = "Google Home",
  VOXIMPLANT = "Phone Assistant",
  MSTEAM = "Microsoft Teams",
  AZURE = "Microsoft Azure",
  SLACK = "Slack",
  AUDIOCODES = "AudioCodes",
  DIALOG360 = "360Dialog",
  UNIVERSAL = "Universal",
  INSTAGRAM = "Instagram",
  TELEGRAM = "Telegram",
}

export enum ChannelNameMap {
  facebook = "Facebook Messenger",
  webchat = "Webchat",
  whatsapp = "Whatsapp by Karix",
  twilio = "Whatsapp by Twilio",
  twilioSms = "SMS by Twilio",
  hangoutsChat = "Hangouts Chat",
  google = "Google Home",
  voximplant = "Phone Assistant",
  msteams = "Microsoft Teams",
  msteamsAzure = "Microsoft Azure",
  slack = "Slack",
  audioCodes = "AudioCodes",
  dialog360 = "360Dialog",
  universal = "Universal",
  instagram = "Instagram",
  telegram = "Telegram",
}

export enum ChannelIcon {
  FACEBOOK = "assets/images/facebook-messenger.svg",
  WEBCHAT = "assets/images/wechat.svg",
  WHATSAPP = "assets/images/whatsapp.svg",
  TWILIO = "assets/images/twilio.svg",
  TWILIOSMS = "assets/images/chat.svg",
  HANGOUTCHAT = "assets/images/hangoutChat.png",
  GOOGLE = "assets/images/google.svg",
  VOXIMPLANT = "assets/images/voximplant2.svg",
  MSTEAM = "assets/images/microsoft-teams.svg",
  AZURE = "assets/images/azure.svg",
  SLACK = "assets/images/slack.png",
  AUDIOCODES = "assets/images/audioCodes.svg",
  DIALOG360 = "assets/images/whatsapp.svg",
  UNIVERSAL = "assets/images/universal.png",
  INSTAGRAM = "assets/images/instagram.svg",
  TELEGRAM = "assets/images/telegram.svg",
}

export enum INSTAGRAM_LANGUAGE_MAP {
  "zh_HK" = "Traditional Chinese (Hong Kong)",
  "zh_CN" = "Simplified Chinese (China)",
  "zh_TW" = "Traditional Chinese (Taiwan)",
  "da_DK" = "Danish",
  "nl_NL" = "Dutch",
  "nl_BE" = "Dutch (België)",
  "gn_PY" = "Guaraní",
  "az_AZ" = "Azeri",
  "en_US" = "English",
  "en_UD" = "English (Upside Down)",
  "ca_ES" = "Catalan",
  "cs_CZ" = "Czech",
  "cx_PH" = "Bisaya",
  "cy_GB" = "Welsh",
  "af_ZA" = "Afrikaans",
  "sq_AL" = "Albanian",
  "de_DE" = "German",
  "eu_ES" = "Basque",
  "gl_ES" = "Galician",
  "es_LA" = "Spanish",
  "es_CL" = "Spanish (Chile)",
  "es_CO" = "Spanish (Colombia)",
  "es_ES" = "Spanish (Spain)",
  "es_MX" = "Spanish (Mexico)",
  "es_VE" = "Spanish (Venezuela)",
  "fr_FR" = "French (France)",
  "br_FR" = "Breton (France)",
  "co_FR" = "Corsican (France)",
  "fr_CA" = "French (Canada)",
  "hu_HU" = "Hungarian",
  "ro_RO" = "Romanian",
  "sk_SK" = "Slovak",
  "sl_SI" = "Slovenian",
  "ku_TR" = "Kurdish",
  "hy_AM" = "Armenian",
  "be_BY" = "Belarusian",
  "bs_BA" = "Bosnian",
  "bg_BG" = "Bulgarian",
  "hr_HR" = "Croatian",
  "fy_NL" = "Frisian",
  "et_EE" = "Estonian",
  "fo_FO" = "Faroese",
  "ka_GE" = "Georgian",
  "el_GR" = "Greek",
  "is_IS" = "Icelandic",
  "id_ID" = "Indonesian",
  "ga_IE" = "Irish",
  "jv_ID" = "Javanese",
  "kk_KZ" = "Kazakh",
  "lv_LV" = "Latvian",
  "lt_LT" = "Lithuanian",
  "mk_MK" = "Macedonian",
  "mg_MG" = "Malagasy",
  "ms_MY" = "Malay",
  "mt_MT" = "Maltese",
  "mn_MN" = "Mongolian",
  "ne_NP" = "Nepali",
  "sr_RS" = "Serbian",
  "so_SO" = "Somali",
  "sw_KE" = "Swahili",
  "tl_PH" = "Filipino",
  "uz_UZ" = "Uzbek",
  "vi_VN" = "Vietnamese",
  "km_KH" = "Khmer",
  "tg_TJ" = "Tajik",
  "ar_AR" = "Arabic",
  "he_IL" = "Hebrew",
  "ur_PK" = "Urdu",
  "fa_IR" = "Persian",
  "ps_AF" = "Pashto",
  "qz_MM" = "Zawgyi (Myanmar)",
  "my_MM" = "Burmese (Myanmar [Burma])",
  "si_LK" = "Sinhala (Sri Lanka)",
  "rw_RW" = "Kinyarwanda (Rwanda)",
  "cb_IQ" = "Sorani",
  "ha_NG" = "Hausa (Nigeria)",
  "ff_NG" = "Fulani (Nigeria)",
  "tz_MA" = "Tamazight",
  "sz_PL" = "Silesian (Poland)",
  "it_IT" = "Italian",
  "sc_IT" = "Sardinian (Italy)",
  "ja_JP" = "Japanese",
  "ja_KS" = "Kansai-ben (Japan)",
  "ko_KR" = "Korean",
  "nb_NO" = "Norwegian (bokmal)",
  "nn_NO" = "Norwegian (nynorsk)",
  "pl_PL" = "Polish",
  "pt_BR" = "Portuguese (Brazil)",
  "pt_PT" = "Portuguese (Portugal)",
  "ru_RU" = "Russian",
  "sv_SE" = "Swedish",
  "th_TH" = "Thai",
  "tr_TR" = "Turkish",
  "bn_IN" = "Bengali",
  "gu_IN" = "Gujarati",
  "hi_IN" = "Hindi",
  "kn_IN" = "Kannada",
  "mr_IN" = "Marathi",
  "pa_IN" = "Punjabi",
  "ta_IN" = "Tamil",
  "te_IN" = "Telugu",
  "ml_IN" = "Malayalam",
  "or_IN" = "Oriya (India)",
  "as_IN" = "Assamese (India)",
  "en_GB" = "English (Great Britain)",
  "uk_UA" = "Ukrainian",
}
export enum BULK_ACTION {
  delete = "deleteBulk",
  move = "moveBulk",
}
