import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { Urls } from "../utils/urls";
import { HttpClient } from "@angular/common/http";
import { Module } from "../models/common.model";

@Injectable()
export class BotService {
  private subject = new Subject<any>();
  public sizeSubject = new Subject<any>();
  public newHandoff = new Subject<any>();
  private _quickReplyBotColor = "#5f1eca";

  constructor(private http: HttpClient) {}

  getUpdate(): Observable<any> {
    return this.subject.asObservable();
  }

  sendUpdate(message: boolean, module: Module) {
    this.subject.next({ message, module });
  }

  saveBot(id: string, name: string, module: Module) {
    if (name) {
      localStorage.setItem("selectedBotName", name);
    }
    if (id) {
      localStorage.setItem("selectedBot", id);
      this.sendUpdate(true, module);
    }
  }
  saveCurrentNLP(nlp) {
    localStorage.setItem("currentNLP", nlp);
  }

  getBot(): string {
    if (localStorage.getItem("selectedBot")) {
      return localStorage.getItem("selectedBot");
    } else {
      return "";
    }
  }
  getName(): string {
    if (localStorage.getItem("selectedBotName")) {
      return localStorage.getItem("selectedBotName");
    } else {
      return "";
    }
  }

  saveRole(role) {
    if (role) {
      localStorage.setItem("currentRole", role);
    }
  }
  getCurrentNLP() {
    return localStorage.getItem("currentNLP");
  }

  isReviewer(): boolean {
    if (localStorage.getItem("currentRole")) {
      return localStorage.getItem("currentRole") === "Bot Reviewer";
    } else {
      return false;
    }
  }

  isTester(): boolean {
    return false;
    // if (localStorage.getItem("currentRole")) {
    //   return localStorage.getItem("currentRole") === "Tester";
    // } else {
    //   return false;
    // }
  }

  isAuthor(): boolean {
    return false;
    // if (localStorage.getItem("currentRole")) {
    //   return localStorage.getItem("currentRole") === "Author";
    // } else {
    //   return false;
    // }
  }

  isPublisher(): boolean {
    return false;
    // if (localStorage.getItem("currentRole")) {
    //   return localStorage.getItem("currentRole") === "Publisher";
    // } else {
    //   return false;
    // }
  }

  updateSettings(data) {
    return new Promise((resolve, reject) => {
      return this.http.put(Urls.botSettings + "/", data).subscribe(
        (data) => {
          resolve(data);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  getSettings() {
    return new Promise((resolve, reject) => {
      return this.http.get(Urls.botSettings + "/").subscribe(
        (data) => {
          resolve(data);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  getGrowthTools() {
    return new Promise((resolve, reject) => {
      return this.http.get(Urls.botSettings + "/growthTool/").subscribe(
        (data) => {
          resolve(data);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  createGrowthTool(data) {
    return new Promise((resolve, reject) => {
      return this.http.post(Urls.botSettings + "/growthTool/", data).subscribe(
        (data) => {
          resolve(data);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  updateGrowthTool(id, data) {
    return new Promise((resolve, reject) => {
      return this.http.put(Urls.botSettings + "/growthTool/" + id, data).subscribe(
        (data) => {
          resolve(data);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  deleteGrowthTool(id) {
    return new Promise((resolve, reject) => {
      return this.http.delete(Urls.botSettings + "/growthTool/" + id).subscribe(
        (data) => {
          resolve(data);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  getBotUrls() {
    return new Promise((resolve, reject) => {
      return this.http.get(Urls.botSettings + "/botUrls").subscribe(
        (data) => {
          resolve(data);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }
  updateBotUrls(data) {
    return new Promise((resolve, reject) => {
      return this.http.put(Urls.botSettings + "/botUrls", data).subscribe(
        (data) => {
          resolve(data);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  updateWidgetWindowSettings(obj) {
    return new Promise((resolve, reject) => {
      return this.http
        .put(Urls.botSettings + "/widgetWindow", {
          chatBotWidgetWindowDesign: obj,
        })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  getWidgetWindowSettings() {
    return new Promise((resolve, reject) => {
      return this.http.get(Urls.botSettings + "/widgetWindow").subscribe(
        (data) => {
          resolve(data);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  get quickReplyBotColor() {
    return this._quickReplyBotColor;
  }

  set quickReplyBotColor(color: string) {
    this._quickReplyBotColor = color;
  }
}
