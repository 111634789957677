<div class="common-flow">
  <div class="common-flow-header">
    <div class="common-heading d-flex w-100 overflow-auto">
      <div class="main-heading c-p mr-0" (click)="backToParentFolder('oneBack')">
        {{ "FOLDER" | translate }}
      </div>
      <ng-container *ngIf="subFolderNavList.length">
        <span class="d-flex c-p outline-none keep-all" *ngFor="let subFolder of subFolderNavList; index as sFIndex">
          <img src="../../../../../assets/images/right-arrow.svg" class="img-fluid ml-3 mr-3" alt="" />
          <span (click)="backToParentFolder('anyBack', sFIndex)" class="sub-folder-name white-space-no-wrap">{{
            subFolder.folderName
          }}</span>
        </span>
      </ng-container>
    </div>
    <div class="language" *ngIf="languageSelector">
      <div
        class="d-flex border-gray language-selection-div ml-10 w-px-100"
        *ngIf="settingService.languages.length && settingService.languages.length > 1"
      >
        <img height="18px" width="18px" alt="" [src]="settingService.getFlag(selectedLanguageFlowLanguage)" />
        <mat-select class="pl-10" [(ngModel)]="selectedLanguageFlowLanguage" (selectionChange)="getFlowBuilderList()">
          <mat-option [value]="language" *ngFor="let language of settingService.languages; index as lIndex"
            ><img height="15px" width="15px" alt="" [src]="settingService.getFlag(language)" />
            {{ settingService.generateLanguage(language) }}
          </mat-option>
        </mat-select>
      </div>
    </div>
  </div>
  <div class="common-flow-body">
    <div class="after-border-bottom">
      <div class="main-heading" *ngIf="allowCreate">
        {{ "FOLDER" | translate }}
        <div class="c-p new-Folder-wrap" aria-hidden="false" aria-label="add" (click)="openDialog(createFolderTemplate, 'newFolder')">
          <img src="../../../../../assets/images/plus-icons.svg" class="img-fluid mr-3" alt="" srcset="" />
          <span>{{ "ADD_NEW" | translate }}</span>
        </div>
      </div>
    </div>
    <div
      class="folder mr-5"
      [ngStyle]="{ height: height }"
      [ngClass]="{ 'd-flex flex-flow-column align-center nlp-no-data': folders.length === 0 }"
      infiniteScroll
      [scrollWindow]="false"
      [infiniteScrollDistance]="1"
      [infiniteScrollUpDistance]="2"
      [infiniteScrollThrottle]="50"
      [fromRoot]="true"
      (scrolled)="onScrollDown()"
    >
      <div class="container" *ngIf="folders.length; else noDataFound">
        <div class="row">
          <div class="col-12" *ngFor="let folder of folders; index as fIndex">
            <div
              class="folder-inner"
              [ngClass]="{ activeView: (selectedFolder.folder && folder._id === selectedFolder.folder._id) || folder.isOpen }"
            >
              <div id="folder-main" [class]="folder.class">
                <div class="d-flex align-center pl-10" (click)="openSubFolder(fIndex, true)">
                  <div class="drop-down-arrow" [ngClass]="{ 'drop-down-arrow--rotate': folder.isOpen }">
                    <img src="../../../../../assets/images/arror-right.svg" class="img-fluid" alt="" />
                  </div>
                  <div id="folders">
                    <div class="icon-text">
                      <p>{{ folder.folderName | shortName }}</p>
                    </div>
                  </div>
                  <div class="folder-name" [matTooltip]="'CLICK_TO_OPEN' | translate">
                    <span class="nlp-folderName" *ngIf="!folder.isRename">{{ folder.folderName }}</span>
                    <input
                      *ngIf="folder.isRename"
                      [id]="'folder-' + fIndex"
                      type="text"
                      class="edit-Flow_name w-100"
                      [value]="folder.folderName"
                      (click)="$event.stopPropagation()"
                      (focusin)="saveLastName($event)"
                      (keyup.enter)="saveData('folder', $event, fIndex)"
                      (blur)="saveData('folder', $event, fIndex)"
                    />
                    <div class="folder-count-badge" *ngIf="allowCreate && type === 'nlp'">
                      {{ folder.intentCount }}
                    </div>
                  </div>
                </div>
                <mat-icon
                  *ngIf="!(isSearch && searchSubFolderNavList.length === 0) && allowCreate"
                  (click)="onActionClick(fIndex, $event, 'parentFolder')"
                  class="align-right-icon"
                  aria-hidden="false"
                  aria-label="add"
                  [mat-menu-trigger-for]="parentMenu"
                  >more_vert
                </mat-icon>
                <mat-menu #parentMenu>
                  <div class="folder-action-icon-div-main d-flex align-center" (click)="this.renameFolder('parent', fIndex)">
                    <img src="../../../../../assets/images/action-icon/pencil.svg" class="img-fluid mr-3" alt="" />
                    <p class="mb-0">{{ "RENAME" | translate }}</p>
                  </div>
                  <div
                    class="folder-action-icon-div-main d-flex align-center"
                    (click)="openDialog(moveToTemplate, 'moveToFolder', fIndex, folder, true)"
                  >
                    <img src="../../../../../assets/images/move.svg" class="img-fluid mr-3" alt="" />
                    <p class="mb-0">{{ "MOVE" | translate }}</p>
                  </div>
                  <div class="folder-action-icon-div-main d-flex align-center" (click)="checkDeleteFolder(folder, fIndex, 'parentFolder')">
                    <img src="../../../../../assets/images/delete-icon.svg" class="img-fluid mr-3" alt="" />
                    <p class="mb-0 text-danger-p">{{ "DELETE" | translate }}</p>
                  </div>
                </mat-menu>
              </div>
              <!-- subfolder  -->
              <ul id="subFolderWrap" [class]="folder.class" *ngIf="folder.isOpen">
                <ng-container *ngIf="subFolderList.length">
                  <li
                    class="sub-folder d-flex align-center c-p"
                    *ngFor="let subFolder of subFolderList; index as SIndex"
                    (click)="openSubFolder(SIndex, false, fIndex)"
                    [ngClass]="{
                      activeViewLi: tempSubFolderIndex === SIndex
                    }"
                  >
                    <div class="" id="folders">
                      <div class="icon-text">
                        <p>{{ subFolder.folderName | shortName }}</p>
                      </div>
                    </div>
                    <div class="folder-name" [matTooltip]="'CLICK_TO_OPEN' | translate">
                      <span class="nlp-folderName" *ngIf="!subFolder.isRename">{{ subFolder.folderName }}</span>
                      <input
                        *ngIf="subFolder.isRename"
                        [id]="'subFolder-' + SIndex"
                        type="text"
                        class="edit-Flow_name w-100"
                        [value]="subFolder.folderName"
                        (click)="$event.stopPropagation()"
                        (focusin)="saveLastName($event)"
                        (blur)="saveData('subFolder', $event, fIndex, SIndex)"
                      />
                    </div>
                    <mat-icon
                      *ngIf="allowCreate"
                      (click)="onActionClick(SIndex, $event, 'subFolder')"
                      class="align-right-icon c-p"
                      aria-hidden="false"
                      aria-label="add"
                      [mat-menu-trigger-for]="subMenu"
                      >more_vert
                    </mat-icon>
                    <mat-menu #subMenu>
                      <div class="folder-action-icon-div-main d-flex align-center" (click)="this.renameFolder('child', SIndex)">
                        <img src="../../../../../assets/images/action-icon/pencil.svg" class="img-fluid mr-3" alt="" />
                        <p class="mb-0">{{ "RENAME" | translate }}</p>
                      </div>
                      <div
                        class="folder-action-icon-div-main d-flex align-center"
                        (click)="openDialog(moveToTemplate, 'moveToFolder', SIndex, subFolder, false)"
                      >
                        <img src="../../../../../assets/images/move.svg" class="img-fluid mr-3" alt="" />
                        <p class="mb-0">{{ "MOVE" | translate }}</p>
                      </div>
                      <div
                        class="folder-action-icon-div-main d-flex align-center"
                        (click)="checkDeleteFolder(subFolder, SIndex, 'subFolder')"
                      >
                        <img src="../../../../../assets/images/delete-icon.svg" class="img-fluid mr-3" alt="" />
                        <p class="mb-0 text-danger-p">{{ "DELETE" | translate }}</p>
                      </div>
                    </mat-menu>
                  </li>
                </ng-container>
                <li *ngIf="allowCreate">
                  <div
                    class="create-folder"
                    *ngIf="!(isSearch && searchSubFolderNavList.length === 0)"
                    aria-hidden="false"
                    aria-label="add"
                    (click)="openDialog(createFolderTemplate, 'subFolder'); isMainFolder = false; fromDisplay = false"
                  >
                    <img src="../../../../../assets/images/plus-icons.svg" class="img-fluid mr-3" alt="" srcset="" />
                    <span>{{ "ADD_NEW_SUB_FOLDER" | translate }}</span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <ng-template #noDataFound>
        {{ "NO_FOLDER_FOUND" | translate }}
      </ng-template>
    </div>
    <div class="right-side--lower-block overflow-hidden" *ngIf="isShowFlow">
      <div class="new-chat-sub-title-row">
        <div class="page-subtitle">{{ "FLOW" | translate }}</div>

        <div class="d-flex align-center">
          <app-search [search]="search" [placeholder]="'SEARCH' | translate" (searchList)="searchList($event)"></app-search>
          <img
            class="view-icon mr-10 c-p"
            [ngClass]="{ 'view-selected': gridViewSelected }"
            src="../../../../../assets/images/grid.svg"
            alt=""
            (click)="selectView()"
          />
          <img
            class="ml-10 view-icon c-p"
            [ngClass]="{ 'view-selected': !gridViewSelected }"
            src="../../../../../assets/images/list-icon.svg"
            alt=""
            (click)="selectView()"
          />
        </div>
      </div>

      <!-- if grid view selected -->
      <ng-container *ngIf="gridViewSelected">
        <div class="flow-container" [ngClass]="{ 'd-flex flex-flow-column align-center nlp-no-data': flowBuilders.length === 0 }">
          <ng-container *ngIf="flowBuilders.length; else noListFlowFound"></ng-container>
          <div
            class="flow-grid-block"
            *ngFor="let flow of flowBuilders; index as flowIndex"
            [ngClass]="flow._id === currentSelectedFlow ? 'selected-flow' : ''"
            (click)="emitFlowSelectEvent(flow._id, flow.flowName, flow.thumbnail)"
          >
            <img [src]="flow.thumbnail ? flow.thumbnail : 'assets/images/flow-thumb.png'" alt="" />
            <div class="grid-flow-detail">
              <div class="details">
                <div class="page-subtitle">
                  {{ flow.flowName }}
                </div>
                <div>Edited {{ flow.updatedAt | date: "d MMM y":"":selectedLanguage }}</div>
              </div>
              <img [src]="channelIcon" alt="" />
            </div>
          </div>

          <ng-template #noListFlowFound>
            {{ "NO_FLOW_FOUND" | translate }}
          </ng-template>
        </div>
      </ng-container>

      <!-- if list view selected -->
      <ng-container *ngIf="!gridViewSelected">
        <div
          class="flow-container-main flow-container"
          [ngClass]="{ 'd-flex flex-flow-column align-center nlp-no-data': flowBuilders.length === 0 }"
        >
          <ng-container *ngIf="flowBuilders.length; else noListFlowFound">
            <div
              class="flow-list-view"
              *ngFor="let flow of flowBuilders; index as flowIndex"
              [ngClass]="flow._id === currentSelectedFlow ? 'selected-flow' : ''"
              (click)="emitFlowSelectEvent(flow._id, flow.flowName, flow.thumbnail)"
            >
              <div class="grid-flow-detail">
                <div class="details">
                  <div class="page-subtitle">
                    {{ flow.flowName }}
                  </div>
                </div>
                <img [src]="channelIcon" alt="" />
              </div>
            </div>
          </ng-container>

          <ng-template #noListFlowFound>
            {{ "NO_FLOW_FOUND" | translate }}
          </ng-template>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<!--new Folder template -->
<ng-template #createFolderTemplate>
  <modal-create-folder (createFolderEvent)="createFolder($event)"></modal-create-folder>
</ng-template>
<!--Move To template -->
<ng-template #moveToTemplate>
  <modal-move-folder
    [type]="type"
    [displayFolders]="displayFolders"
    [moveFolder]="moveToFolder"
    (createFolderEvent)="createFolder($event)"
    (moveToFolderEvent)="editFolder('move')"
    (selectedFolderEvent)="openDisplaySubFolder($event)"
    (displayMainList)="displayList('displayMainList', null)"
    (displaySubList)="displayList('displaySubList', $event)"
  ></modal-move-folder>
</ng-template>
