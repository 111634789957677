import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { BotService } from "../../services/bot.service";
import { FlowBuilderService } from "../../services/flowbuilder.service";
import { NotifyService } from "../../services/noyify.service";
import { SettingService } from "../../services/setting.service";
import { TranslationService } from "../../services/translation.service";
import { ChannelsEnum } from "../../enums/common.enum";

@Component({
  selector: "app-flow-list-for-another-flow",
  templateUrl: "./flow-list-for-another-flow.component.html",
  styleUrls: ["./flow-list-for-another-flow.component.scss"],
})
export class FlowListForAnotherFlowComponent implements OnInit, OnChanges, OnDestroy {
  @Input() data;
  @Input() type;
  @Input() currentFlowId: string;
  @Input() botId = null;
  @Input() previewLanguages = [];
  @Output() stepFlowEmitter = new EventEmitter<any>();
  displayFolders = [];
  displayFlow = [];
  displaySubFolderNavList: any[] = [];
  selectedFlowIndex;
  isLoading = true;
  selectedLanguage = this._settingService.languages[0];
  languages = [];

  readonly unsubscribe: Subject<void> = new Subject();

  constructor(
    public _botService: BotService,
    private _flowBuilderService: FlowBuilderService,
    private _notificationService: NotifyService,
    public _settingService: SettingService,
    private _translationService: TranslationService
  ) {}

  ngOnInit() {
    if (this.botId && this.previewLanguages) {
      this.languages = this.previewLanguages;
      this.selectedLanguage = this.previewLanguages[0];
    } else {
      this.languages = this._settingService.languages;
    }
    this._botService
      .getUpdate()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => {
        this.getFlowBuilderList();
      });
  }

  getFlowBuilderList(folderId?) {
    this.isLoading = true;
    if (!folderId) {
      this.displaySubFolderNavList = [];
    }
    this._flowBuilderService
      .getAllFlowFolderList(this.type ? this.type : "all", this.data, this.selectedLanguage, folderId ? folderId : "")
      .then((result: any) => {
        this.displayFlow = result.result.docs;
        this.displayFolders = result.folderList;
        this.isLoading = false;
        this.setFolderColors();
      })
      .catch((err) => {
        this.isLoading = false;
        let errorMessage = "INTERNAL_SERVER_ERROR";
        if (err.error && err.error.error_code) {
          errorMessage = err.error.error_code;
        }
        this._translationService.getTranslate(errorMessage).then((res) => {
          this._notificationService.sendNotification("error", res);
        });
      });
  }

  openDisplaySubFolder(fIndex) {
    this.displaySubFolderNavList.push(this.displayFolders[fIndex]);
    this.getFlowBuilderList(this.displayFolders[fIndex]._id);
  }

  backToParentDisplayFolder(type, subFolderIndex?) {
    if (type === "oneBack") {
      if (this.displaySubFolderNavList.length === 1) {
        this.displaySubFolderNavList = [];
        this.getFlowBuilderList();
      } else {
        this.displaySubFolderNavList.splice(this.displaySubFolderNavList.length - 1, 1);
        this.getFlowBuilderList(this.displaySubFolderNavList[this.displaySubFolderNavList.length - 1]._id);
      }
    } else {
      this.displaySubFolderNavList.splice(subFolderIndex + 1);
      this.getFlowBuilderList(this.displaySubFolderNavList[this.displaySubFolderNavList.length - 1]._id);
    }
  }

  dismissModal(dialogRef) {
    dialogRef.close();
  }

  setSelection(flowIndex) {
    this.stepFlowEmitter.emit({
      selectedFlow: {
        flowName: this.displayFlow[flowIndex].flowName,
        _id: this.displayFlow[flowIndex]._id,
        parentFlowId: this.displayFlow[flowIndex].parentFlowId,
        tag: this.displayFlow[flowIndex].tag,
        broadcastType: this.displayFlow[flowIndex].broadcastType,
      },
    });
  }

  setFolderColors() {
    this.displayFolders.forEach((folder, i) => {
      if (i % 4 === 0) {
        folder.class = "yellow";
      } else if (i % 4 === 1) {
        folder.class = "red";
      } else if (i % 4 === 2) {
        folder.class = "green";
      } else {
        folder.class = "purple";
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.data.currentValue === ChannelsEnum.WHATSAPP ||
      changes.data.currentValue === ChannelsEnum.FACEBOOK ||
      changes.data.currentValue === ChannelsEnum.HANGOUTCHAT ||
      changes.data.currentValue === ChannelsEnum.SLACK ||
      changes.data.currentValue === ChannelsEnum.TWILIO ||
      changes.data.currentValue === ChannelsEnum.TWILIOSMS ||
      changes.data.currentValue === ChannelsEnum.GOOGLE ||
      changes.data.currentValue === ChannelsEnum.WEBCHAT ||
      changes.data.currentValue === ChannelsEnum.DIALOG360 ||
      changes.data.currentValue === ChannelsEnum.AUDIOCODES ||
      changes.data.currentValue === ChannelsEnum.UNIVERSAL ||
      changes.data.currentValue === ChannelsEnum.INSTAGRAM ||
      changes.data.currentValue === ChannelsEnum.TELEGRAM ||
      changes.data.currentValue === ChannelsEnum.MSTEAM ||
      changes.data.currentValue === ChannelsEnum.AZURE
    ) {
      this.data = changes.data.currentValue;
      this.getFlowBuilderList();
    }
  }

  onChangeLanguage() {
    this.getFlowBuilderList();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
