import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { AuthService } from "../services/auth.service";
import { Injectable } from "@angular/core";

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private _authService: AuthService) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const token = this._authService.getToken();
    const user = this._authService.getUser();
    if (user && token) {
      return true;
    } else {
      this.router.navigate(["login"], {
        queryParams: { returnUrl: state.url },
      });
      return false;
    }
  }
}
