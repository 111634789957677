import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

declare var $;

@Component({
  selector: "app-color-picker",
  templateUrl: "./color-picker.component.html",
  styleUrls: ["./color-picker.component.scss"],
})
export class ColorPickerComponent implements OnInit {
  @Input() selectedColor;
  @Input() type; // make you sure type is unique string this is used as Id if duplicate comes then not working..
  @Output() setSelectedColor = new EventEmitter();
  colors = [
    {
      color: "#FF3F3F",
      isSelected: false,
    },
    {
      color: "#09d28d",
      isSelected: false,
    },
    {
      color: "#393b3f",
      isSelected: false,
    },
    {
      color: "#858e8c",
      isSelected: false,
    },
    {
      color: "#C4C4C4",
      isSelected: false,
    },
  ];
  ngOnInit(): void {
    this.openColorPicker(this.type);
  }

  selectColor(colorIndex: number) {
    this.colors[colorIndex].isSelected = true;
    this.colors.forEach((e, index) => {
      if (index !== colorIndex) {
        e.isSelected = false;
      }
      return e;
    });
    this.setSelectedColor.emit({
      colorCode: this.colors[colorIndex].color,
      eventType: this.type,
    });
  }

  openColorPicker(id) {
    const tempColor = this.colors.find((color) => color.color === this.selectedColor);
    if (tempColor) {
      tempColor.isSelected = true;
    } else {
      this.colors.forEach((color) => (color.isSelected = false));
    }
    const _this = this;
    $(function () {
      $("#" + id).wheelColorPicker({
        layout: "block",
        format: "hexa",
        preview: false,
      });
      $("#" + id).wheelColorPicker("setValue", _this.selectedColor ? _this.selectedColor : "#FFFFFF");
      $("#" + id).on("colorchange", function (e) {
        const color = $(this).wheelColorPicker("value");
        _this.selectedColor = "#" + color;
        _this.setSelectedColor.emit({
          eventType: _this.type,
          colorCode: _this.selectedColor,
        });
      });
    });
  }

  setColor(event: any, id) {
    if (event.code === "Enter") {
      this.openColorPicker(id);
      this.setSelectedColor.emit({
        colorCode: event.target.value,
        eventType: this.type,
      });
    }
  }
}
