import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { Urls } from "../utils/urls";
import {
  ICreateFlowResponse,
  IDeleteDryRun,
  IFlow,
  IFlowBodyObject,
  IFolderList,
  ISummary,
  IUpdateFolderResponse,
} from "../models/flow-list";
import { IAPIDeletedSuccessfullyResponse, IAPIResponse, IPaginateResults } from "../models/common-model";
import {
  IFlowCSV,
  IFLowIcon,
  IFLowLanguages,
  IFlowVersioning,
  IPublishFlowErrorResponse,
  IPublishFlowSuccessResponse,
} from "../../bot-builder/models/flow.model";
import { IFlowBlocks } from "../../bot-builder/models/IFlowBlock.model";
import { Router } from "@angular/router";
import { IExtensionWorkflow, IntentFolder } from "../../bot-builder/models/IFlowSteps.model";

@Injectable()
export class FlowBuilderService {
  constructor(private http: HttpClient, private router: Router) {}

  selectedFolderActionIndex: number;
  selectedSubFolderIndex: number;
  noBlockOpened = true;

  private subject = new Subject<any>();
  flowNameSubject = new Subject<any>();
  publishRestoreFlow = new Subject();
  suggestionSubject = new Subject<any>();
  getVersionList = new Subject<any>();
  flowId;
  versionNumber;
  isRestoreVersion = false;
  tempMoveFlowList = [];

  getUpdates(): Observable<any> {
    return this.subject.asObservable();
  }

  sendUpdates(message) {
    this.subject.next(message);
  }

  setFlowBuilderNavigationList(list) {
    localStorage.setItem("flowBuilderNavigation", JSON.stringify(list));
  }

  getFlowBuilderNavigationList() {
    return JSON.parse(localStorage.getItem("flowBuilderNavigation"));
  }

  getIcons(): Promise<IFLowIcon> {
    return new Promise((resolve, reject) => {
      return this.http.get(`${Urls.flow_builder}all/icon`).subscribe({
        next: (data: IFLowIcon) => {
          resolve(data);
        },
        error: (err) => {
          reject(err);
        },
      });
    });
  }

  getVoiceConfig(channelId) {
    return new Promise((resolve, reject) => {
      return this.http.get(`${Urls.baseUrl}botsetting/ttsProvider/${channelId}`).subscribe(
        (data) => {
          resolve(data);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  getFlowSummary(flowId): Promise<ISummary> {
    return new Promise((resolve, reject) => {
      return this.http.get(Urls.analytics + "sanky/flow/" + flowId + "/all").subscribe(
        (data) => {
          resolve(data as ISummary);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  getFlowFolderList(page, search, type, channel, folderId?, sortType?, sortBy?) {
    let url;
    if (folderId) {
      url =
        Urls.flowFolder +
        "?page=" +
        page +
        "&limit=15&filter_param=" +
        search +
        "&type=" +
        type +
        "&channel=" +
        channel +
        "&folderId=" +
        folderId +
        "&sortBy=" +
        sortBy +
        "&sortType=" +
        sortType +
        "&timezone=" +
        Intl.DateTimeFormat().resolvedOptions().timeZone;
    } else {
      url =
        Urls.flowFolder +
        "?page=" +
        page +
        "&limit=15&filter_param=" +
        search +
        "&type=" +
        type +
        "&channel=" +
        channel +
        "&sortBy=" +
        sortBy +
        "&sortType=" +
        sortType +
        "&timezone=" +
        Intl.DateTimeFormat().resolvedOptions().timeZone;
    }
    return new Promise((resolve, reject) => {
      return this.http.get(url).subscribe(
        (data) => {
          resolve(data);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  getAllFlowFolderList(type, channel, language, folderId?, search?) {
    let url;
    if (folderId) {
      url =
        Urls.flowFolder +
        "?getAllFlow=" +
        true +
        "&type=" +
        type +
        "&channel=" +
        channel +
        "&language=" +
        language +
        "&folderId=" +
        folderId;
    } else {
      url = Urls.flowFolder + "?getAllFlow=" + true + "&type=" + type + "&channel=" + channel + "&language=" + language;
    }
    if (search) {
      url = url + "&filter_param=" + search;
    }
    return new Promise((resolve, reject) => {
      return this.http
        .get(url, {
          headers: {
            isPreviewLink: this.router.url.includes("preview") ? "true" : "false",
          },
        })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  createFlowFolder(object) {
    return new Promise((resolve, reject) => {
      return this.http.post(Urls.flowFolder, object).subscribe(
        (data) => {
          resolve(data);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  moveFlowFolder(object, folderId) {
    return new Promise((resolve, reject) => {
      return this.http.put(Urls.flowFolder + folderId, object).subscribe(
        (data) => {
          resolve(data);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  deleteFlowFolder(folderId, isDelete): Promise<IDeleteDryRun> {
    return new Promise((resolve, reject) => {
      return this.http.delete(Urls.flowFolder + folderId + "?dryRun=" + isDelete).subscribe(
        (data) => {
          resolve(data as IDeleteDryRun);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  updateFolder(folderId, object): Promise<IUpdateFolderResponse> {
    return new Promise((resolve, reject) => {
      return this.http.put(Urls.flowFolder + folderId, object).subscribe(
        (data) => {
          resolve(data as IUpdateFolderResponse);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  getFlowBuilderById(id, isPreview): any {
    let url;
    if (isPreview) {
      url = Urls.flow_builder + id + "/publish/flow";
    } else {
      url = Urls.flow_builder + id;
    }
    return new Promise((resolve, reject) => {
      return this.http.get(url).subscribe(
        (data) => {
          resolve(data);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  getOtherLanguageFlow(id: string): Promise<IFLowLanguages[]> {
    return new Promise((resolve, reject) => {
      return this.http.get(`${Urls.flow_builder}language-flow/${id}`).subscribe({
        next: (data: IFLowLanguages[]) => {
          resolve(data);
        },
        error: (err) => {
          reject(err);
        },
      });
    });
  }

  breakSyncForChild(id: string): Promise<void> {
    return new Promise((resolve, reject) => {
      return this.http.put(`${Urls.flow_builder}break-sync/${id}`, {}).subscribe({
        next: () => {
          resolve();
        },
        error: (err) => {
          reject(err);
        },
      });
    });
  }

  addFlow(object): Promise<ICreateFlowResponse> {
    return new Promise((resolve, reject) => {
      return this.http.post(Urls.flow_builder, object).subscribe(
        (data) => {
          resolve(data as ICreateFlowResponse);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  updateFlow(id, object): Promise<void> {
    return new Promise((resolve, reject) => {
      return this.http.put(Urls.flow_builder + id, object).subscribe({
        next: () => {
          resolve();
        },
        error: (err) => {
          reject(err);
        },
      });
    });
  }

  copyFlow(flowId, object) {
    return new Promise((resolve, reject) => {
      return this.http.put(Urls.flow_builder + "copy/flow/" + flowId, object).subscribe(
        (data) => {
          resolve(data);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }
  deleteMultipleFlow(object) {
    return new Promise((resolve, reject) => {
      return this.http.put(Urls.flow_builder + "delete/flow", object).subscribe(
        (data) => {
          resolve(data);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  moveMultipleFlow(object) {
    return new Promise((resolve, reject) => {
      return this.http.put(Urls.flow_builder + "move/flow", object).subscribe(
        (data) => {
          resolve(data);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  publishFlow(id): Promise<IPublishFlowErrorResponse[] | IPublishFlowSuccessResponse> {
    return new Promise((resolve, reject) => {
      return this.http.put(`${Urls.flow_builder}${id}/publish/flow`, {}).subscribe({
        next: (data: IPublishFlowErrorResponse[] | IPublishFlowSuccessResponse) => {
          resolve(data);
        },
        error: (err) => {
          reject(err);
        },
      });
    });
  }

  restoreFlow(id) {
    return new Promise((resolve, reject) => {
      return this.http.get(Urls.flow_builder + id + "/restore/publish/flow").subscribe(
        (data) => {
          resolve(data);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  restoreVersion(id) {
    return new Promise((resolve, reject) => {
      return this.http.get(Urls.flow_builder + id + "/restore/publish/flow").subscribe(
        (data) => {
          resolve(data);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  previewPublishFlow(id) {
    return new Promise((resolve, reject) => {
      return this.http.get(Urls.flow_builder + id + "/publish/flow").subscribe(
        (data) => {
          resolve(data);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  deleteFlow(object): Observable<any> {
    return this.http.put<any>(`${Urls.flow_builder}flow/delete`, object);
  }

  moveFlow(object): Observable<any> {
    return this.http.put<any>(`${Urls.flow_builder}flow/move`, object);
  }

  saveStep(id, object) {
    return new Promise((resolve, reject) => {
      return this.http.post(Urls.flow_builder_step + id, object).subscribe(
        (data) => {
          resolve(data);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  editStep(id, object) {
    return new Promise((resolve, reject) => {
      return this.http.put(Urls.flow_builder_step + id, object).subscribe(
        (data) => {
          resolve(data);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  deleteStep(id) {
    return new Promise((resolve, reject) => {
      return this.http.delete(Urls.flow_builder_step + id).subscribe(
        (data) => {
          resolve(data);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  deletemessage(id) {
    return new Promise((resolve, reject) => {
      return this.http.delete(Urls.floW_messages + "/" + id).subscribe(
        (data) => {
          resolve(data);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  getAllFlowList(type): any {
    return new Promise((resolve, reject) => {
      return this.http.get(Urls.flow_builder + "all/" + type).subscribe(
        (data) => {
          resolve(data);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  getChannelFlowList(channel, type): any {
    return new Promise((resolve, reject) => {
      return this.http.get(Urls.flow_builder + "all/" + type + "?channel=" + channel).subscribe(
        (data) => {
          resolve(data);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  getAllDialogList(id): any {
    return new Promise((resolve, reject) => {
      return this.http.get(Urls.flow_dialog + "?botId=" + id).subscribe(
        (data) => {
          resolve(data);
        },
        (err) => {
          console.log(err);
          reject(err);
        }
      );
    });
  }

  getAllListOfFunction(botId): any {
    return new Promise((resolve, reject) => {
      return this.http.get(Urls.flow_builder + "operations").subscribe(
        (data) => {
          resolve(data);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  /*new methods for block*/

  saveBlock(id, object): any {
    return new Promise((resolve, reject) => {
      return this.http.post(Urls.flow_builder + id + "/block", object).subscribe(
        (data) => {
          resolve(data);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  copyBlock(id, object): Promise<IFlowBlocks> {
    return new Promise((resolve, reject) => {
      return this.http.put(`${Urls.flow_builder}${id}/copy/block`, object).subscribe({
        next: (data: IFlowBlocks) => {
          resolve(data);
        },
        error: (err) => {
          reject(err);
        },
      });
    });
  }

  updateBlock(id, blockId, object) {
    return new Promise((resolve, reject) => {
      return this.http.put(Urls.flow_builder + id + "/block/" + blockId, object).subscribe(
        (data) => {
          resolve(data);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }
  updateMultiplBlock(id, object) {
    return new Promise((resolve, reject) => {
      return this.http
        .put(Urls.flow_builder + id + "/updatePositionsOfMultipleBlock", {
          blocksToUpdate: object,
        })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }
  /* ACTION ONLY ON SAVE BUTTON CHANGES | START */
  updateBlockWithAllSteps(id, blockId, object) {
    return new Promise((resolve, reject) => {
      return this.http.put(Urls.flow_builder + id + "/block/" + blockId + "/save/", object).subscribe(
        (data) => {
          resolve(data);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }
  /* ACTION ONLY ON SAVE BUTTON CHANGES | END */

  deleteBlock(id: string, blockId: string): Promise<void> {
    return new Promise((resolve, reject) => {
      return this.http.delete(`${Urls.flow_builder}${id}/block/${blockId}`).subscribe({
        next: () => {
          resolve();
        },
        error: (err) => {
          reject(err);
        },
      });
    });
  }

  saveBlockStep(botId, blockId, obj): any {
    return new Promise((resolve, reject) => {
      return this.http.post(Urls.flow_builder + botId + "/block/" + blockId + "/step", obj).subscribe(
        (data) => {
          resolve(data);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  deleteBlockStep(id, blockId, stepId) {
    return new Promise((resolve, reject) => {
      return this.http.delete(Urls.flow_builder + id + "/block/" + blockId + "/step/" + stepId).subscribe(
        (data) => {
          resolve(data);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  updateBlockStepRedirect(id, obj) {
    return new Promise((resolve, reject) => {
      return this.http.put(Urls.flow_builder + "step/" + id + "/redirect-update", obj).subscribe(
        (data) => {
          resolve(data);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  setDefaultStartingFlow(obj) {
    return new Promise((resolve, reject) => {
      return this.http.put(Urls.bdk_common, obj).subscribe(
        (data) => {
          resolve(data);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  getDefaultStartingFlow(botId): any {
    return new Promise((resolve, reject) => {
      return this.http.get(Urls.bdk_common).subscribe(
        (data) => {
          resolve(data);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  uploadCommonFile(uploadLocation, fd) {
    return new Promise((resolve, reject) => {
      return this.http.post(Urls.flow_builder + uploadLocation, fd).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  getAllAgents(): any {
    return new Promise((resolve, reject) => {
      return this.http.get(Urls.usersAgentsList).subscribe(
        (data) => {
          resolve(data);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  getAllTeam(): any {
    return new Promise((resolve, reject) => {
      return this.http.get(Urls.teamList).subscribe(
        (data) => {
          resolve(data);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  exportCSV(id: string): Promise<IFlowCSV> {
    return new Promise((resolve, reject) => {
      return this.http.get(`${Urls.flow_builder}${id}/export-to-csv`).subscribe({
        next: (data: IFlowCSV) => {
          resolve(data);
        },
        error: (err) => {
          reject(err);
        },
      });
    });
  }
  getVersion() {
    return new Promise((resolve, reject) => {
      return this.http.get(Urls.flow_builder + "version/" + this.flowId + "/" + this.versionNumber).subscribe(
        (data: any) => {
          resolve(data);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  getVersionsPaginated(limit): Promise<IPaginateResults<IFlowVersioning>> {
    return new Promise((resolve, reject) => {
      return this.http.get(`${Urls.flow_builder}version/${this.flowId}/?page=1&limit=${limit}`).subscribe(
        (data: IPaginateResults<IFlowVersioning>) => {
          resolve(data);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  restoreCurrentVersion() {
    return new Promise((resolve, reject) => {
      return this.http.post(Urls.flow_builder + "version/" + this.flowId + "/" + this.versionNumber, {}).subscribe(
        (data: any) => {
          resolve(data);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  createThumb(requestBody, id) {
    return new Promise((resolve, reject) => {
      return this.http.post(Urls.flow_builder + "uploadThumbnail/" + id, requestBody).subscribe(
        (data) => {
          resolve(data);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  getSankeyGraphAnalyticsForFlow(flowId, date) {
    return new Promise((resolve, reject) => {
      return this.http.get(Urls.analytics + "sanky/flow/" + flowId + "?" + date).subscribe(
        (data: any) => {
          resolve(data);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  getFolderList(folderId?): Promise<IFolderList> {
    let url = "";
    if (folderId) {
      url = Urls.flowFolder + "bot" + "?folderId=" + folderId;
    } else {
      url = Urls.flowFolder + "bot";
    }
    return new Promise((resolve, reject) => {
      return this.http.get(url).subscribe(
        (data) => {
          resolve(data as IFolderList);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  getFlowList(requestObject: IFlowBodyObject): Promise<IPaginateResults<IFlow>> {
    let url;
    if (requestObject.folderId) {
      url =
        Urls.flow_builder +
        "list" +
        "?page=" +
        requestObject.page +
        "&limit=" +
        requestObject.limit +
        "&filterParam=" +
        requestObject.filterParam +
        "&type=" +
        requestObject.type +
        "&channel=" +
        requestObject.channel +
        "&folderId=" +
        requestObject.folderId +
        "&sortBy=" +
        requestObject.sortBy +
        "&sortType=" +
        requestObject.sortType +
        "&timezone=" +
        requestObject.timezone;
    } else {
      url =
        Urls.flow_builder +
        "list" +
        "?page=" +
        requestObject.page +
        "&limit=" +
        requestObject.limit +
        "&filterParam=" +
        requestObject.filterParam +
        "&type=" +
        requestObject.type +
        "&channel=" +
        requestObject.channel +
        "&sortBy=" +
        requestObject.sortBy +
        "&sortType=" +
        requestObject.sortType +
        "&timezone=" +
        requestObject.timezone;
    }
    return new Promise((resolve, reject) => {
      return this.http.get(url).subscribe(
        (data) => {
          resolve(data as IPaginateResults<IFlow>);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  getAllExtensionWorkflowList(): Observable<IAPIResponse<Array<IExtensionWorkflow>>> {
    return this.http.get<IAPIResponse<Array<IExtensionWorkflow>>>(`${Urls.flow_builder}extensionWorkflows/bot`);
  }

  getFolderForQuestionType(typeOfNlp: string, language): Observable<IAPIResponse<IntentFolder>> {
    return this.http.get<IAPIResponse<IntentFolder>>(`${Urls.getNlpFolder}/${typeOfNlp}/${language}`);
  }
}
