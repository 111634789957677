<div class="mt-10">
  <div class="map-count-wrap d-flex justify-content-center align-center">
    <img src="../../../../../assets/images/map.svg" class="img-fluid" alt="" />
    <span>{{ step.inputMapping.length }} {{ "MAPPED" | translate }}</span>
  </div>
  <popper-content #mappingContent>
    <div class="btn-popup-main-div">
      <div class="btn-popup-heading">
        <span>{{ "ADD_MAPPING" | translate }}</span>
      </div>
      <div class="p-20 mt-fd pb-0">
        <div class="btn-popup-heading p-0 border-none just-space-bet mb-10 align-items-center d-flex">
          <div class="just-start d-flex align-items-center">
            <span class="mr-10">{{ "MAPPING" | translate }}</span>
            <ng-container *ngIf="FIType !== 'string'">
              <mat-icon
                [popper]="tooltipFi"
                [popperTrigger]="'hover'"
                [popperPlacement]="'right'"
                [popperPreventOverflow]="false"
                [popperPositionFixed]="true"
                [popperApplyClass]="'warning-popper'"
                [popperApplyArrowClass]="'warning-arrow'"
                [popperHideOnMouseLeave]="false"
                class="operation c-p top-10"
                aria-hidden="false"
                >error_outline
              </mat-icon>
              <popper-content #tooltipFi>
                <div class="error-warning-text-div">
                  <p>
                    {{ infoMessage | translate }}
                  </p>
                </div>
              </popper-content>
            </ng-container>
          </div>
          <div>
            <span
              *ngIf="this.flowObject._id === this.flowObject.parentFlowId || !this.flowObject.isSync"
              class="material-icons ml-3 c-p light-white-color"
              (click)="$event.stopPropagation(); addInputMappingChanged.emit(); scrollBottom.emit()"
            >
              add_circle_outline
            </span>
          </div>
        </div>
        <div [id]="listId" class="height-fit overflow-y listIdMain">
          <div class="btn-text-div1" *ngFor="let inputMapping of step.inputMapping; index as iIndex">
            <div class="question-mapping-div">
              <mat-form-field class="w-100">
                <div class="d-flex align-center">
                  <mat-select
                    #matSelect
                    class="question-text common-select-mat"
                    panelClass="stickyAddTag"
                    [(ngModel)]="inputMapping.placeHolder"
                    (click)="removeBackDrop()"
                    (selectionChange)="breakContentSync.emit()"
                    (clickOutside)="matSelect.close()"
                  >
                    <ng-container *ngIf="flexibleIndicatorList.length">
                      <ng-container *ngFor="let FI of flexibleIndicatorList">
                        <mat-option (click)="$event.stopPropagation()" *ngIf="FI.type === FIType" [value]="FI.field">{{
                          FI.field
                        }}</mat-option>
                      </ng-container>
                    </ng-container>
                    <ng-container *ngIf="!flexibleIndicatorList.length">
                      <mat-option (click)="$event.stopPropagation()" [value]="null" [disabled]="true"></mat-option>
                    </ng-container>
                    <mat-divider></mat-divider>
                    <mat-option>
                      <div class="p-8 c-p d-flex align-center" (click)="openFIDialog.emit(iIndex)">
                        <mat-icon>add</mat-icon>
                        {{ "ADD_FLEXIBLE_INDICATOR" | translate }}
                      </div>
                    </mat-option>
                  </mat-select>
                  <img
                    *ngIf="this.flowObject._id === this.flowObject.parentFlowId || !this.flowObject.isSync"
                    src="../../../../../assets/images/delete-popper.svg"
                    alt=""
                    class="mat-tooltip-trigger img-fluid delete-message-icon ml-2"
                    (click)="deleteInputMapping.emit(iIndex)"
                  />
                </div>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="btn-action-div">
          <button
            class="w-100 d-flex align-center"
            mat-flat-button
            color="primary"
            (click)="closePopup.emit(mappingContent)"
            aria-hidden="false"
          >
            <img src="../../../../../assets/images/verified.svg" class="img-fluid mr-3" alt="" />
            {{ "DONE" | translate }}
          </button>
        </div>
      </div>
    </div>
  </popper-content>
  <button
    class="common-button"
    [popper]="mappingContent"
    [popperTrigger]="'click'"
    [popperPlacement]="'right'"
    [popperPreventOverflow]="false"
    [popperPositionFixed]="true"
    [popperApplyClass]="'custom-popper'"
    [popperApplyArrowClass]="'custom-arrow'"
    [popperHideOnMouseLeave]="false"
    (click)="addInputMappingBlank.emit()"
  >
    <div class="common-button-div">
      <mat-icon aria-hidden="false">add_circle_outline</mat-icon>
      <span>{{ "ADD_MAPPING" | translate }}</span>
    </div>
  </button>
</div>
