import { Injectable } from "@angular/core";
import { Urls } from "../utils/urls";
import { HttpClient } from "@angular/common/http";
import { IPaginateResults } from "../models/common-model";
import { IAddEditTag, ITag, ITagCreateResponse, ITags } from "../models/tags";

@Injectable()
export class TagsService {
  constructor(private http: HttpClient) {}

  getTagsList(id: string): Promise<{ tags: ITag[] }> {
    return new Promise((resolve, reject) => {
      this.http.get(Urls.tags).subscribe({
        next: (data: { tags: ITag[] }) => {
          resolve(data);
        },
        error: (err) => {
          reject(err);
        },
      });
    });
  }

  getTagsPaginated(requestParams): Promise<IPaginateResults<ITags>> {
    return new Promise((resolve, reject) => {
      this.http
        .get(`${Urls.tags}paginate?limit=${requestParams.limit}&page=${requestParams.page}&filter_param=${requestParams.searchParams}`)
        .subscribe({
          next: (data: IPaginateResults<ITags>) => {
            resolve(data);
          },
          error: (err) => {
            reject(err);
          },
        });
    });
  }

  addTag(tag: IAddEditTag): Promise<ITagCreateResponse> {
    return new Promise((resolve, reject) => {
      this.http.post(Urls.tags, tag).subscribe({
        next: (data: ITagCreateResponse) => {
          resolve(data);
        },
        error: (err) => {
          reject(err);
        },
      });
    });
  }

  editTag(tag: IAddEditTag, tagId: string) {
    return new Promise((resolve, reject) => {
      this.http.put(`${Urls.tags}update/${tagId}`, { tag }).subscribe({
        next: (data) => {
          resolve(data);
        },
        error: (err) => {
          reject(err);
        },
      });
    });
  }

  deleteTag(tagId: string[], dryRun: boolean): Promise<Array<string>> {
    return new Promise((resolve, reject) => {
      this.http
        .put(`${Urls.tags}delete?dryRun=${dryRun}`, {
          tagsToDelete: tagId,
        })
        .subscribe({
          next: (data: Array<string>) => {
            resolve(data);
          },
          error: (err) => {
            reject(err);
          },
        });
    });
  }

  assignTags(id: string, object): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http.put(Urls.tags, object).subscribe({
        next: () => {
          resolve();
        },
        error: (err) => {
          reject(err);
        },
      });
    });
  }
}
