<div class="bg-design">
  <div class="after-border-bottom">
    <div class="main-heading" *ngIf="showBlock">
      {{ "FOLDER" | translate }}
      <div
        class="c-p new-Folder-wrap"
        aria-hidden="false"
        aria-label="add"
        (click)="openDialog(createFolderTemplate, 'newFolder'); fromDisplay = false; isMainFolder = true"
      >
        <img src="../../../../assets/images/plus-icons.svg" class="img-fluid mr-3" alt="" srcset="" />
        <span>{{ "ADD_NEW" | translate }}</span>
      </div>
    </div>
  </div>
  <ng-container *ngIf="folders">
    <div
      *ngIf="folders.length"
      class="main-folders-list"
      [ngStyle]="{ padding: showBlock ? '15px 20px' : '0px' }"
      [ngClass]="{
        'main-folders-list-half': viewType === 'half'
      }"
    >
      <div
        class="col-md-12 p-0"
        *ngFor="let folder of folders; index as fIndex"
        [ngClass]="{
          activeView: (selectedFolder && folder._id === selectedFolder._id) || folder.isOpen
        }"
      >
        <div id="folder-main" [class]="folder.class">
          <div class="d-flex align-center pl-10" (click)="openSubFolder(fIndex, true)">
            <div class="drop-down-arrow" [ngClass]="{ 'drop-down-arrow--rotate': folder.isOpen }">
              <img src="../../../../assets/images/arror-right.svg" class="img-fluid" alt="" />
            </div>
            <div id="folders">
              <div class="icon-text">
                <p>{{ folder.folderName | shortName }}</p>
              </div>
            </div>
            <div class="folder-name" [matTooltip]="'CLICK_TO_OPEN' | translate">
              <span class="nlp-folderName" *ngIf="!folder.isRename">{{ folder.folderName }}</span>
              <input
                *ngIf="folder.isRename"
                [id]="'folder-' + fIndex"
                type="text"
                class="edit-Flow_name w-100"
                [value]="folder.folderName"
                (click)="$event.stopPropagation()"
                (focusin)="saveLastName($event)"
                (keyup.enter)="saveData('folder', $event, fIndex)"
                (blur)="saveData('folder', $event, fIndex)"
              />
              <div class="folder-count-badge" *ngIf="showBlock && type === 'nlp'">
                {{ folder.intentCount }}
              </div>
            </div>
          </div>
          <mat-icon
            *ngIf="!(isSearch && searchSubFolderNavList.length === 0) && showBlock"
            (click)="onActionClick(fIndex, $event, 'parentFolder')"
            class="align-right-icon"
            aria-hidden="false"
            aria-label="add"
            [mat-menu-trigger-for]="parentMenu"
            >more_vert
          </mat-icon>
          <mat-menu #parentMenu>
            <div class="folder-action-icon-div-main d-flex align-center" (click)="this.renameFolder('parent', fIndex)">
              <img src="../../../../assets/images/action-icon/pencil.svg" class="img-fluid mr-3" alt="" />
              <p class="mb-0">{{ "RENAME" | translate }}</p>
            </div>
            <div
              class="folder-action-icon-div-main d-flex align-center"
              (click)="openDialog(moveToTemplate, 'moveToFolder', fIndex, folder, true)"
            >
              <img src="../../../../assets/images/move.svg" class="img-fluid mr-3" alt="" />
              <p class="mb-0">{{ "MOVE" | translate }}</p>
            </div>
            <div class="folder-action-icon-div-main d-flex align-center" (click)="checkDeleteFolder(folder, fIndex, 'parentFolder')">
              <img src="../../../../assets/images/delete-icon.svg" class="img-fluid mr-3" alt="" />
              <p class="mb-0 text-danger-p">{{ "DELETE" | translate }}</p>
            </div>
          </mat-menu>
        </div>

        <!-- subfolder  -->
        <ul id="subFolderWrap" [class]="folder.class" *ngIf="folder.isOpen">
          <ng-container *ngIf="subFolderList.length">
            <li
              class="sub-folder d-flex align-center c-p"
              *ngFor="let subFolder of subFolderList; index as SIndex"
              (click)="openSubFolder(SIndex, false, fIndex)"
              [ngClass]="{
                activeViewLi: tempSubFolderIndex === SIndex
              }"
            >
              <div class="" id="folders">
                <div class="icon-text">
                  <p>{{ subFolder.folderName | shortName }}</p>
                </div>
              </div>
              <div class="folder-name" [matTooltip]="'CLICK_TO_OPEN' | translate">
                <span class="nlp-folderName" *ngIf="!subFolder.isRename">{{ subFolder.folderName }}</span>
                <input
                  *ngIf="subFolder.isRename"
                  [id]="'subFolder-' + SIndex"
                  type="text"
                  class="edit-Flow_name w-100"
                  [value]="subFolder.folderName"
                  (click)="$event.stopPropagation()"
                  (focusin)="saveLastName($event)"
                  (blur)="saveData('subFolder', $event, fIndex, SIndex)"
                />
              </div>
              <mat-icon
                *ngIf="showBlock"
                (click)="onActionClick(SIndex, $event, 'subFolder')"
                class="align-right-icon c-p"
                aria-hidden="false"
                aria-label="add"
                [mat-menu-trigger-for]="subMenu"
                >more_vert
              </mat-icon>
              <mat-menu #subMenu>
                <div class="folder-action-icon-div-main d-flex align-center" (click)="this.renameFolder('child', SIndex)">
                  <img src="../../../../assets/images/action-icon/pencil.svg" class="img-fluid mr-3" alt="" />
                  <p class="mb-0">{{ "RENAME" | translate }}</p>
                </div>
                <div
                  class="folder-action-icon-div-main d-flex align-center"
                  (click)="openDialog(moveToTemplate, 'moveToFolder', SIndex, subFolder, false)"
                >
                  <img src="../../../../assets/images/move.svg" class="img-fluid mr-3" alt="" />
                  <p class="mb-0">{{ "MOVE" | translate }}</p>
                </div>
                <div class="folder-action-icon-div-main d-flex align-center" (click)="checkDeleteFolder(subFolder, SIndex, 'subFolder')">
                  <img src="../../../../assets/images/delete-icon.svg" class="img-fluid mr-3" alt="" />
                  <p class="mb-0 text-danger-p">{{ "DELETE" | translate }}</p>
                </div>
              </mat-menu>
            </li>
          </ng-container>
          <li *ngIf="showBlock">
            <div
              class="create-folder"
              *ngIf="!(isSearch && searchSubFolderNavList.length === 0)"
              aria-hidden="false"
              aria-label="add"
              (click)="openDialog(createFolderTemplate, 'subFolder'); isMainFolder = false; fromDisplay = false"
            >
              <img src="../../../../assets/images/plus-icons.svg" class="img-fluid mr-3" alt="" srcset="" />
              <span>{{ "ADD_NEW_SUB_FOLDER" | translate }}</span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </ng-container>
</div>
<!--new Folder template -->
<ng-template #createFolderTemplate>
  <modal-create-folder (createFolderEvent)="createFolder($event)"></modal-create-folder>
</ng-template>
<!--Move To template -->
<ng-template #moveToTemplate>
  <modal-move-folder
    [type]="type"
    [displayFolders]="displayFolders"
    [moveFolder]="moveToFolder"
    (createFolderEvent)="createFolder($event)"
    (moveToFolderEvent)="editFolder('move')"
    (selectedFolderEvent)="openDisplaySubFolder($event)"
    (displayMainList)="displayMainListFunction()"
    (displaySubList)="displaySubListFunction($event)"
  ></modal-move-folder>
</ng-template>
