<div class="modal-body">
  <div class="sub-folder-input">
    <input (keypress)="handleEnter($event)" [placeholder]="'ENTER_FOLDER_NAME' | translate" [(ngModel)]="createFolderName" />
  </div>

  <mat-dialog-actions align="end">
    <button mat-button color="accent" [mat-dialog-close]="false" mat-dialog-close>
      {{ "CANCEL" | translate }}
    </button>
    <button mat-button color="accent" (click)="createFolder()" [disabled]="createFolderName.trim() === ''" [mat-dialog-close]="true">
      {{ "CREATE" | translate }}
    </button>
  </mat-dialog-actions>
</div>
