import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-table-row-loader",
  templateUrl: "./table-row-loader.component.html",
  styleUrls: ["./table-row-loader.component.scss"],
})
export class TableRowLoaderComponent implements OnInit {
  @Input()
  isRecordEnd: boolean;
  @Input()
  isRecordsLoaded: boolean;
  @Input()
  noOfColumns = 1;
  @Input()
  noOfRows = 1;
  @Input()
  colsWidth = [];
  @Input()
  insideTable = true;
  @Input()
  lastAction = false;
  columns: Array<number>;
  rows: Array<number>;
  constructor() {}

  ngOnInit(): void {
    this.columns = Array(this.noOfColumns)
      .fill(0)
      .map((x, i) => i);
    this.rows = Array(this.noOfRows)
      .fill(0)
      .map((x, i) => i);
    if (!this.colsWidth.length) {
      this.columns.forEach((x) => {
        this.colsWidth.push(100);
      });
    }
  }
}
