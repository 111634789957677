import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, TemplateRef } from "@angular/core";
import { SettingService } from "../../../services/setting.service";
import { FolderService } from "../../../services/folder.service";
import { BotService } from "../../../services/bot.service";
import { CommonService } from "../../../services/common.service";
import { FlowBuilderService } from "../../../services/flowbuilder.service";
import { getChannelInfo } from "../../../helper/channel.helper";

@Component({
  selector: "app-common-flow-list",
  templateUrl: "./common-flow-list.component.html",
  styleUrls: ["./common-flow-list.component.scss"],
})
export class CommonFlowListComponent implements OnChanges {
  @Input() selectedChannel;
  @Input() allowCreate = false;
  @Input() folders;
  @Input() type;
  @Input() height;
  @Input() displayFolders = [];
  @Input() subFolderList = [];
  @Input() flowBuilders = [];
  @Input() currentSelectedFlow;
  @Input() isShowFlow = true;
  @Output() flowChanges = new EventEmitter();
  @Output() scrollEmitter = new EventEmitter();
  @Input() languageSelector = false;
  selectedLanguage = "en";
  selectedBot = this.botService.getBot();
  subFolderNavList = [];
  selectedSubFolder;
  deleteFolderData;
  selectedLanguageFlowLanguage = this.settingService.languages[0];
  createFolderName: string;
  moveToFolderIndex: number;
  moveTempRef: TemplateRef<any>;
  deleteFolderIndex: number;
  tempDisplaySelectedFolderIndex;
  moveToFolder: any;
  displaySubFolderNavList: any[] = [];
  searchSubFolderNavList: any[] = [];
  fromDisplay = false;
  isSearch = false;
  tempSubFolderIndex: number;
  isMainFolder = true;
  lastFolderName: string;
  isDeleteMainFolder = true;
  selectedFolder = {
    selectedSubFolder: null,
    folder: null,
    _id: null,
  };
  search: string;
  gridViewSelected = true;
  constructor(
    readonly settingService: SettingService,
    readonly folderService: FolderService,
    readonly botService: BotService,
    readonly commonService: CommonService,
    readonly flowBuilderService: FlowBuilderService
  ) {}
  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.selectedChannel && changes.selectedChannel.currentValue) {
      this.subFolderNavList = [];
      this.selectedFolder.folder = undefined;
      this.folders = [];
      this.getFlowBuilderList();
    }
  }

  get channelIcon() {
    return getChannelInfo(this.selectedChannel);
  }

  backToParentFolder(type, subFolderIndex?): void {
    if (type === "oneBack") {
      if (this.subFolderNavList.length === 1) {
        this.subFolderNavList = [];
        this.selectedFolder = {
          _id: null,
          folder: null,
          selectedSubFolder: null,
        };
        this.getFlowBuilderList();
      } else {
        this.subFolderNavList.splice(this.subFolderNavList.length - 1, 1);
        if (this.subFolderNavList[this.subFolderNavList.length - 1]) {
          this.backToParentFolder("oneBack");
        }
      }
    } else {
      this.subFolderNavList.splice(subFolderIndex + 1);
      this.selectedFolder = this.subFolderNavList[this.subFolderNavList.length - 1];
      this.flowChanges.emit({ type: "openSubFolderEvent", data: { id: this.selectedFolder._id, isMainFolder: !subFolderIndex } });
    }
  }

  getFlowBuilderList(): void {
    this.subFolderNavList = [];
    this.flowChanges.emit({ type: "languageChange", data: this.selectedLanguageFlowLanguage });
  }

  openDialog(templateRef: TemplateRef<any>, type?: string, index?: number, folder?: any, isMainFolder?: any): void {
    switch (type) {
      case "newFolder":
      case "subFolder":
        this.createFolderSubFolder(type, templateRef);
        break;
      case "moveToFolder":
        this.moveFolderSubFolder(templateRef, index, folder, isMainFolder);
        break;
      case "deleteFolder":
        this.deleteFolderSubFolder(templateRef, index);
        break;
    }
  }

  createFolderSubFolder(type: string, templateRef: TemplateRef<any>): void {
    this.createFolderName = "";
    const title = type === "newFolder" ? "CREATE_FOLDER" : "CREATE_SUB_FOLDER";
    this.folderService.openDialog(templateRef, { width: "600px" }, { title, template: templateRef, isShowHeader: true }, "create");
  }

  moveFolderSubFolder(templateRef: TemplateRef<any>, index?: number, folder?: any, isMainFolder?: any): void {
    this.flowChanges.emit({ type: "displayListEvent" });
    this.moveToFolderIndex = index;
    this.moveTempRef = templateRef;
    this.moveToFolder = folder;
    if (isMainFolder) {
      this.selectedFolder.folder = this.folders.folder[index];
    } else {
      this.selectedFolder.selectedSubFolder = this.folders.subFolderList[index];
      this.moveToFolder.parentFolder = this.selectedFolder.selectedSubFolder._id;
    }
    const obj = {
      isCheckSubFolder: true,
      isMainFolder: true,
      index,
      id: folder._id,
    };
    this.flowChanges.emit({ type: "openSubFolderEvent", data: obj });
  }

  deleteFolderSubFolder(templateRef: TemplateRef<any>, index?: number): void {
    this.deleteFolderIndex = index;
    const dataObj = {
      title: "ARE_YOU_SURE",
      template: templateRef,
      isShowHeader: false,
    };
    this.folderService.openDialog(templateRef, { width: "400px", panelClass: "delete-model-main" }, dataObj);
  }

  createFolder(event: any): void {
    if (event) {
      const object = {
        folderName: event.data.folderName ? event.data.folderName : event.folderName,
        botId: this.selectedBot,
        parentFolder: "",
      };
      if (!this.fromDisplay && !this.isSearch && !this.isMainFolder) {
        object.parentFolder = this.selectedFolder.folder._id;
      } else if (this.fromDisplay && this.displaySubFolderNavList.length) {
        object.parentFolder = this.displaySubFolderNavList[this.displaySubFolderNavList.length - 1]._id;
      } else if (this.isSearch && !this.fromDisplay && this.searchSubFolderNavList.length) {
        object.parentFolder = this.searchSubFolderNavList[this.searchSubFolderNavList.length - 1]._id;
      }
      this.flowChanges.emit({ type: "createFolderEvent", data: object });
    } else {
      this.commonService.errorHandler("ENTER_FOLDER_NAME");
    }
  }

  editFolder(type: any): void {
    const parentFolderId = this.displayFolders[this.tempDisplaySelectedFolderIndex]._id;
    const editFolderObject = {
      type,
      parentFolder: parentFolderId ? parentFolderId : "root",
      botId: this.selectedBot,
      folderId: this.moveToFolder._id,
    };
    this.flowChanges.emit({ type: "createFolderEvent", data: editFolderObject });
  }

  openDisplaySubFolder(fIndex: number): void {
    this.moveToFolderIndex = fIndex;
    this.tempDisplaySelectedFolderIndex = fIndex;
    this.displaySubFolderNavList.push(this.displayFolders[fIndex]);
  }

  displayList(type: string, id: string): void {
    this.flowChanges.emit({ type, id });
  }
  openSubFolder(fIndex: number, isMainFolder: any, parentIndex?: number): void {
    const tempFolder = {
      name: "",
      id: "",
    };
    if (isMainFolder) {
      this.folders.forEach((folder, i) => {
        folder.isOpen = i === fIndex;
      });
      this.tempSubFolderIndex = undefined;
      tempFolder.id = this.folders[fIndex]._id;
      tempFolder.name = this.folders[fIndex].folderName;
      this.selectedFolder.folder = this.folders[fIndex];
      this.selectedFolder.folder.selectedIndex = fIndex;
      this.flowChanges.emit({ type: "selectedFolderEvent", data: this.selectedFolder });
      this.subFolderList = [];
      this.subFolderNavList = [];
      this.subFolderNavList.push({ folderName: this.folders[fIndex].folderName, _id: this.folders[fIndex]._id });
    } else {
      this.tempSubFolderIndex = fIndex;
      tempFolder.id = this.subFolderList[fIndex]._id;
      tempFolder.name = this.subFolderList[fIndex].folderName;
      this.selectedFolder.selectedSubFolder = this.subFolderList[fIndex];
      this.flowChanges.emit({ type: "selectedSubFolderEvent", data: this.selectedFolder.selectedSubFolder });
      const parentFolder = this.folders.find((folder) => folder._id === this.folders[parentIndex]._id);
      this.subFolderNavList = [];
      this.subFolderNavList.push({
        _id: parentFolder._id,
        folderName: parentFolder.folderName,
      });
      this.subFolderNavList.push({ folderName: this.subFolderList[fIndex].folderName, _id: this.subFolderList[fIndex]._id });
    }
    if ((this.folders[fIndex] && this.folders[fIndex].isOpen) || !isMainFolder) {
      const obj = {
        isCheckSubFolder: false,
        isMainFolder,
        index: fIndex,
        id: tempFolder.id,
        name: tempFolder.name,
        parentFolder: !isMainFolder ? this.folders[parentIndex]?._id : null,
      };
      this.flowChanges.emit({ type: "openSubFolderEvent", data: obj });
    }
  }
  saveLastName(event: any) {
    this.lastFolderName = event.target.value;
  }
  async saveData(type: string, event: any, folderIndex: number, subFolderIndex?: number): Promise<void> {
    try {
      let id;
      const obj = {
        botId: this.selectedBot,
        folderName: event.target.value,
        parentFolderId: null,
        lastName: this.lastFolderName,
      };
      switch (type) {
        case "folder":
          this.folders[folderIndex].isRename = false;
          if (event.target.value === this.lastFolderName || event.target.value.trim() === "") {
            this.folders[folderIndex].folderName = this.lastFolderName;
            return;
          }
          id = this.folders[folderIndex]._id;
          break;
        case "subFolder":
          this.subFolderList[subFolderIndex].isRename = false;
          if (event.target.value === this.lastFolderName || event.target.value.trim() === "") {
            this.subFolderList[subFolderIndex].folderName = this.lastFolderName;
            return;
          }
          id = this.subFolderList[subFolderIndex]._id;
          obj.parentFolderId = this.folders[folderIndex]._id;
          break;
      }
      if (this.type !== "nlp") {
        await this.flowBuilderService.updateFolder(id, obj);
        this.lastFolderName = undefined;
        this.checkSaveData(event, type, folderIndex, subFolderIndex);
      } else {
        this.flowChanges.emit({ type: "folderRenameEvent", id, data: obj });
      }
    } catch (e) {
      this.commonService.APIErrorHandler(e);
      this.checkSaveData(event, type, folderIndex, subFolderIndex, true);
    }
  }
  checkSaveData(event: any, type: string, folderIndex: number, subFolderIndex: number, isError?: boolean): void {
    if (type === "folder") {
      if (isError) {
        this.folders = JSON.parse(JSON.stringify(this.folders));
        this.folders[folderIndex].folderName = this.lastFolderName;
      } else {
        this.folders[folderIndex].folderName = event.target.value;
        this.commonService.successNotification("Folder Name Updated Successfully!");
      }
    } else if (type === "subFolder") {
      if (isError) {
        this.subFolderList = JSON.parse(JSON.stringify(this.subFolderList));
        this.subFolderList[subFolderIndex].folderName = this.lastFolderName;
      } else {
        this.subFolderList[subFolderIndex].folderName = event.target.value;
        this.commonService.successNotification("Folder Name Updated Successfully!");
      }
    }
  }
  onActionClick(index: number, event: any, type: string): void {
    if (type === "parentFolder") {
      this.folders.forEach((folder) => (folder.isOpen = false));
      this.flowBuilderService.selectedFolderActionIndex = index;
    } else {
      this.flowBuilderService.selectedSubFolderIndex = index;
    }
    event.stopPropagation();
  }

  renameFolder(type: string, index: number): void {
    if (type === "parent") {
      this.folders[index].isRename = true;
      setTimeout(() => {
        document.getElementById("folder-" + index).focus();
      }, 10);
    } else {
      this.subFolderList[index].isRename = true;
      setTimeout(() => {
        document.getElementById("subFolder-" + index).focus();
      }, 10);
    }
  }

  checkDeleteFolder(folder, index, type) {
    this.isDeleteMainFolder = type === "parentFolder";
    this.deleteFolderData = folder;
    if (type === "subFolder") {
      this.deleteFolderData.parentFolder = this.selectedFolder.folder._id;
    }
    const folderDataObject = {
      deleteFolderData: this.deleteFolderData,
      index,
    };
    this.flowChanges.emit({ type: "checkFolderForDelete", data: folderDataObject });
  }

  async searchList(event: any): Promise<void> {
    const obj = {
      type: "search",
      data: {
        searchValue: event,
        selectedFolder: this.selectedFolder,
      },
    };
    this.flowChanges.emit(obj);
  }

  selectView() {
    this.gridViewSelected = !this.gridViewSelected;
  }

  emitFlowSelectEvent(_id: any, flowName: any, thumbnail: string) {
    const obj = {
      flowName,
      flowId: _id,
      thumbnail,
    };
    this.flowChanges.emit({ type: "FlowSelectEvent", data: obj });
  }

  onScrollDown() {
    this.scrollEmitter.emit();
  }
}
