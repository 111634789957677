import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Urls } from "../utils/urls";
import { IAPIDeletedSuccessfullyResponse, IPaginateResults } from "../models/common-model";
import { ScheduleObject } from "../models/schedule.model";

@Injectable()
export class RulesService {
  constructor(private http: HttpClient) {}

  getRuleList(requestParams): Promise<ScheduleObject[]> {
    return new Promise((resolve, reject) => {
      return this.http
        .get(
          `${Urls.rules}?page=${requestParams.page}&limit=${requestParams.limit}&search_param=${requestParams.search}&channel=${requestParams.channel}`
        )
        .subscribe({
          next: (trainRes: IPaginateResults<ScheduleObject>) => resolve(trainRes.docs),
          error: (err) => reject(err),
        });
    });
  }

  getRuleById(id) {
    return new Promise((resolve, reject) => {
      return this.http.get(Urls.rules + id).subscribe(
        (data) => {
          resolve(data);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  addRule(object) {
    return new Promise((resolve, reject) => {
      return this.http.post(Urls.rules, object).subscribe(
        (data) => {
          resolve(data);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  updateRule(id, object): Promise<{ message: string; status: string }> {
    return new Promise((resolve, reject) => {
      return this.http.put(Urls.rules + id, object).subscribe({
        next: (trainRes: { message: string; status: string }) => resolve(trainRes),
        error: (err) => reject(err),
      });
    });
  }

  deleteRule(id): Promise<IAPIDeletedSuccessfullyResponse> {
    return new Promise((resolve, reject) => {
      return this.http.delete(Urls.rules + id).subscribe({
        next: (trainRes: IAPIDeletedSuccessfullyResponse) => resolve(trainRes),
        error: (err) => reject(err),
      });
    });
  }
}
