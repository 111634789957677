import { Component, Input, Output, EventEmitter } from "@angular/core";
import { environment } from "../../../../../environments/environment";

@Component({
  selector: "app-add-button",
  templateUrl: "./add-button.component.html",
  styleUrls: ["./add-button.component.scss"],
})
export class AddButtonComponent {
  @Input() buttonText;
  @Output() addEvent = new EventEmitter<any>();
  environment = environment;
  addButtonEvent() {
    this.addEvent.emit();
  }
}
