import { Injectable } from "@angular/core";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { SnackBarComponent } from "../components/common/snack-bar/snack-bar.component";
import { BotService } from "./bot.service";
import { TranslationService } from "./translation.service";

/*
	Firebase events and event code  event names
*/

export const handoffEventsCode = {
  newHandoffRequest: "NEW_HANDOFF_REQUEST",
  newUserAssignedToMe: "NEW_USER_ASSIGNED_TO_ME",
  handoffRequestAccepted: "A_REQUEST_ACCEPTED",
  assignedUserAcceptedByAgent: "ASSIGNED_USER_ACCEPTED_BY_AGENT",
  userMarkedAsCompleted: "USER_MARKED_AS_COMPLETED",
  userConversationUpdate: "USER_CONVERSATION_UPDATE",
};

export const NLPEvents = {
  documentTrain: "KNOWLEDGE_BASE_DOC_TRAIN",
  updateQualityScore: "INTENT_QUALITY_SCORE",
  NLPTrain: "NLP_TRAINING_PROCESS",
  MatrixCreated: "MATRIX_CREATED",
};

const handoffNewRequest = ["newHandoffRequest", "newUserAssignedToMe"];

@Injectable()
export class NotifyService {
  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    horizontalPosition: "end",
    verticalPosition: "bottom",
    duration: 3000,
  };
  constructor(private translate: TranslationService, private snackBar: MatSnackBar, readonly botService: BotService) {}

  async sendNotification(type, notificationDetail, notificationData?) {
    this.snackBar.dismiss();
    switch (type) {
      case "error":
        this.snackBar.openFromComponent(SnackBarComponent, {
          data: {
            header: "ERROR!",
            details: notificationDetail,
            icon: "highlight_off_outline",
            color: "#F9461C",
            class: "error",
          },
          ...this.configSuccess,
        });
        break;
      case "success":
        this.snackBar.openFromComponent(SnackBarComponent, {
          data: {
            header: "SUCCESS!",
            details: notificationDetail,
            icon: "check_circle_outline",
            color: "#43D787",
            class: "success",
          },
          ...this.configSuccess,
        });
        break;
      case "warning":
        this.snackBar.openFromComponent(SnackBarComponent, {
          data: {
            header: "WARNING!",
            details: notificationDetail,
            icon: "warning_amber",
            color: "#FFA849",
            class: "warning",
          },
          ...this.configSuccess,
        });
        break;
      case "info":
        const notificationDetails = {
          header: "SUCCESS!",
          details: notificationDetail,
          icon: "info_outline",
          class: "info",
          isHandoffDetails: false,
          metaInfo: null,
          notificationData,
        };
        if (notificationData) {
          const { type: notificationType, [notificationType]: typeData } = notificationData?.data;
          if (notificationType && typeData) {
            const parsedData = JSON.parse(typeData);
            notificationData.data[notificationType] = parsedData[notificationType];
          }
          if (notificationData && handoffNewRequest.includes(notificationData.data[notificationType].type)) {
            notificationDetails.header = notificationData.data.title;
            notificationDetails.isHandoffDetails = true;
            notificationDetails.metaInfo = notificationData.data[notificationType].metaInfo;
          }
        }
        this.snackBar.openFromComponent(SnackBarComponent, {
          data: notificationDetails,
          ...this.configSuccess,
        });
        break;
    }
  }
}
