<div class="modal-body selection_modal_body">
  <div class="main-body-content">
    <ng-container *ngIf="moveFolderData.folderNavigation">
      <div class="d-flex">
        <span (click)="backToRootFolder()" class="sub-folder-name c-p white-space-no-wrap">
          {{ "FOLDER" | translate }}
        </span>
        <span class="d-flex c-p outline-none keep-all" *ngFor="let navigation of moveFolderData.folderNavigation; index as navigationIndex">
          <img src="../../../../../assets/images/right-arrow.svg" class="img-fluid ml-3 mr-3" alt="" />
          <span (click)="backToParentFolder(navigation, navigationIndex)" class="sub-folder-name white-space-no-wrap">{{
            navigation.folderName
          }}</span>
        </span>
      </div>
    </ng-container>
    <div class="move-folder-list-container">
      <div *ngIf="moveFolderData.folders.length !== 0" class="main-folders-list p-10">
        <div
          class="display-folders"
          *ngFor="let folder of moveFolderData.folders; index as fIndex"
          [matTooltip]="folder.folderName"
          (click)="onSelectFolder(folder, fIndex)"
          [ngClass]="{
            notAllowed: !this.moveFolderData.config.allowNestedFolderSelection
              ? folder._id === moveFolderData.moveData._id || folder._id === moveFolderData.moveData.parentFolderId
              : false,
            'selected-folder': moveFolderData.selectedFolder?._id === folder._id
          }"
        >
          <div class="" id="folders" [class]="folder.class">
            <div class="icon-text">
              <p>{{ folder.folderName | shortName }}</p>
            </div>
          </div>
          <span>{{ folder.folderName }}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="move-buttons d-flex justify-content-center">
    <ng-container>
      <button mat-flat-button color="primary" (click)="moveToFolder()" class="move-here" [disabled]="isButtonClicked">
        {{ moveFolderData.config.buttonTitle ? (moveFolderData.config.buttonTitle | translate) : ("MOVE_HERE" | translate) }}
      </button>
    </ng-container>
    <button
      [disabled]="moveFolderData.config.disableAddFolder"
      mat-flat-button
      color="primary"
      class="createBtn createBtnForMove"
      cdkFocusInitial
      (click)="openDialog(createFolderTemplate)"
    >
      <mat-icon aria-hidden="false" aria-label="add">add_circle_outline </mat-icon>
      {{ "FOLDER" | translate }}
    </button>
  </div>
</div>
<ng-template #createFolderTemplate>
  <modal-create-folder (createFolderEvent)="createFolder($event)"></modal-create-folder>
</ng-template>
