import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { DialogComponent } from "../components/modals/dialog/dialog.component";
import { Subject } from "rxjs";

@Injectable()
export class FolderService {
  dialogRef;
  moveFolderSubject = new Subject();
  closeDialogSubject = new Subject();
  moveDialogRef;
  createDialogRef;
  constructor(public dialog: MatDialog) {}
  openDialog(template, config, data, id?) {
    this.dialogRef = this.dialog.open(DialogComponent, {
      data,
      width: config.width,
      height: config.height ? config.height : "auto",
      panelClass: config.panelClass ? config.panelClass : "",
      id,
    });
    if (id === "move") {
      this.moveDialogRef = this.dialogRef;
    } else if (id === "create") {
      this.createDialogRef = this.dialogRef;
    }
    this.dialogRef.afterClosed().subscribe((result) => {
      this.closeDialogSubject.next("cancel");
    });
  }
  closeDialog(id?) {
    if (id === "create") {
      this.createDialogRef.close();
    } else if (id === "move") {
      this.moveDialogRef.close();
    } else {
      this.dialogRef.close();
    }
  }
}
