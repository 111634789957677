import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "app-search",
  templateUrl: "./search.component.html",
  styleUrls: ["./search.component.scss"],
})
export class SearchComponent {
  @Input() search: string;
  @Input() placeholder: string;
  @Output() searchList = new EventEmitter<any>();
  @Output() keyUp = new EventEmitter<any>();
  @Input() showSearchWrap = false;

  doSearch(event) {
    if (!(event.keyCode === 8 && this.search.trim() === "")) {
      this.searchList.emit(event);
    }
  }
  keyUpEvent(event) {
    this.keyUp.emit(event);
  }
  searchOnIcon() {
    if (this.search.trim() !== "") {
      const event = {
        keyCode: 13,
        target: {
          value: this.search,
        },
      };
      this.searchList.emit(event);
      this.keyUp.emit(event);
    }
  }
}
