<div class="action-btn d-flex align-center">
  <div class="action-btn-group">
    <button class="action-success d-flex align-center" (click)="getActionEvent('run')">
      <img src="../../../../../assets/images/icons/start.svg" class="img-fluid" height="15" alt="start campaign" />
      {{ "RUN" | translate }}
    </button>
  </div>
  <div class="action-btn-group">
    <button class="action-danger d-flex align-center" (click)="getActionEvent('pause')">
      <img src="../../../../../assets/images/icons/pause.svg" class="img-fluid" height="15" alt="start campaign" />
      {{ "PAUSE" | translate }}
    </button>
  </div>
  <div class="action-btn-group">
    <button class="action-info d-flex align-center" (click)="getActionEvent('stop')">
      <img src="../../../../../assets/images/icons/stop.svg" class="img-fluid" height="15" alt="start campaign" />
      {{ "STOP" | translate }}
    </button>
  </div>
</div>
