<div>
  <h2 mat-dialog-title>{{ "ARE_YOU_SURE" | translate }}</h2>
  <div>
    <p class="mb-0">
      {{ "YOU_WANT_TO_DELETE" | translate }}
      <span> {{ folderName }}</span>
    </p>
    <div class="list-delete">
      <ng-container *ngIf="deleteList && deleteList?.folderList.length > 1">
        <p class="mb-15 mt-15">
          <strong>{{ "FOLDER_LIST" | translate }}</strong> :
        </p>
        <p *ngFor="let flow of deleteList.folderList; index as fIndex">
          <ng-container *ngIf="fIndex !== 0"> {{ fIndex }}. {{ flow.name }} </ng-container>
        </p>
      </ng-container>
      <ng-container *ngIf="deleteList && deleteList?.recordList.length !== 0">
        <p class="mt-15">
          <strong>{{ title | translate }}</strong> :
        </p>
        <p *ngFor="let record of deleteList.recordList; index as rIndex">{{ +rIndex + 1 }}. {{ record.name }}</p>
      </ng-container>
    </div>
  </div>
  <mat-dialog-actions class="justify-content-center">
    <button (click)="closeDialog()" mat-button color="accent" [mat-dialog-close]="false" mat-dialog-close>
      {{ "CANCEL" | translate }}
    </button>
    <button (click)="deleteFolder()" mat-button color="accent" [mat-dialog-close]="true" cdkFocusInitial>
      {{ "OKAY" | translate }}
    </button>
  </mat-dialog-actions>
</div>
