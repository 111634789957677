<div class="maindiv ml-10 pt-10">
  <div class="resetPassword">
    <div class="row profile-main">
      <div class="col-12">
        <div class="my-profile-settings-header">
          <h4>{{ "PROFILE_SETTING" | translate }}</h4>
        </div>
      </div>

      <div class="edit-profile col-12">
        <h4>{{ "YOUR_PHOTO" | translate }}</h4>
        <div class="d-flex">
          <div class="profile">
            <img class="profile-img" *ngIf="profileImage; else profileContent" [src]="profileImage" alt="" />
            <div *ngIf="profileImage" class="hidden-icon c-p top-minus-1 p-3" (click)="removeProfileImage()">
              <mat-icon class="f-15 h-px-15 w-px-15">delete_outline</mat-icon>
            </div>
          </div>
          <ng-template #profileContent>
            <div class="user-shortName" [ngStyle]="{ 'background-color': _commonService.lightBotColor }" *ngIf="!profileImage">
              <p *ngIf="!_authService.getUser().profileImage">
                <span>{{ _authService.getUser().name.first | slice: 0:1 }}</span>
                <span> {{ _authService.getUser().name.last | slice: 0:1 }}</span>
              </p>
            </div>
          </ng-template>
          <div class="edit-profile-content">
            <h4 (click)="openFileUpload()" class="c-p">
              {{ "CHANGE_YOUR_PHOTO" | translate }}
            </h4>
            <input
              type="file"
              multiple="multiple"
              accept=".png,.jpge,.jpg"
              (change)="uploadProfileEvent($event)"
              #uploadProfile
              class="upload-links d-none"
            />
          </div>
        </div>
      </div>

      <form [formGroup]="profileSettingForm">
        <div class="col-sm-6 col-6 new-password">
          <label class="profile-email"
            >{{ "FIRST_NAME" | translate }}
            <p class="text-red">*</p>
            :
          </label>
          <div class="input-div input-pass">
            <input
              class="custom-text-input w-100"
              [placeholder]="'FIRST_NAME' | translate"
              autocomplete="off"
              type="text"
              formControlName="name"
            />
          </div>
        </div>
        <div class="col-sm-6 col-6 new-password">
          <label class="profile-email"
            >{{ "LAST_NAME" | translate }}
            <p class="text-red">*</p>
            :</label
          >
          <div class="input-div input-pass">
            <input
              class="custom-text-input w-100"
              autocomplete="off"
              [placeholder]="'LAST_NAME' | translate"
              type="text"
              formControlName="lastname"
            />
          </div>
        </div>
        <div class="col-sm-6 col-6 new-password">
          <label class="profile-email"
            >{{ "EMAIL" | translate }}
            <p class="text-red">*</p>
            :
          </label>
          <div class="input-div input-pass">
            <input
              class="custom-text-input w-100"
              [placeholder]="'EMAIL' | translate"
              type="email"
              formControlName="email"
              autocomplete="off"
            />
          </div>
        </div>
        <div class="col-sm-6 col-6 new-password">
          <label class="profile-email">{{ "PHONE_NUMBER" | translate }} :</label>
          <div class="input-div input-pass">
            <input
              class="custom-text-input w-100"
              [placeholder]="'PHONE_NUMBER' | translate"
              type="text"
              formControlName="phoneNumber"
              onpaste="return false;"
              (keypress)="checkMobileNumber($event)"
            />
          </div>
        </div>
        <div class="col-sm-6 col-6 new-password" [formGroup]="profileSettingForm.controls.notificationConfig">
          <label class="profile-email">{{ "NOTIFICATION" | translate }} :</label>
          <div
            class="d-flex notification-module"
            *ngFor="let module of notificationModule"
            [formGroup]="profileSettingForm.controls.notificationConfig.controls[module]"
          >
            <mat-slide-toggle
              class="mb-10"
              [formControl]="profileSettingForm.controls.notificationConfig.controls[module].controls.isEnable"
            >
              {{ NOTIFICATION_MODULE_NAME_CONST[module] | translate }}
            </mat-slide-toggle>
            <mat-slide-toggle
              class="mb-10"
              [formControl]="profileSettingForm.controls.notificationConfig.controls[module].controls.isSound"
              color="primary"
            >
              {{ "SOUND" | translate }}
            </mat-slide-toggle>
          </div>
        </div>
      </form>
      <div class="col-sm-6 col-6 new-password">
        <label class="profile-email">{{ "LANGUAGE" | translate }} :</label>
        <div class="pl-20 w-100 d-flex common-select-mat border-gray h-px-40">
          <img
            alt=""
            height="18px"
            width="18px"
            class="mr-5"
            [src]="selectedLanguage === 'en' ? 'assets/images/united-states.svg' : 'assets/images/netherlands.svg'"
          />
          <mat-select [(ngModel)]="selectedLanguage">
            <mat-option value="en">
              <img alt="" height="15px" width="15px" src="assets/images/united-states.svg" />
              English
            </mat-option>
            <mat-option value="nl">
              <img alt="" height="15px" width="15px" src="assets/images/netherlands.svg" />
              Dutch
            </mat-option>
          </mat-select>
        </div>
      </div>
    </div>
    <div class="reset-button">
      <button
        mat-flat-button
        color="primary"
        (click)="UpdateProfile()"
        class="mt-0 mb-0 ml-10 mr-10 min-w-px-80 max-h-px-35 font-white"
        [disabled]="isDisable"
      >
        {{ "SAVE" | translate }}
      </button>
    </div>
  </div>
</div>
