import { LoaderComponent } from "./components/loader/loader.component";
import { NgModule } from "@angular/core";
import { CommonConversationComponent } from "./components/common-conversation/common-conversation.component";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { CommonModule } from "@angular/common";
import { DragScrollModule } from "ngx-drag-scroll";
import { MaterialModule } from "../material.module";
import { MatNativeDateModule } from "@angular/material/core";
import { OwlDateTimeModule, OwlNativeDateTimeModule } from "ng-pick-datetime-ex";
import { ClickOutsideDirective } from "./pipe/click-outside.directive";
import { SafeHtmlPipe } from "./pipe/safe-html.pipe";
import { DialogComponent } from "./components/modals/dialog/dialog.component";
import { AddButtonComponent } from "./components/common/add-button/add-button.component";
import { SearchComponent } from "./components/common/search/search.component";
import { TableComponent } from "./components/common/table/table.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { NgxMaterialTimepickerModule } from "ngx-material-timepicker";
import { ColorPickerModule } from "ngx-color-picker";
import { FlowSelectionComponent } from "./components/flow-selection/flow-selection.component";
import { FlowListForAnotherFlowComponent } from "./components/flow-list-for-another-flow/flow-list-for-another-flow.component";
import { ShortNamePipe } from "./pipe/split-name.pipe";
import { FolderFlowColumnComponent } from "./components/common/folder-flow-column/folder-flow-column.component";
import { FolderSubfolderComponent } from "./components/folder-subfolder/folder-subfolder.component";
import { CreateFolderModalComponent } from "./components/modals/create-folder-modal/create-folder-modal.component";
import { MoveModalComponent } from "./components/modals/move-modal/move-modal.component";
import { DeleteFolderModalComponent } from "./components/modals/delete-folder-modal/delete-folder-modal.component";
import { ColorPickerComponent } from "./components/common/color-picker/color-picker.component";
import { CommonBotUserDetailsComponent } from "./components/common-bot-user-details/common-bot-user-details.component";
import { NgxPopperModule } from "ngx-popper";
import { AddFiComponent } from "./components/modals/add-fi/add-fi.component";
import { AddMappingPopperComponent } from "./components/modals/add-mapping-popper/add-mapping-popper.component";
import { StepWarningPopperComponent } from "./components/modals/step-warning-popper/step-warning-popper.component";
import { FolderViewComponent } from "./components/folder-view/folder-view.component";
import { MoveModelV2Component } from "./components/modals/move-model-v2/move-model-v2.component";
import { CommonAnalyticsCardsComponent } from "./components/common-analytics-cards/common-analytics-cards.component";
import { BadgeComponent } from "./components/common/badge/badge.component";
import { ActionButtonComponent } from "./components/common/action-button/action-button.component";
import { MatSelectModule } from "@angular/material/select";
import { MatListModule } from "@angular/material/list";
import { NoModuleComponent } from "./components/no-module/no-module.component";
import { TableRowLoaderComponent } from "./components/common/table-row-loader/table-row-loader.component";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { TranslationService } from "./services/translation.service";
import { HtmlToTextPipe } from "./pipe/html-to-text.pipe";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { NgxDaterangepickerMd } from "ngx-daterangepicker-material";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatExpansionModule } from "@angular/material/expansion";
import { ProfileComponent } from "../dashboard/profile/profile.component";
import { ResetPasswordComponent } from "../dashboard/reset-password/reset-password.component";
import { ChartsModule } from "ng2-charts";
import { CKEditorModule } from "ckeditor4-angular";
import { CommonFlowListComponent } from "./components/common/common-flow-list/common-flow-list.component";
import { OrdinalPipe } from "./pipe/ordinal.pipe";

const declarations: any = [
  FlowListForAnotherFlowComponent,
  LoaderComponent,
  CommonConversationComponent,
  ClickOutsideDirective,
  SafeHtmlPipe,
  DialogComponent,
  AddButtonComponent,
  SearchComponent,
  TableComponent,
  ShortNamePipe,
  FlowSelectionComponent,
  FolderFlowColumnComponent,
  FolderSubfolderComponent,
  CreateFolderModalComponent,
  MoveModalComponent,
  DeleteFolderModalComponent,
  ColorPickerComponent,
  CommonBotUserDetailsComponent,
  ColorPickerComponent,
  AddFiComponent,
  AddMappingPopperComponent,
  StepWarningPopperComponent,
  FolderViewComponent,
  MoveModelV2Component,
  CommonAnalyticsCardsComponent,
  BadgeComponent,
  ActionButtonComponent,
  NoModuleComponent,
  TableRowLoaderComponent,
  HtmlToTextPipe,
  ProfileComponent,
  ResetPasswordComponent,
  CommonFlowListComponent,
  OrdinalPipe,
];
const imports: any = [
  ScrollingModule,
  CommonModule,
  DragScrollModule,
  MaterialModule,
  MatNativeDateModule,
  OwlDateTimeModule,
  OwlNativeDateTimeModule,
  CommonModule,
  FormsModule,
  RouterModule,
  DragScrollModule,
  ReactiveFormsModule,
  NgxMaterialTimepickerModule,
  ColorPickerModule,
  TranslateModule,
  NgxPopperModule.forRoot({}),
  MatSelectModule,
  MatListModule,
  InfiniteScrollModule,
  NgxDaterangepickerMd.forRoot(),
  MatAutocompleteModule,
  MatExpansionModule,
  ChartsModule,
  CKEditorModule,
];
const exports = declarations.concat(imports);
@NgModule({
  declarations,
  imports,
  exports: [exports, LoaderComponent],
  entryComponents: [FlowSelectionComponent],
  providers: [TranslationService],
})
export class SharedModule {
  constructor(private translate: TranslateService) {
    translate.addLangs(["en", "nl"]);
  }
}
