export enum SetTypeEnum {
  DATE = "date",
  DATE_AND_TIME = "dateAndTime",
  TAG = "tag",
  STRING = "string",
  CUSTOM = "custom",
}

export enum MessageTypeEnum {
  INTERACTIVE = "interactive",
  CONTACT = "contacts",
  CONTACTINFO = "contactInfo",
}
