<mat-icon
  class="error-warning operation"
  [popper]="tooltipContent"
  [popperTrigger]="'hover'"
  [popperPlacement]="'right'"
  [popperPreventOverflow]="false"
  [popperPositionFixed]="true"
  [popperApplyClass]="'warning-popper'"
  [popperApplyArrowClass]="'warning-arrow'"
  [popperHideOnMouseLeave]="false"
  aria-hidden="false"
  >error_outline
</mat-icon>
<popper-content #tooltipContent>
  <div class="error-warning-text-div">
    <p *ngFor="let err of errorList">
      {{ err }}
    </p>
  </div>
</popper-content>
