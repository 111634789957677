import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LoginComponent } from "./login/login.component";
import { AuthGuard } from "./core/guards/auth.guard";
import { UserTranscriptComponent } from "./dashboard/users/user-transcript/user-transcript.component";

export const routes: Routes = [
  {
    path: "dashboard",
    loadChildren: () => import("./dashboard/dashboard.module").then((m) => m.DashboardModule),
    canActivate: [AuthGuard],
  },
  {
    path: "preview/:id",
    loadChildren: () => import("./preview/preview.module").then((m) => m.PreviewModule),
  },
  {
    path: "link/:botName/:uuid",
    loadChildren: () => import("./preview/preview.module").then((m) => m.PreviewModule),
  },
  {
    path: "",
    redirectTo: "login",
    pathMatch: "full",
  },
  {
    path: "login",
    component: LoginComponent,
  },
  {
    path: "forgot",
    component: LoginComponent,
  },
  {
    path: "reset/:id",
    component: LoginComponent,
  },
  {
    path: "user-transcript/:id",
    component: UserTranscriptComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "**",
    redirectTo: "login",
    pathMatch: "full",
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
