import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { Router } from "@angular/router";
import { TranslationService } from "../../../services/translation.service";
import { ITable } from "../../../models/table";
import { environment } from "../../../../../environments/environment";
import { BULK_ACTION } from "../../../enums/common.enum";

interface IGhostLoaderConfig {
  noOfRows: number;
  noOfColumns?: number;
}

@Component({
  selector: "app-table",
  templateUrl: "./table.component.html",
  styleUrls: ["./table.component.scss"],
})
export class TableComponent implements OnInit, OnChanges {
  @Input() data;
  @Input() table: ITable;
  @Input() isNotFireEditWithNavigation;
  @Output() onAction = new EventEmitter<any>();
  @Output() onNavigation = new EventEmitter<any>();
  @Input() allComplete = false;
  @Input() isBulkAction = false;
  @Input() total = 0;
  @Input() ghostLoaderConfig!: IGhostLoaderConfig;
  @Input() isRecordsLoaded = true;
  @Input() lastRecordFetched = false;
  @Input() initialGhostLoader = false;
  @Output() allCompleteEvent = new EventEmitter<any>();
  environment = environment;
  isAllChecked = false;
  isNoneChecked = true;
  isTrainButtonDisabled = false;
  @Input() sortingType;
  @Input() sortField;
  transitionLanguage;
  @Input() selectedIndex;
  ischecked = false;
  checkedCount = 0;
  colsWidth: Array<number>;
  constructor(public _router: Router, public _translationService: TranslationService) {}

  ngOnInit() {
    if (this.table) {
      this.table.columns.forEach((row) => (row.isCheckValue = false));
      this.colsWidth = this.table?.columns?.map((col) => col.width);
    }
    setTimeout(() => {
      this.data.forEach((row) => (row.isRename = false));
    }, 500);
    this.transitionLanguage = this._translationService.getTranslationLanguage();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.data && changes.data.currentValue && changes.data.currentValue.length < 1) {
      this.isAllChecked = false;
    }
    if (changes.data && changes.data.currentValue && changes.data.currentValue.length > 0) {
      this.checkedCount = 0;
      this.ischecked = false;
      let someCheck = false;
      changes.data.currentValue.forEach((val) => {
        if (!val.isChecked) {
          this.isAllChecked = false;
          this.isNoneChecked = true;
          someCheck = true;
        } else {
          this.ischecked = this.isBulkAction;
          this.checkedCount++;
        }
      });
      if (someCheck) {
        this.allCompleteEvent.emit(false);
      }
    }
  }

  performAction(type: string, rule, index: number) {
    if (type === "rename") {
      this.renameData(index);
    } else {
      rule.index = index;
      this.onAction.emit({ type, index, data: rule });
    }
  }

  selectAll(value) {
    this.isTrainButtonDisabled = value;
    this.isAllChecked = value;
    this.isNoneChecked = !value;
    this.ischecked = this.isBulkAction ? value : false;
    this.allComplete = value;
    const dataId = [];
    this.data.forEach((data) => {
      data.isChecked = value;
      if (data.isNotCheckBox) {
        data.isChecked = false;
      }
      if (data.isChecked) {
        dataId.push(data._id);
      }
    });
    this.checkedCount = this.data.length;
    this.onAction.emit({ type: "train", trainType: "multiple", dataId });
    this.allCompleteEvent.emit(value);
    this.updateAllComplete();
  }

  selectSingle(value, index?) {
    this.data[index].isChecked = value;
    let flag = false;
    this.ischecked = false;
    const dataId = [];
    if (!value) {
      this.checkedCount--;
      this.isAllChecked = false;
      this.data.forEach((t) => {
        if (t.isChecked) {
          dataId.push(t._id);
          flag = true;
        }
      });
      if (!flag) {
        this.isNoneChecked = true;
      } else if (this.isBulkAction) {
        this.ischecked = true;
      }
    } else {
      this.checkedCount++;
      this.isNoneChecked = false;
      this.data.forEach((t) => {
        if (!t.isChecked) {
          flag = true;
        } else {
          dataId.push(t._id);
          if (this.isBulkAction) {
            this.ischecked = true;
          }
        }
      });
      if (!flag) {
        this.isAllChecked = true;
      }
    }
    this.onAction.emit({ type: "train", trainType: "multiple", dataId });
    this.updateAllComplete();
  }

  openNavigation(id, index, rule) {
    if (this.isNotFireEditWithNavigation) {
      this.onAction.emit({ type: "select", id, index, rule });
    } else {
      if (this.table.navigation && this.table.navigation.length > 0) {
        this.table.navigation.push(id);
        this._router
          .navigate(this.table.navigation, {
            queryParams: this.table.queryParams,
          })
          .catch();
      }
    }
  }

  stopRoutine(event: any) {
    event.stopPropagation();
  }

  renameData(index) {
    this.data[index].isRename = true;
    setTimeout(() => {
      document.getElementById("name-" + index).focus();
    }, 10);
  }

  saveData(selectedData, index) {
    this.data[index].isRename = false;
    if (selectedData.lastName === selectedData.flowName || selectedData.flowName.trim() === "") {
      this.data[index].flowName = selectedData.lastName;
      return;
    }
    selectedData.index = index;
    this.onAction.emit({ type: "rename", data: selectedData });
  }

  trainData(dataId, dataIndex) {
    this.onAction.emit({
      type: "train",
      trainType: "single",
      dataId,
      trainingIndex: dataIndex,
    });
    this.updateAllComplete();
  }

  changeValue(id, event) {
    this.onAction.emit({
      type: "changeText",
      id,
      data: event.target.value,
    });
  }

  openTranscript(row: any) {
    this._router.navigate(["/dashboard/users/user-detail/" + row.botUserId]);
  }

  saveLastName(index, value) {
    this.data[index].lastName = this.data[index][value];
  }

  sortList(index, value, isSorting) {
    if (isSorting) {
      if (this.sortField === value) {
        if (this.sortingType === 1) {
          this.sortingType = -1;
        } else {
          this.sortingType = 1;
        }
      }
      this.sortField = value;
      this.onAction.emit({
        type: "sorting",
        sortingType: this.sortingType,
        sortField: value,
      });
      this.table.columns.forEach((rowList, ind) => {
        if (index === ind) {
          rowList.isCheckValue = !rowList.isCheckValue;
        } else {
          rowList.isCheckValue = false;
        }
      });
    }
  }

  // check for all checked or not
  updateAllComplete(): void {
    this.allComplete = this.data != null && this.data.every((t) => t.isChecked);
    this.checkedCount = this.data != null && this.allComplete ? this.data.length : 0;
  }

  // check some checked or not
  someComplete(): boolean {
    if (this.data == null) {
      return false;
    }
    const length = this.data.filter((t) => t.isChecked).length;
    this.checkedCount = length;
    const value = length > 0 && !this.allComplete;
    this.allCompleteEvent.emit(!value);
    return value;
  }

  // check all
  setAll(completed: boolean): boolean {
    this.allComplete = completed;
    if (this.data == null) {
      return false;
    }
    this.data.forEach((t) => (t.isChecked = completed));
    if (!this.allComplete) {
      this.ischecked = false;
    }
    this.checkedCount = completed ? this.data.length : 0;
    this.allCompleteEvent.emit(completed);
    this.selectAll(this.allComplete);
  }

  // delete bulk data
  deleteBulk(): void {
    let selectData: {
      dataId: [];
      objects: [];
    } = this.getCheckedData();
    this.onAction.emit({ type: BULK_ACTION.delete, dataId: selectData.dataId, Objects: selectData.objects });
  }

  // move bulk data
  moveBulk(): void {
    let selectData: {
      dataId: [];
      objects: [];
    } = this.getCheckedData();
    this.onAction.emit({ type: BULK_ACTION.move, dataId: selectData.dataId, Objects: selectData.objects });
  }

  // get checke data from list
  getCheckedData(): any {
    const dataId = [];
    let selectData = {
      dataId: [],
      objects: [],
    };
    this.data.forEach((data) => {
      if (data.isChecked) {
        selectData.dataId.push(data._id);
        selectData.objects.push(data);
      }
    });
    return selectData;
  }
}
