import { environment } from "../../../environments/environment";

export class Urls {
  static baseUrl = environment.apiUrl + "/";
  static login = Urls.baseUrl + "users/login";
  static logout = Urls.baseUrl + "auth/users/logout";
  static usersProfile = Urls.baseUrl + "auth/users/";
  static usersAgentsList = Urls.baseUrl + "auth/users/agentList/";
  static teamList = Urls.baseUrl + "department/";
  static changePassword = Urls.baseUrl + "auth/users/changePassword";
  static forgotPassword = Urls.baseUrl + "users/forgotPassword";
  static reserPassword = Urls.baseUrl + "users/changeForgotPassword";
  static getresetUrl = Urls.baseUrl + "users/verifyResetToken/";
  static searchContent = Urls.baseUrl + "search";
  static flexible_indicator = Urls.baseUrl + "fi/";
  static flexible_indicator_delete = Urls.flexible_indicator + "remove/";
  static flow_builder = Urls.baseUrl + "flowbuilder/";
  static floW_messages = Urls.baseUrl + "flowbuilder/messages";
  static flow_dialog = Urls.flow_builder + "flowDialogs";
  static intent = Urls.flow_builder + "intents";

  static flow_builder_step = Urls.flow_builder + "step/";
  static org = Urls.baseUrl + "org/";
  static botList = Urls.baseUrl + "bots/";
  static rules = Urls.baseUrl + "rules/";
  static get_apis = Urls.baseUrl + "apitool/";
  static get_api_tool = Urls.baseUrl + "apitool/tool-req";
  static soap = Urls.baseUrl + "apitool/soap_client/";
  static soap_result = Urls.get_apis + "soap_result";
  static soap_save = Urls.get_apis + "soap_save";
  static users = Urls.baseUrl + "user-profiling";
  static gdpr = Urls.baseUrl + "GDPR/";
  static botSettings = Urls.baseUrl + "botsetting";
  static whatsappProfile = Urls.baseUrl + "botsetting/whatsappProfileSettings/";
  static intent_save = Urls.baseUrl + "df-intents";
  static utterance = Urls.baseUrl + "utterance";
  static form = Urls.baseUrl + "form/";
  static languages = Urls.baseUrl + "df-intents/supported-lang/";
  static entity = Urls.baseUrl + "df-entity/";
  static folder_create = Urls.baseUrl + "intent-folder";
  static folders = Urls.baseUrl + "intent-folder/";
  static handoff = Urls.baseUrl + "handOff/v2/";
  static bdk_common = Urls.baseUrl + "bdk/";
  static analytics = Urls.baseUrl + "analytics/";
  static flowFolder = Urls.baseUrl + "flowFolder/";
  static untrained_intents = Urls.baseUrl + "df-training/";
  static bot = "bots/";
  static knowledge_base = Urls.baseUrl + "knowledge-base/";
  static growthTool = Urls.baseUrl + "growth-tool/";
  static agentList = Urls.baseUrl + "nlp/";
  static getNlpFolder = Urls.baseUrl + "nlp/intentFolder/";
  static NlpIntent = Urls.baseUrl + "nlp/intent/";
  static NlpIntentMoveFolder = Urls.baseUrl + "nlp/intent/moveIntent";
  static tags = Urls.baseUrl + "tag/";
  static whatsapp_widget = Urls.baseUrl + "whatsapp-widget/";
  static audit_log = Urls.baseUrl + "audit-log/";
  static voximplant = Urls.baseUrl + "voximplant/";
  static templateMessage = Urls.baseUrl + "templateMessage/";
  static GDPRConsentScreen = Urls.baseUrl + "GDPR/consentScreen/";
  static handoffV2 = Urls.baseUrl + "handOff/v2/";
  static handoff_request_handle = Urls.handoffV2 + "requestHandle/";
  static notes = Urls.baseUrl + "notes/";
  static createConversation = Urls.baseUrl + "directline/conversations";
  static fallBack = `${Urls.baseUrl}fallback`;

  // campaign Urls | START
  static campaign = `${Urls.baseUrl}campaign`;
  // campaign Urls | END

  // IBM DISCOVERY URLS | START
  static ibmDocument = `${Urls.baseUrl}ibmDiscoveryDocument`;
  static ibmDocumentAnalytic = `${Urls.ibmDocument}/analytic`;
  // IBM DISCOVERY URLS | END

  // TEMPLATE MESSAGE URLS | STARTS
  static template = `${Urls.baseUrl}template`;
  // TEMPLATE MESSAGE URLS | ENDS

  // NLP V2 ROUTES | START
  static nlpConfig = `${Urls.baseUrl}nlp/config/`;
  static nlpEntity = `${Urls.baseUrl}nlp/entity`;
  static nlpIntentList = `${Urls.baseUrl}nlp/intent/list/`;
  static nlpKnowledgeBase = `${Urls.baseUrl}nlp/knowledgeBase`;
  static nlpFallbackTraining = `${Urls.baseUrl}nlp/fallbackTraining`;
  static nlpTrainingSet = `${Urls.baseUrl}nlp/trainingSet`;
  static llmKnowledge = `${Urls.baseUrl}llm/knowledge-base`;
  static llmFallback = `${Urls.baseUrl}llm/llm-fallback-flow`;
  static llmConfig = `${Urls.baseUrl}llm/llm-setting`;

  // NLP V2 ROUTES | END

  // PREVIEW LINK
  static previewLink = `${Urls.baseUrl}preview-link`;

  // universal channel
  static universalChannel = `${Urls.baseUrl}universal`;

  // instagram configs
  static instagramSettings = `${Urls.botSettings}/instagramSettings`;

  // default channel delay
  static defaultDelay = `${Urls.botSettings}/defaultDelay/`;
}
