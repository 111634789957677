import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { UsersService } from "../../../core/services/users.service";
import { NotifyService } from "../../../core/services/noyify.service";
import { takeUntil } from "rxjs/operators";
import { BotService } from "../../../core/services/bot.service";
import { Subject } from "rxjs";
import { TranslationService } from "../../../core/services/translation.service";
import { CommonService } from "../../../core/services/common.service";
import { environment } from "src/environments/environment";
import { BotSubscriberModel } from "../../../core/models/common.model";

declare var $: any;

@Component({
  selector: "app-user-transcript",
  templateUrl: "./user-transcript.component.html",
  styleUrls: ["./user-transcript.component.scss"],
})
export class UserTranscriptComponent implements OnInit, AfterViewInit {
  @Input() selectedUser;
  @Output() updateVariables = new EventEmitter();
  environment = environment;
  isDataLoading = true;
  botIcon;
  conversationTopLabel: any;
  conversationDateLabels;
  private requestParams = {
    page: 1,
  };
  readonly unsubscribe: Subject<void> = new Subject();
  isTranscriptUrl = false;
  constructor(
    readonly route: ActivatedRoute,
    readonly router: Router,
    readonly _userService: UsersService,
    readonly _notificationService: NotifyService,
    readonly _botService: BotService,
    readonly _translationService: TranslationService,
    readonly _commonService: CommonService
  ) {}

  ngOnInit() {
    this._botService
      .getUpdate()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res: BotSubscriberModel) => {
        if (res.message && res.module.userProfiling) {
          this.isTranscriptUrl = false;
          this.router.navigate(["/", "dashboard", "users"]).catch();
        }
      });
    this._translationService.setLanguageToTranslateService(this._translationService.getTranslationLanguage());
    if (this.route.snapshot.params.id) {
      this.selectedUser = {};
      this.isTranscriptUrl = true;
      this.selectedUser._id = this.route.snapshot.params.id;
    }
  }

  onUserChatMainScrollUp() {
    if (this.requestParams.page) {
      this.getUserTranscript(false);
    }
  }

  ngAfterViewInit(): void {
    this.conversationTopLabel = document.getElementById("date0");
  }

  /**
   * GetUserTranscript used to get selected user transcript
   * @param isScrollToBottom : user for scroll down at first time loading
   */
  getUserTranscript(isScrollToBottom: boolean) {
    if (this.requestParams.page > 0) {
      this._userService
        .getShowUserTranscriptDetailsById(this.selectedUser._id, this.requestParams.page)
        .then((res: any) => {
          this.requestParams.page--;
          this.selectedUser.transcript = res.docs.concat(this.selectedUser.transcript);
          if (res.docs.length < 50 && this.requestParams.page > 0) {
            /**
             * Here we have checked if last page have less then 50 record then
             * we'll get next page transcript
             */
            this.getUserTranscript(isScrollToBottom);
            return;
          }
          if (isScrollToBottom) {
            setTimeout(() => {
              this.scrollBottom();
            }, 100);
          }
        })
        .catch((err) => {
          this._commonService.APIErrorHandler(err);
        });
    }
  }

  setSmoothScroll(value) {
    $(".user-chat-main").css("scroll-behavior", value);
  }

  scrollBottom() {
    const container = document.getElementById("chat-area");
    if (container) {
      if (container.scrollHeight > container.offsetHeight) {
        this.setSmoothScroll("unset");
        container.scrollTop = container.scrollHeight;
        setTimeout(() => {
          this.setSmoothScroll("smooth");
        }, 100);
      } else {
        this.setSmoothScroll("smooth");
      }
    }
  }

  updateVariableStatus(event) {
    switch (event.type) {
      case "getUserTranscript": {
        this.requestParams.page = event.totalPage;
        this.getUserTranscript(true);
        break;
      }
      case "loader": {
        this.isDataLoading = event.status;
        break;
      }
    }
  }

  showUserList() {
    if (this.route.snapshot.params.id) {
      this.router.navigate(["/", "dashboard", "users"]).catch();
    } else {
      this.updateVariables.emit({
        type: "showUserDetails",
      });
    }
  }

  changeLabel(event: any) {
    this.conversationTopLabel = event;
    this.conversationDateLabels = document.querySelectorAll(".conversation-date");
  }

  onUserChatMainScrollDown() {
    const userConversationArea = document.getElementById("chat-area");
    let currentLabel = null;
    if (this.conversationDateLabels && this.conversationDateLabels.length) {
      this.conversationDateLabels.forEach((dateLabel) => {
        if (userConversationArea.scrollTop >= dateLabel.offsetTop) {
          currentLabel = dateLabel;
        }
        if (this.conversationTopLabel) {
          if (currentLabel) {
            this.conversationTopLabel.style.opacity = "1";
          } else {
            this.conversationTopLabel.style.opacity = "0";
          }
        }
      });
    }
  }
}
