import { mediaTypeEnum } from "../../bot-builder/enums/media.enum";
import { ChannelsEnum } from "../enums/common.enum";

export const NULLISH_STRING_VALUE = ["", null, undefined];
export const EMAIL_REGEX = /[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/;
export const PHONE_NUMBER_REGEX = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
export const WEB_URL_REGX = RegExp(
  "^(http:\\/\\/www\\.|https:\\/\\/www\\.|http:\\/\\/|https:\\/\\/)?[a-z0-9]+([\\-.][a-z0-9]+)*\\.[a-z]{2,5}(:[0-9]{1,5})?(\\/.*)?$"
);
export const nulldataIntent = [null, undefined, "", "null", "undefined"];
export const LLM_URL = /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})(\.[a-zA-Z0-9]{2,})?/;

export const CHANNELS_CONST = {
  WEBCHAT: "webchat",
  FACEBOOK: "facebook",
  WHATSAPP: "whatsapp",
  TWILIO: "twilio",
  TWILIO_SMS: "twilioSms",
  HANGOUTS_CHAT: "hangoutsChat",
  GOOGLE: "google",
  VOXIMPLANT: "voximplant",
  SLACK: "slack",
  MSTEAMS: "msteams",
  MSTEAMSAZURE: "msteamsAzure",
  AUDIOCODES: "audioCodes",
  DIALOG360: "dialog360",
  UNIVERSAL: "universal",
  INSTAGRAM: "instagram",
  TELEGRAM: "telegram",
} as const;

export const PREVIEW_CHANEL_CONST = [
  "webchat",
  "facebook",
  "whatsapp",
  "twilio",
  "twilioSms",
  "dialog360",
  "universal",
  "instagram",
  "telegram",
] as const;
export const apiToolMappingType = "jsonMapping";

export const KEYBOARD_KEY_CONST = {
  SPACE: "Space",
};

/*supported media type for channel */
export const CHANNEL_MEDIA_SUPPORT = {
  [ChannelsEnum.WEBCHAT]: [
    mediaTypeEnum.IMAGE,
    mediaTypeEnum.AUDIO,
    mediaTypeEnum.VIDEO,
    mediaTypeEnum.PDF,
    mediaTypeEnum.DOC,
    mediaTypeEnum.PPT,
    mediaTypeEnum.XLS,
  ],
  [ChannelsEnum.UNIVERSAL]: [mediaTypeEnum.IMAGE],
  [ChannelsEnum.FACEBOOK]: [mediaTypeEnum.IMAGE, mediaTypeEnum.AUDIO, mediaTypeEnum.VIDEO],
  [ChannelsEnum.WHATSAPP]: [
    mediaTypeEnum.IMAGE,
    mediaTypeEnum.AUDIO,
    mediaTypeEnum.VIDEO,
    mediaTypeEnum.PDF,
    mediaTypeEnum.DOC,
    mediaTypeEnum.PPT,
    mediaTypeEnum.XLS,
  ],
  [ChannelsEnum.TWILIO]: [mediaTypeEnum.IMAGE, mediaTypeEnum.AUDIO, mediaTypeEnum.VIDEO, mediaTypeEnum.PDF],
  [ChannelsEnum.TWILIOSMS]: [mediaTypeEnum.IMAGE, mediaTypeEnum.AUDIO, mediaTypeEnum.VIDEO],
  [ChannelsEnum.GOOGLE]: [mediaTypeEnum.IMAGE, mediaTypeEnum.AUDIO],
  [ChannelsEnum.DIALOG360]: [
    mediaTypeEnum.IMAGE,
    mediaTypeEnum.AUDIO,
    mediaTypeEnum.VIDEO,
    mediaTypeEnum.PDF,
    mediaTypeEnum.DOC,
    mediaTypeEnum.PPT,
    mediaTypeEnum.XLS,
  ],
  [ChannelsEnum.MSTEAM]: [mediaTypeEnum.IMAGE],
  [ChannelsEnum.AZURE]: [mediaTypeEnum.IMAGE],
  [ChannelsEnum.VOXIMPLANT]: [mediaTypeEnum.AUDIO],
  [ChannelsEnum.AUDIOCODES]: [mediaTypeEnum.AUDIO],
  [ChannelsEnum.SLACK]: [mediaTypeEnum.IMAGE],
  [ChannelsEnum.HANGOUTCHAT]: [mediaTypeEnum.IMAGE, mediaTypeEnum.AUDIO, mediaTypeEnum.VIDEO],
  [ChannelsEnum.INSTAGRAM]: [mediaTypeEnum.IMAGE],
  [ChannelsEnum.TELEGRAM]: [
    mediaTypeEnum.IMAGE,
    mediaTypeEnum.AUDIO,
    mediaTypeEnum.VIDEO,
    mediaTypeEnum.PDF,
    mediaTypeEnum.DOC,
    mediaTypeEnum.PPT,
    mediaTypeEnum.XLS,
  ],
};

/*supported media mime type type for channel */
export const CHANNEL_MEDIA_VALIDATION = {
  [ChannelsEnum.FACEBOOK]: {
    [mediaTypeEnum.IMAGE]: ["jpg", "png", "jpeg"],
    // ogg must be audio/ogg; codecs=opus. The base audio/ogg type is not supported.
    [mediaTypeEnum.AUDIO]: ["aac", "mp4", "amr", "mpeg", "ogg"],
    [mediaTypeEnum.VIDEO]: ["mp4", "3gpp"],
    [mediaTypeEnum.PDF]: ["pdf"],
    [mediaTypeEnum.DOC]: ["doc", "docx"],
    [mediaTypeEnum.PPT]: ["ppt", "pptx"],
    [mediaTypeEnum.XLS]: ["xls", "xlsx"],
  },
  [ChannelsEnum.WHATSAPP]: {
    [mediaTypeEnum.IMAGE]: ["jpg", "png", "jpeg"],
    [mediaTypeEnum.AUDIO]: ["mp3"],
    [mediaTypeEnum.VIDEO]: ["mp4", "3gpp"],
    [mediaTypeEnum.PDF]: ["pdf"],
    [mediaTypeEnum.DOC]: ["doc", "docx"],
    [mediaTypeEnum.PPT]: ["ppt", "pptx"],
    [mediaTypeEnum.XLS]: ["xls", "xlsx"],
  },
  [ChannelsEnum.TWILIO]: {
    [mediaTypeEnum.IMAGE]: ["jpg", "png", "jpeg"],
    [mediaTypeEnum.AUDIO]: ["mp3", "ogg", "amr"],
    [mediaTypeEnum.VIDEO]: ["mp4"],
    [mediaTypeEnum.PDF]: ["pdf"],
  },
  [ChannelsEnum.GOOGLE]: {
    [mediaTypeEnum.IMAGE]: ["jpg", "png", "gif"],
    [mediaTypeEnum.AUDIO]: ["mp3"],
  },
  [ChannelsEnum.DIALOG360]: {
    [mediaTypeEnum.IMAGE]: ["jpg", "png", "jpeg"],
    [mediaTypeEnum.AUDIO]: ["aac", "mp4", "amr", "mpeg", "ogg"],
    [mediaTypeEnum.VIDEO]: ["mp4", "3gpp"],
    [mediaTypeEnum.PDF]: ["pdf"],
  },
  [ChannelsEnum.VOXIMPLANT]: {
    [mediaTypeEnum.AUDIO]: ["mp3"],
  },
  [ChannelsEnum.AUDIOCODES]: {
    [mediaTypeEnum.AUDIO]: ["wav"],
  },
  [ChannelsEnum.SLACK]: {
    [mediaTypeEnum.IMAGE]: ["jpg", "gif", "png", "jpeg"],
  },
  [ChannelsEnum.HANGOUTCHAT]: {
    [mediaTypeEnum.IMAGE]: ["jpg", "gif", "png", "jpeg", "tif"],
    [mediaTypeEnum.AUDIO]: ["mp3", "wpl", "mpeg"],
    [mediaTypeEnum.VIDEO]: ["mp4", "3gp", "flv", "ogv", "webem"],
  },
  [ChannelsEnum.INSTAGRAM]: {
    [mediaTypeEnum.IMAGE]: ["jpg", "png", "ico", "bmp"],
  },
  [ChannelsEnum.AZURE]: {
    [mediaTypeEnum.IMAGE]: ["jpg", "png"],
  },
  [ChannelsEnum.TELEGRAM]: {
    [mediaTypeEnum.VIDEO]: ["mp4"],
    [mediaTypeEnum.AUDIO]: ["mp3", "m4a", "ogg"],
  },
  [ChannelsEnum.MSTEAM]: {
    [mediaTypeEnum.IMAGE]: ["jpg", "png", "jpeg", "gif"],
  },
};

export const FONT_FAMILY = [
  "Arial, sans-serif",
  "Courier, monospace",
  "Cambria, serif",
  "Poppins, sans-serif",
  "Times New Roman, serif",
  "Verdana, sans-serif",
];

export const FONT_SIZE = [10, 12, 14, 18, 20, 22];
export const INSTAGRAM_LOCALE_LIST: { label: string; value: string; instagramValue: string; flag: string }[] = [
  { label: "Traditional Chinese (Hong Kong)", value: "zh-HK", instagramValue: "zh_HK", flag: "" },
  { label: "Simplified Chinese (China)", value: "zh-CN", instagramValue: "zh_CN", flag: "" },
  { label: "Traditional Chinese (Taiwan)", value: "zh-TW", instagramValue: "zh_TW", flag: "" },
  { label: "Danish", value: "da", instagramValue: "da_DK", flag: "" },
  { label: "Dutch", value: "nl", instagramValue: "nl_NL", flag: "" },
  { label: "Dutch (België)", value: "nl", instagramValue: "nl_BE", flag: "" },
  { label: "Guaraní", value: "gn-PY", instagramValue: "gn_PY", flag: "" },
  { label: "Azeri", value: "az-AZ", instagramValue: "az_AZ", flag: "" },
  { label: "English", value: "en", instagramValue: "en_US", flag: "" },
  { label: "English (Upside Down)", value: "en", instagramValue: "en_UD", flag: "" },
  { label: "Catalan", value: "es-ES", instagramValue: "ca_ES", flag: "" },
  { label: "Czech", value: "cs-CZ", instagramValue: "cs_CZ", flag: "none" },
  { label: "Bisaya", value: "cx-PH", instagramValue: "cx_PH", flag: "none" },
  { label: "Welsh", value: "cy-GB", instagramValue: "cy_GB", flag: "none" },
  { label: "Afrikaans", value: "af-ZA", instagramValue: "af_ZA", flag: "none" },
  { label: "Albanian", value: "sq-AL", instagramValue: "sq_AL", flag: "none" },
  { label: "German", value: "de", instagramValue: "de_DE", flag: "" },
  { label: "Basque", value: "eu-ES", instagramValue: "eu_ES", flag: "none" },
  { label: "Galician", value: "gl-ES", instagramValue: "gl_ES", flag: "none" },
  { label: "Spanish", value: "es", instagramValue: "es_LA", flag: "" },
  { label: "Spanish (Chile)", value: "es", instagramValue: "es_CL", flag: "" },
  { label: "Spanish (Colombia)", value: "es", instagramValue: "es_CO", flag: "" },
  { label: "Spanish (Spain)", value: "es-ES", instagramValue: "es_ES", flag: "" },
  { label: "Spanish (Mexico)", value: "es", instagramValue: "es_MX", flag: "" },
  { label: "Spanish (Venezuela)", value: "es", instagramValue: "es_VE", flag: "" },
  { label: "French (France)", value: "fr-FR", instagramValue: "fr_FR", flag: "" },
  { label: "Breton (France)", value: "fr-FR", instagramValue: "br_FR", flag: "" },
  { label: "Corsican (France)", value: "fr-FR", instagramValue: "co_FR", flag: "" },
  { label: "French (Canada)", value: "fr-CA", instagramValue: "fr_CA", flag: "" },
  { label: "Hungarian", value: "hu-HU", instagramValue: "hu_HU", flag: "none" },
  { label: "Romanian", value: "ro-RO", instagramValue: "ro_RO", flag: "none" },
  { label: "Slovak", value: "sk-SK", instagramValue: "sk_SK", flag: "none" },
  { label: "Slovenian", value: "sl-SI", instagramValue: "sl_SI", flag: "none" },
  { label: "Kurdish", value: "ku-TR", instagramValue: "ku_TR", flag: "none" },
  { label: "Armenian", value: "hy-AM", instagramValue: "hy_AM", flag: "none" },
  { label: "Belarusian", value: "be-BY", instagramValue: "be_BY", flag: "none" },
  { label: "Bosnian", value: "bs-BA", instagramValue: "bs_BA", flag: "none" },
  { label: "Bulgarian", value: "bg-BG", instagramValue: "bg_BG", flag: "none" },
  { label: "Croatian", value: "hr-HR", instagramValue: "hr_HR", flag: "none" },
  { label: "Frisian", value: "fy-NL", instagramValue: "fy_NL", flag: "none" },
  { label: "Estonian", value: "et-EE", instagramValue: "et_EE", flag: "none" },
  { label: "Faroese", value: "et-EE", instagramValue: "fo_FO", flag: "none" },
  { label: "Georgian", value: "ka-GE", instagramValue: "ka_GE", flag: "none" },
  { label: "Greek", value: "el-GR", instagramValue: "el_GR", flag: "none" },
  { label: "Icelandic", value: "is-IS", instagramValue: "is_IS", flag: "none" },
  { label: "Indonesian", value: "id-ID", instagramValue: "id_ID", flag: "none" },
  { label: "Irish", value: "ga-IE", instagramValue: "ga_IE", flag: "none" },
  { label: "Javanese", value: "jv-ID", instagramValue: "jv_ID", flag: "none" },
  { label: "Kazakh", value: "kk_KZ", instagramValue: "kk_KZ", flag: "none" },
  { label: "Latvian", value: "lv-LV", instagramValue: "lv_LV", flag: "none" },
  { label: "Lithuanian", value: "lt-LT", instagramValue: "lt_LT", flag: "none" },
  { label: "Macedonian", value: "mk-MK", instagramValue: "mk_MK", flag: "none" },
  { label: "Malagasy", value: "mg-MG", instagramValue: "mg_MG", flag: "none" },
  { label: "Malay", value: "ms-MY", instagramValue: "ms_MY", flag: "none" },
  { label: "Maltese", value: "mt-MT", instagramValue: "mt_MT", flag: "none" },
  { label: "Mongolian", value: "mn-MN", instagramValue: "mn_MN", flag: "none" },
  { label: "Nepali", value: "ne-NP", instagramValue: "ne_NP", flag: "none" },
  { label: "Serbian", value: "sr-RS", instagramValue: "sr_RS", flag: "none" },
  { label: "Somali", value: "so-SO", instagramValue: "so_SO", flag: "none" },
  { label: "Swahili", value: "sw-KE", instagramValue: "sw_KE", flag: "none" },
  { label: "Filipino", value: "tl-PH", instagramValue: "tl_PH", flag: "none" },
  { label: "Uzbek", value: "uz-UZ", instagramValue: "uz_UZ", flag: "none" },
  { label: "Vietnamese", value: "vi-VN", instagramValue: "vi_VN", flag: "none" },
  { label: "Khmer", value: "km-KH", instagramValue: "km_KH", flag: "none" },
  { label: "Tajik", value: "tg-TJ", instagramValue: "tg_TJ", flag: "none" },
  { label: "Arabic", value: "ar-AR", instagramValue: "ar_AR", flag: "none" },
  { label: "Hebrew", value: "he-IL", instagramValue: "he_IL", flag: "none" },
  { label: "Urdu", value: "ur-PK", instagramValue: "ur_PK", flag: "none" },
  { label: "Persian", value: "fa-IR", instagramValue: "fa_IR", flag: "none" },
  { label: "Pashto", value: "ps-AF", instagramValue: "ps_AF", flag: "none" },
  { label: "Zawgyi (Myanmar)", value: "qz-MM", instagramValue: "qz_MM", flag: "none" },
  { label: "Burmese (Myanmar [Burma])", value: "my-MM", instagramValue: "my_MM", flag: "none" },
  { label: "Sinhala (Sri Lanka)", value: "si-LK", instagramValue: "si_LK", flag: "none" },
  { label: "Kinyarwanda (Rwanda)", value: "rw-RW", instagramValue: "rw_RW", flag: "none" },
  { label: "Sorani", value: "cb-IQ", instagramValue: "cb_IQ", flag: "none" },
  { label: "Hausa (Nigeria)", value: "ha-NG", instagramValue: "ha_NG", flag: "none" },
  { label: "Fulani (Nigeria)", value: "ff-NG", instagramValue: "ff_NG", flag: "none" },
  { label: "Tamazight", value: "tz-MA", instagramValue: "tz_MA", flag: "none" },
  { label: "Silesian (Poland)", value: "sz-PL", instagramValue: "sz_PL", flag: "none" },
  { label: "Italian", value: "it", instagramValue: "it_IT", flag: "" },
  { label: "Sardinian (Italy)", value: "it", instagramValue: "sc_IT", flag: "" },
  { label: "Japanese", value: "ja", instagramValue: "ja_JP", flag: "" },
  { label: "Kansai-ben (Japan)", value: "ja", instagramValue: "ja_KS", flag: "" },
  { label: "Korean", value: "ko", instagramValue: "ko_KR", flag: "" },
  { label: "Norwegian (bokmal)", value: "no", instagramValue: "nb_NO", flag: "" },
  { label: "Norwegian (nynorsk)", value: "no", instagramValue: "nn_NO", flag: "" },
  { label: "Polish", value: "pl", instagramValue: "pl_PL", flag: "" },
  { label: "Portuguese (Brazil)", value: "pt-BR", instagramValue: "pt_BR", flag: "" },
  { label: "Portuguese (Portugal)", value: "pt", instagramValue: "pt_PT", flag: "" },
  { label: "Russian", value: "ru", instagramValue: "ru_RU", flag: "" },
  { label: "Swedish", value: "sv", instagramValue: "sv_SE", flag: "" },
  { label: "Thai", value: "th", instagramValue: "th_TH", flag: "" },
  { label: "Turkish", value: "tr", instagramValue: "tr_TR", flag: "" },
  { label: "Bengali", value: "hi", instagramValue: "bn_IN", flag: "" },
  { label: "Gujarati", value: "hi", instagramValue: "gu_IN", flag: "" },
  { label: "Hindi", value: "hi", instagramValue: "hi_IN", flag: "" },
  { label: "Kannada", value: "hi", instagramValue: "kn_IN", flag: "" },
  { label: "Marathi", value: "hi", instagramValue: "mr_IN", flag: "" },
  { label: "Punjabi", value: "hi", instagramValue: "pa_IN", flag: "" },
  { label: "Tamil", value: "hi", instagramValue: "ta_IN", flag: "" },
  { label: "Telugu", value: "hi", instagramValue: "te_IN", flag: "" },
  { label: "Malayalam", value: "hi", instagramValue: "ml_IN", flag: "" },
  { label: "Oriya (India)", value: "hi", instagramValue: "or_IN", flag: "" },
  { label: "Assamese (India)", value: "hi", instagramValue: "as_IN", flag: "" },
  { label: "English (Great Britain)", value: "en-GB", instagramValue: "en_GB", flag: "" },
  { label: "Ukrainian", value: "uk", instagramValue: "uk_UA", flag: "" },
];

export const NOTIFICATION_MODULE_NAME_CONST = {
  handoff: "LIVE_CHAT",
  nlp: "NLP",
};
export const ContactNumberWithCountryCodeRegex = /^\+[0-9]{1,3}?[0-9]{8,16}$/;
