<div class="color-theme-main">
  <div class="font-gray">
    {{ "COLOR_THEME" | translate }}
  </div>
  <div class="d-flex mt-10">
    <ng-container *ngFor="let colorBall of colors; let colorIndex = index">
      <div class="color-theme-ball--main" [ngStyle]="{ marginLeft: colorIndex > 0 ? '10px' : '0' }">
        <div
          class="color-theme-ball"
          (click)="selectColor(colorIndex)"
          [ngStyle]="{
            'background-color': colorBall.color,
            border: colorBall.isSelected ? '2px solid white' : 'none',
            boxShadow: colorBall.isSelected ? '0 0 0 2px' + colorBall.color : 'none'
          }"
        ></div>
      </div>
    </ng-container>
    <input
      class="input ml-auto w-px-100 position-relative top-minus-15"
      [placeholder]="'COLOR' | translate"
      (paste)="setColor($event, type)"
      (keyup)="setColor($event, type)"
      (blur)="setColor($event, type)"
      [(ngModel)]="selectedColor"
    />
  </div>
</div>
<div class="custom-theme-main">
  <div class="font-gray">
    {{ "CUSTOM_THEME" | translate }}
  </div>
  <input [id]="type" />
</div>
