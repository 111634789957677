<!-- MAIN TABLE | START-->
<ul class="table-ul">
  <!-- TABLE-HEADER | START-->
  <li *ngIf="isBulkAction && ischecked">
    <div class="d-flex flow-wrap bulk-action">
      <mat-checkbox
        class="example-margin mr-25"
        [checked]="allComplete"
        [color]="'primary'"
        [indeterminate]="someComplete()"
        (change)="setAll($event.checked)"
      >
      </mat-checkbox>
      <img class="mr-25" src="../../../../../assets/images/bulkmove.svg" (click)="moveBulk()" />
      <img class="mr-30" src="../../../../../assets/images/delete-icon.svg" (click)="deleteBulk()" />
      <span>{{ checkedCount }} {{ "OUT_OF" | translate }} {{ total }} {{ "SELECTED" | translate }}</span>
    </div>
  </li>

  <li *ngIf="!ischecked">
    <div *ngIf="table.isCheckBox" class="flow-wrap select-column mr-10">
      <mat-checkbox
        class="ck"
        [ngClass]="{ 'disable-checkbox notAllowed-cursor': table.isChecked }"
        [matTooltip]="'SELECT_ALL' | translate"
        (ngModelChange)="selectAll($event)"
        [disabled]="table.isChecked"
        [(ngModel)]="isAllChecked"
      >
      </mat-checkbox>
    </div>
    <div
      *ngFor="let row of table.columns; let i = index"
      class="d-block flow-wrap text-overflow-ellipsis overflow-hidden pt-20"
      [ngStyle]="{ flex: '0 0 ' + row.width + '%', 'max-width': row.width + '%' }"
      (click)="sortList(i, row.value, row.isSorting)"
      [ngClass]="{ 'justify-content-center': row.flag === 'center' }"
    >
      {{ row.name | translate }}
      <div class="sorting-arrow" *ngIf="row.isSorting" [class.rotation-none]="sortingType === 1" [class.visible]="row.isCheckValue">
        <img alt="" src="../../../../../assets/images/down-arrow-wrap.svg" />
      </div>
    </div>
    <div *ngIf="table.actions.length > 0" class="flow-wrap">
      {{ "ACTION" | translate }}
    </div>
  </li>
  <!-- TABLE-HEADER | END-->

  <!-- TABLE-BODY | START-->
  <ng-container *ngIf="!initialGhostLoader">
    <li [ngClass]="{ selected: rule.isSelected }" *ngFor="let rule of data; let dIndex = index">
      <div *ngIf="table.isCheckBox" class="flow-wrap select-column mr-10">
        <mat-checkbox
          [(ngModel)]="rule['isChecked']"
          [ngClass]="{ 'disable-checkbox notAllowed-cursor': rule['isNotCheckBox'] }"
          [disabled]="rule['isNotCheckBox']"
          (ngModelChange)="selectSingle($event, dIndex)"
        ></mat-checkbox>
      </div>

      <div
        class="flow-wrap"
        *ngFor="let row of table.columns; index as rIndex"
        [ngStyle]="{ flex: '0 0 ' + row.width + '%', 'max-width': row.width + '%' }"
        [ngClass]="{
          'c-p': table.navigation && table.navigation.length > 0,
          'justify-content-center': row.flag === 'center',
          approved: rule[row.value] === 'approved' || rule[row.value].status === 'Trained',
          pending: rule[row.value] === 'pending' || rule[row.value].status === 'Pending',
          rejected: rule[row.value] === 'rejected' || rule[row.value].status === 'Failed',
          submitted: rule[row.value] === 'submitted' || rule[row.value].status === 'Untrained'
        }"
        (click)="openNavigation(rule._id, dIndex, rule)"
        [matTooltip]="
          table.navigation && table.navigation.length > 0 && row.type !== 'infoIcon' && row.type !== 'typeIcon'
            ? ('CLICK_TO_OPEN' | translate)
            : ''
        "
      >
        <div *ngIf="row.type === 'textWithInput'" class="d-flex align-items-center w-100">
          <span *ngIf="!rule.isRename">{{ rule[row.value] }}</span>
          <input
            type="text"
            *ngIf="rule.isRename"
            class="edit-Flow_name w-100"
            [id]="'name-' + dIndex"
            [(ngModel)]="rule[row.value]"
            (click)="stopRoutine($event)"
            (focusin)="saveLastName(dIndex, row.value)"
            (focusout)="saveData(rule, dIndex)"
            (keyup.enter)="saveData(rule, dIndex)"
          />
        </div>

        <div *ngIf="row.type === 'button'" class="d-flex align-items-center w-100">
          <button
            type="button"
            [ngClass]="isNoneChecked ? 'btn-outline-primary' : 'train-btn-disabled'"
            [class.btn-selected]="selectedIndex === dIndex"
            [disabled]="!isNoneChecked"
            (click)="trainData(rule._id, dIndex)"
          >
            Train
          </button>
        </div>

        <span class="nameofintent" *ngIf="row.type === 'text' && !row.isEditable" [ngClass]="{ 'text-center': row.flag === 'center' }">{{
          rule[row.value] !== undefined ? rule[row.value] : "N/A"
        }}</span>
        <span
          class="nameofintent copyright-text"
          *ngIf="row.type === 'link' && !row.isEditable"
          [ngClass]="{ 'text-center': row.flag === 'center' }"
        >
          <a [href]="rule[row.value].link.startsWith('http') ? rule[row.value].link : 'https://' + rule[row.value].link" target="_blank">{{
            rule[row.value].name !== undefined ? rule[row.value].name : "N/A"
          }}</a></span
        >
        <span
          class="nameofintent"
          *ngIf="row.type === 'statusWithReason' && !row.isEditable"
          [ngClass]="{ 'text-center': row.flag === 'center' }"
          >{{ rule[row.value].status !== undefined ? rule[row.value].status : "N/A" }}
        </span>
        <div *ngIf="row.type === 'statusWithReason' && rule[row.value].status === 'Failed'" [matTooltip]="rule[row.value].reason">
          <img class="m-5" src="assets/images/info_icon1.svg" alt="Information Icon" />
        </div>
        <span
          class="nameofintent"
          *ngIf="row.type === 'text' && row.isEditable && isEdit !== dIndex"
          (click)="isEdit = dIndex"
          [ngClass]="{ 'text-center': row.flag === 'center' }"
          >{{ rule[row.value] !== undefined ? rule[row.value] : "N/A" }}</span
        >
        <input
          *ngIf="row.type === 'text' && row.isEditable && isEdit === dIndex"
          (blur)="isEdit = false; changeValue(rule._id, $event)"
          autofocus="false"
          (keydown.enter)="isEdit = false; changeValue(rule._id, $event)"
          class="nameofintent w-100 input ng-pristine ng-valid ng-touched"
          [ngClass]="{ 'text-center': row.flag === 'center' }"
          type="text"
          [value]="rule[row.value]"
        />

        <div *ngIf="row.type === 'textWithProfile'" class="nameofintent">
          <img *ngIf="rule.profile_pic" [src]="rule.profile_pic" class="w-px-40 h-px-40 line-40 border-radius-50" alt="" />
          <div class="profile_img" *ngIf="!rule.profile_pic">
            {{ rule.data_letters }}
          </div>
          <span>{{ rule[row.value] !== undefined ? rule[row.value] : "N/A" }}</span>
        </div>

        <div *ngIf="row.type === 'textWithImage'" class="nameofintent">
          <img height="25" width="25" class="mr-10" [src]="rule.icon" />
          {{ rule[row.value] !== undefined ? rule[row.value] + row.icon : "N/A" }}
        </div>

        <div *ngIf="row.type === 'percentageWithIndicator'" class="div-quality-score">
          <span class="quality-score" [ngClass]="rule.class"></span>
          {{ rule[row.value] !== undefined ? rule[row.value] + "%" : "N/A" }}
        </div>

        <ng-container *ngIf="row.type === 'status'">
          <app-badge [text]="rule[row.value]" [status]="rule[row.value]"></app-badge>
        </ng-container>
        <ng-container *ngIf="row.type === 'actionBtn'">
          <app-action-button (actionEvent)="performAction($event, rule, dIndex)"></app-action-button>
        </ng-container>

        <div *ngIf="row.type === 'date'" class="edit-date">
          <span>{{ rule[row.value] !== undefined ? (rule[row.value] | date: "d MMM y":"":transitionLanguage) : "N/A" }}</span>
        </div>

        <div *ngIf="row.type === 'dateWithTime'" class="w-px-100">
          <span class="date-spacing">{{
            rule[row.value] !== undefined ? (rule[row.value] | date: "d MMM y hh:mm:ss":"":transitionLanguage) : "N/A"
          }}</span>
        </div>

        <span *ngIf="row.type === 'textArray' && rule[row.value].length < 1">{{ "N/A" }} </span>

        <div *ngIf="row.type === 'statusWithIcon'" class="intentStatusIcon flow-progress d-flex align-items-center w-100">
          <ul>
            <li *ngIf="row.name === 'STATUS'">
              <mat-icon class="d-flex align-center">check_circle_outline</mat-icon>
              <span>{{ rule[row.value] }}</span>
            </li>
          </ul>
        </div>
        <div *ngIf="row.type === 'textWithIcon'" class="flow-progress">
          <ul>
            <li *ngIf="row.name === 'STATICS'" class="flag" (click)="$event.stopPropagation(); performAction('summary', rule, dIndex)">
              <img src="../../../../../assets/images/analytics/flag.svg" class="img-fluid" alt="" />
            </li>
            <li>
              <img src="../../../../../assets/images/down-arrow-wrap.svg" class="img-fluid rotate-180" alt="" />
              <span>{{ rule[row.value] }}</span>
            </li>
          </ul>
        </div>

        <div *ngIf="row.type === 'typeIcon'" class="mr-12">
          <img [matTooltip]="rule.type" [src]="rule[row.value]" class="flow-type-img ml-3" alt="" />
        </div>

        <div *ngIf="row.type === 'image' && !row.isEditable">
          <img *ngIf="rule.tableImagePath !== undefined" [src]="rule.tableImagePath" class="table-img ml-3" alt="" />
          <mat-icon color="primary" *ngIf="rule.tableIcon">{{ rule.tableIcon }}</mat-icon>
        </div>

        <div *ngIf="row.type === 'image' && row.isEditable" (click)="openTranscript(rule)">
          <img *ngIf="rule.tableImagePath !== undefined" [src]="rule.tableImagePath" class="table-img ml-3" alt="" />
          <mat-icon color="primary" *ngIf="rule.tableIcon">{{ rule.tableIcon }}</mat-icon>
        </div>

        <div *ngIf="row.type === 'svg'" [style.color]="rule[row.value]">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7 3H8V2H7V0H0V5H7V3ZM6 4H1V1H6V4Z" fill="currentColor" />
            <path d="M9 13H8V14H9V16H16V11H9V13ZM10 12H15V15H10V12Z" fill="currentColor" />
            <path
              d="M15 5.25C15 6.49263 13.9927 7.5 12.75 7.5H12.5V8.5H12.75C14.5449 8.5 16 7.04494 16 5.25C16 3.45506 14.5449 2 12.75 2H11V3H12.75C13.9921 3.00137 14.9986 4.00794 15 5.25Z"
              fill="currentColor"
            />
            <path d="M10 2H9V3H10V2Z" fill="currentColor" />
            <path
              d="M10.207 8.49997H11.5V7.49997H10.207L8 5.29297L5.793 7.49997H4.5V8.49997H5.793L8 10.707L10.207 8.49997ZM8 6.70697L9.293 7.99997L8 9.29297L6.707 7.99997L8 6.70697Z"
              fill="currentColor"
            />
            <path
              d="M1 10.75C1 9.50734 2.00734 8.5 3.25 8.5H3.5V7.5H3.25C1.45506 7.5 0 8.95506 0 10.75C0 12.5449 1.45506 14 3.25 14H5V13H3.25C2.00794 12.9986 1.00137 11.9921 1 10.75Z"
              fill="currentColor"
            />
            <path d="M7 13H6V14H7V13Z" fill="currentColor" />
          </svg>
        </div>

        <ng-container *ngIf="row.type === 'text-icon'">
          <div class="d-flex align-center">
            <mat-icon class="icon-text" color="primary" *ngIf="row.icon">{{ rule.icon }}</mat-icon>
            <span class="ml-10">{{ rule[row.value] !== undefined ? rule[row.value] : "N/A" }}</span>
          </div>
        </ng-container>

        <ng-container *ngIf="row.type === 'imageArray' && rule[row.value].length && rule[row.value].length > 0">
          <ng-container *ngFor="let image of rule[row.value]">
            <img alt="" *ngIf="image !== undefined" [src]="image" />
          </ng-container>
        </ng-container>

        <ng-container *ngIf="row.type === 'imageArray' && rule[row.value].length < 1">
          <span>N/A</span>
        </ng-container>

        <ng-container *ngIf="row.type === 'infoIcon' && rule[row.value]">
          <mat-icon [matTooltip]="rule[row.value]" class="info-icon">error_outline</mat-icon>
        </ng-container>
        <mat-icon
          class="opacity-5 ml-20"
          *ngIf="row.type === 'info-button'"
          [matTooltip]="'MORE_INFO' | translate"
          (click)="performAction(row.type, rule, dIndex)"
          >info_outline</mat-icon
        >
      </div>

      <div *ngIf="table.actions.length > 0" class="flow-wrap">
        <button mat-icon-button [matMenuTriggerFor]="menu">
          <mat-icon class="opacity-6">more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item class="h-px-40" *ngFor="let action of table.actions" (click)="performAction(action.type, rule, dIndex)">
            <div class="flow-action-main">
              <img [src]="action.icon" class="img-fluid" alt="" />
              <p [ngClass]="{ red: action.type === 'delete' }">
                {{ action.name | translate }}
              </p>
            </div>
          </button>
        </mat-menu>
      </div>
    </li>
  </ng-container>
  <!-- TABLE-BODY | END-->

  <!--Initial Ghost loader for Whole table-->
  <ng-container *ngIf="initialGhostLoader">
    <app-table-row-loader
      [noOfRows]="ghostLoaderConfig?.noOfRows"
      [noOfColumns]="table.columns?.length ? table.columns.length : 1"
      [isRecordsLoaded]="false"
      [isRecordEnd]="false"
      [colsWidth]="colsWidth"
    >
    </app-table-row-loader>
  </ng-container>

  <!--Single Row loader-->
  <app-table-row-loader
    [noOfRows]="1"
    [noOfColumns]="table.actions?.length ? table.columns?.length + 1 : table.columns?.length"
    [isRecordsLoaded]="isRecordsLoaded"
    [isRecordEnd]="lastRecordFetched"
    [colsWidth]="colsWidth"
    [lastAction]="table.actions?.length > 0"
  >
  </app-table-row-loader>
</ul>
<!-- MAIN TABLE | END-->
