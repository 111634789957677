<div class="modal-header">
  <p>{{ "CREATE_NEW_FLEXIBLE_INDICATOR" | translate }}</p>
  <mat-icon mat-dialog-close class="material-icons close"> close </mat-icon>
</div>
<div class="modal-body">
  <form *ngIf="indicatorForm" [formGroup]="indicatorForm">
    <mat-form-field appearance="outline">
      <mat-label>{{ "ENTER_NAME" | translate }}</mat-label>
      <input
        maxlength="20"
        (keypress)="validateIndicatorName($event)"
        formControlName="indicatorValue"
        matInput
        [placeholder]="'ENTER_NAME' | translate"
      />
    </mat-form-field>

    <mat-form-field appearance="outline" class="mt-20">
      <mat-label>{{ "TYPE" | translate }}</mat-label>
      <mat-select formControlName="type">
        <mat-option *ngFor="let type of Type" [value]="type.id">
          {{ type.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <button (click)="addFIEmitter.emit()" mat-dialog-close class="createBtn" mat-button>
      {{ "CREATE" | translate }}
    </button>
  </form>
</div>
