<div class="maindiv pt-10">
  <div class="resetPassword">
    <form [formGroup]="resetpassword">
      <div class="col-12">
        <div class="my-profile-settings-header">
          <h4>{{ "RESET_PASSWORD" | translate }}</h4>
        </div>
      </div>

      <div class="profile-main">
        <div class="col-md-6 col-12 new-password">
          <label class="reset-password mb-10">{{ "CURRENT_PASSWORD" | translate }} : </label>
          <div class="input-div input-pass">
            <input
              class="custom-text-input w-100"
              [placeholder]="'ENTER_CURRENT_PASSWORD' | translate"
              type="password"
              formControlName="oldPassword"
              autocomplete="off"
            />
          </div>
        </div>
        <div class="col-md-6 col-12 new-password">
          <label class="reset-password mb-10">{{ "NEW_PASSWORD" | translate }} : </label>
          <div class="input-div input-pass">
            <input
              class="custom-text-input w-100"
              [placeholder]="'ENTER_NEW_PASSWORD' | translate"
              autocomplete="off"
              type="password"
              formControlName="newPassword"
            />
          </div>
        </div>
        <div class="col-md-6 col-12 new-password">
          <label class="reset-password mb-10">{{ "CONFIRM_PASSWORD" | translate }} : </label>
          <div class="input-div input-pass">
            <input
              type="password"
              class="custom-text-input w-100"
              autocomplete="off"
              [placeholder]="'ENTER_CONFIRM_PASSWORD' | translate"
              type="password"
              formControlName="confirmPassword"
            />
          </div>
        </div>
        <mat-checkbox color="primary" class="logout-checkbox" formControlName="isLogoutOfOtherDevices">
          {{ "LOGOUT_OF_ALL_OTHER_DEVICES" | translate }}
        </mat-checkbox>
      </div>
      <div class="reset-button d-flex align-items-center">
        <p class="forgot-pass" (click)="openDialog(isNewUser)">
          {{ "I_FORGOT_MY_PASSWORD" | translate }}
        </p>
        <button
          mat-flat-button
          color="primary"
          [ngStyle]="{
            opacity: resetpassword.invalid || isDisable ? '0.6' : '1',
            cursor: resetpassword.invalid ? 'not-allowed' : 'pointer'
          }"
          (click)="resetpassword.invalid ? $event.stopPropagation() : forgotPassword()"
          class="font-white"
          [disabled]="isDisable"
        >
          {{ "CHANGE_PASSWORD" | translate }}
        </button>
      </div>
    </form>
  </div>
</div>

<ng-template #isNewUser>
  <div class="modal-header">
    <span class="font-gray f-14 mb-10">{{ "RESET_PASSWORD_LINK_WILL_BE_SENT_TO_YOUR_EMAIL" | translate }}</span>
    <mat-icon class="material-icons forgot-pass-model-Close font-gray" (click)="closeModal()"> close </mat-icon>
  </div>
  <div class="modal-body">
    <p>{{ "RESET_PASSWORD_CONFIRMATION" | translate }}</p>
  </div>
  <div class="d-flex just-center">
    <button
      mat-button
      [mat-dialog-close]="false"
      mat-dialog-close
      color="accent"
      class="mr-20 reset-dialog-button"
      (click)="dialog.closeAll()"
    >
      {{ "CANCEL" | translate }}
    </button>
    <button mat-button [mat-dialog-close]="true" cdkFocusInitial color="accent" class="reset-dialog-button" (click)="confirmPass()">
      {{ "CONFIRM" | translate }}
    </button>
  </div>
</ng-template>
