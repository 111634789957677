import { Injectable } from "@angular/core";
import { Urls } from "../utils/urls";
import { HttpClient } from "@angular/common/http";
import { IFlexibleIndicator } from "../models/flexible-indicator";
import { IPaginateResults } from "../models/common-model";
import { BehaviorSubject } from "rxjs";

@Injectable()
export class FlexibleIndicatorService {
  stepIndex = new BehaviorSubject(0);
  fiIndex = new BehaviorSubject(0);
  fiMapIndex = new BehaviorSubject(0);
  constructor(private http: HttpClient) {}

  getFlexibleIndicatorList(): Promise<IFlexibleIndicator[]> {
    return new Promise((resolve, reject) => {
      return this.http.get(Urls.flexible_indicator + "bot").subscribe({
        next: (data: IFlexibleIndicator[]) => {
          resolve(data);
        },
        error: (err) => {
          reject(err);
        },
      });
    });
  }

  addFlexibleIndicator(object: Partial<IFlexibleIndicator>) {
    return new Promise((resolve, reject) => {
      return this.http.post(Urls.flexible_indicator, object).subscribe(
        (data) => {
          resolve(data);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  editFlexibleIndicator(id: string, object: Partial<IFlexibleIndicator>): Promise<void> {
    return new Promise((resolve, reject) => {
      return this.http.put(Urls.flexible_indicator + id, object).subscribe({
        next: () => {
          resolve();
        },
        error: (err) => {
          reject(err);
        },
      });
    });
  }

  deleteFlexibleIndicator(flexibleIndicatorId: string): Promise<void> {
    return new Promise((resolve, reject) => {
      return this.http.delete(Urls.flexible_indicator + flexibleIndicatorId).subscribe({
        next: () => {
          resolve();
        },
        error: (err) => {
          reject(err);
        },
      });
    });
  }

  getFlexibleIndicatorPaginated(requestParams): Promise<IPaginateResults<IFlexibleIndicator>> {
    return new Promise((resolve, reject) => {
      return this.http
        .get(
          `${Urls.flexible_indicator}?page=${requestParams.page}&limit=${requestParams.limit}&filter_param=${requestParams.searchParams}`
        )
        .subscribe({
          next: (data: IPaginateResults<IFlexibleIndicator>) => {
            resolve(data);
          },
          error: (err) => {
            reject(err);
          },
        });
    });
  }
}
