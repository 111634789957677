import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { environment } from "../../../../environments/environment";
import { FlowStepTypeEnum } from "../../../bot-builder/enums/flowStepTypes.enum";
import { MessageTypeEnum } from "../../enums/userProfile.enum";
import { INTERACTIVE_BUTTON_HEADER_CONST } from "../../consts/interactiveType.const";
import { CommonService } from "../../services/common.service";

@Component({
  selector: "app-common-conversation",
  templateUrl: "./common-conversation.component.html",
  styleUrls: ["./common-conversation.component.scss"],
})
export class CommonConversationComponent implements OnInit, OnChanges {
  @Input() transcript;
  @Input() userDetails;
  @Input() botIcon;
  @Input() parentComponent;
  @Output() dateLabel = new EventEmitter();
  @Input() tranlateLanguage = "bot";
  messageTypeEnum = MessageTypeEnum;
  flowStepTypeEnum = FlowStepTypeEnum;
  interactiveTypeHeaderConst = INTERACTIVE_BUTTON_HEADER_CONST;
  environment = environment;
  transcriptArr = [];
  currentDate = new Date();
  isShowSticky = true;
  newDate;
  constructor(readonly commonService: CommonService) {}

  ngOnInit() {
    if (!this.botIcon) {
      this.botIcon = localStorage.getItem("BotIcon");
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.transcript?.currentValue) {
      this.prepareTranscriptData(changes.transcript.currentValue);
    } else if (changes.tranlateLanguage?.currentValue) {
      this.translatedLangugeChange();
    }
  }

  prepareTranscriptData(transcript) {
    if (transcript) {
      this.transcriptArr = [];
      let datePushCount = 0;
      transcript.forEach((message, index) => {
        // create message date push into arrayisShowSticky
        if (message) {
          if (index === 0) {
            if (new Date(message.createdAt).getDate() === this.currentDate.getDate()) {
              this.transcriptArr.splice(index + datePushCount, 0, {
                date: "Today",
                type: "conversationDate",
              });
              datePushCount++;
            } else {
              this.transcriptArr.splice(index + datePushCount, 0, {
                date: new Date(message.createdAt),
                type: "conversationDate",
              });
              datePushCount++;
            }
          } else if (new Date(message.createdAt).getDate() !== new Date(transcript[index - 1].createdAt).getDate()) {
            if (new Date(message.createdAt).getDate() === this.currentDate.getDate()) {
              this.transcriptArr.splice(index + datePushCount, 0, {
                date: "Today",
                type: "conversationDate",
              });
              datePushCount++;
            } else {
              this.transcriptArr.splice(index + datePushCount, 0, {
                date: new Date(message.createdAt),
                type: "conversationDate",
              });
              datePushCount++;
            }
          }
          if (message.from === "Bot") {
            if (message.type === "form") {
              message.form.formElements.forEach((formElement, fIndex) => {
                if (formElement.type === "checkBox") {
                  const items = [];
                  if (Array.isArray(formElement.answerMapping)) {
                    formElement.answerMapping = formElement?.answerMapping?.map((item) => item.trim());
                  }
                  formElement.checkBox.items.forEach((option, oIndex) => {
                    items.push({
                      _id: Date.now() + oIndex,
                      name: option.name ? option.name : option.trim(),
                      isSelected: formElement?.answerMapping?.includes(option.name ? option.name : option.trim()),
                    });
                  });
                  formElement.checkBox.items = items;
                }
                if (formElement.type === "radioButtons") {
                  const items = [];
                  formElement.radioButtons.items.forEach((option, oIndex) => {
                    items.push({
                      _id: Date.now() + oIndex,
                      name: option.name ? option.name : option.trim(),
                      isSelected: formElement?.answerMapping?.trim() === option ? (option.name ? option.name : option.toString()) : false,
                    });
                  });
                  formElement.radioButtons.items = items;
                }
              });
            }
            if (message.type === "checklist") {
              message.checklist.checklistItems.map((checkItem, cIndex) => {
                const a = checkItem;
                a._id = Date.now() + cIndex;
                return a;
              });
            }
            if (message.type === "list" && message.list.listType === "horizontal") {
              message.list.leftReach = false;
              message.list.rightReach = false;
            }
            if (message.type !== "multiselect") {
              this.transcriptArr.push(message);
            }
          }
          if (
            message.from === "User" &&
            message.type !== "formResponse" &&
            message.type !== "checklist" &&
            message.type !== "scheduler" &&
            message.type !== "attachment"
          ) {
            this.transcriptArr.push(message);
          }
          if (message.type === "attachment") {
            const fileName = message.attachment.contentUrl.split("_");
            fileName[0] = "";
            fileName[1] = "";
            message.attachment.fileName = fileName.join("");
            this.transcriptArr.push(message);
          }
        }
      });
      setTimeout(() => {
        this.dateLabel.emit(document.getElementById("date0"));
        const container = document.getElementById("chat-area");
        if (container) {
          this.isShowSticky = container.scrollHeight >= container.offsetHeight;
        }
      }, 100);
    }
  }

  reachBound(index, type) {
    if (type === "left") {
      this.transcriptArr[index].list.leftReach = true;
      this.transcriptArr[index].list.rightReach = false;
    } else if (type === "right") {
      this.transcriptArr[index].list.leftReach = false;
      this.transcriptArr[index].list.rightReach = true;
    }
  }

  updateIndexOfCarousel(index, type, el, display?) {
    if (type === "prev") {
      el.moveLeft();
    } else if (type === "next") {
      el.moveRight();
    }
  }

  checkDate(value) {
    const dt = new Date(value);
    if (isNaN(dt.getTime())) {
      // Checked for date
      return ""; // Return string if not date.
    } else {
      return dt; // Return date **Can do further operations here.
    }
  }
  /**
   * @description This method is used to set which language text will shown in the chat box for handoff
   * @returns {void}
   */
  translatedLangugeChange(): void {
    if (this.tranlateLanguage === "user") {
      this.transcriptArr.forEach((message) => {
        message.isTranslate = true;
      });
      return;
    }

    this.transcriptArr.forEach((message) => {
      message.isTranslate = false;
    });
  }
}
