// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  /*
   * LOCAL
   */
  // apiUrl: "https://822a-103-240-208-5.ngrok.io",
  // frontImageAssetsUrl: "http://localhost:5000/assets/images/",

  /*
   * OTHER'S IP
   */
  // apiUrl: "http://192.168.1.7:3500",

  /*
   * DEVELOPMENT
   */
  // webSocketUrl: "wss://localhost:3700/app",
  // apiUrl: "http://localhost:3500",
  apiUrl: "https://antonic.dev.admin.gradlesol.com/app",
  webSocketUrl: "https://antonic.dev.admin.gradlesol.com/app",
  frontImageAssetsUrl: "https://backoffice.antonic.dev.gradlesol.com/assets/images/",
  extensionUrl: "http://localhost:8080",
  firebase: {
    apiKey: "AIzaSyDUrnvFm-ATOKIbfeCdst7cKjQL5dMh1oQ",
    authDomain: "antonic-dev.firebaseapp.com",
    databaseURL: "https://antonic-dev.firebaseio.com",
    projectId: "antonic-dev",
    storageBucket: "antonic-dev.appspot.com",
    messagingSenderId: "498067251449",
    appId: "1:498067251449:web:d673aeb3dac96590ea4228",
    measurementId: "G-2QLNZE20L3",
  },
  /*
   * STAGING
   */
  // apiUrl: "https://saas.staging.antonic.ai/app",
  // frontImageAssetsUrl: "https://backoffice.staging.antonic.ai/assets/images/",

  /*
   * PRODUCTION
   */
  // apiUrl: "https://saas.app.antonic.ai/app",
  // frontImageAssetsUrl: "https://backoffice.app.antonic.ai/assets/images/"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with AnCannot set delay in minusgular CLI.
