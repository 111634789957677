import { Injectable } from "@angular/core";
import { AngularFireMessaging } from "@angular/fire/messaging";
import { BehaviorSubject } from "rxjs";
import { NotifyService } from "./noyify.service";

@Injectable()
export class FirebaseService {
  static fcmToken;
  currentMessage = new BehaviorSubject(null);
  constructor(private angularFireMessaging: AngularFireMessaging, readonly _notifyService: NotifyService) {}
  requestPermission() {
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        FirebaseService.fcmToken = token;
      },
      (err) => {
        console.error("Unable to get permission to notify.", err);
      }
    );
  }
  receiveMessage() {
    this.angularFireMessaging.messages.subscribe((payload: any) => {
      const user: any = JSON.parse(localStorage.getItem("user"));
      let isSound = false;
      if (payload) {
        isSound = user?.notificationConfig?.[payload.data.type].isSound;
        this._notifyService.sendNotification("info", payload.data.title, payload);
      }
      if (isSound) {
        new Audio("assets/notification.wav").play();
      }
    });
  }
}
