import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Urls } from "../utils/urls";
import { IPaginateResults } from "../models/common-model";
import { IApiDocument, IAPIToolList, IApiToolListRequestParams, IRestAPIResponse, ISaveAPI, ISoapBody } from "../models/api-tool";

@Injectable()
export class ApiToolService {
  constructor(private http: HttpClient) {}

  getAllAPIs(requestParams: IApiToolListRequestParams): Promise<IPaginateResults<IAPIToolList>> {
    return new Promise((resolve, reject) => {
      return this.http
        .get(`${Urls.get_apis}?page=${requestParams.page}&filter_param=${requestParams.filterParam}&limit=${requestParams.limit}`)
        .subscribe({
          next: (data: IPaginateResults<IAPIToolList>) => {
            resolve(data);
          },
          error: (err) => {
            reject(err);
          },
        });
    });
  }

  getAllApiDetails(): Promise<IApiDocument[]> {
    return new Promise((resolve, reject) => {
      return this.http.get(`${Urls.get_apis}all`).subscribe({
        next: (data: IApiDocument[]) => {
          resolve(data);
        },
        error: (err) => {
          reject(err);
        },
      });
    });
  }

  getAPIDetailsById(id: string): Promise<IApiDocument> {
    return new Promise((resolve, reject) => {
      return this.http.get(Urls.get_apis + id).subscribe({
        next: (data: IApiDocument) => {
          resolve(data);
        },
        error: (err) => {
          reject(err);
        },
      });
    });
  }

  deleteAPI(id: string): Promise<void> {
    return new Promise((resolve, reject) => {
      return this.http.delete(Urls.get_apis + id).subscribe({
        next: () => {
          resolve();
        },
        error: (err) => {
          reject(err);
        },
      });
    });
  }

  saveAPI(object: IApiDocument | IApiDocument[], isInsertMany: string | boolean): Promise<void> {
    return new Promise((resolve, reject) => {
      return this.http.post(`${Urls.get_apis}?isInsertMany=${isInsertMany}`, object).subscribe({
        next: () => {
          resolve();
        },
        error: (err) => {
          reject(err);
        },
      });
    });
  }

  editAPI(id: string, object: IApiDocument): Promise<void> {
    return new Promise((resolve, reject) => {
      return this.http.put(Urls.get_apis + id, object).subscribe({
        next: () => {
          resolve();
        },
        error: (err) => {
          reject(err);
        },
      });
    });
  }

  apiToolTestCall(object): Promise<IRestAPIResponse> {
    return new Promise((resolve, reject) => {
      return this.http.post(Urls.get_api_tool, object).subscribe({
        next: (data: IRestAPIResponse) => {
          resolve(data);
        },
        error: (err) => {
          reject(err);
        },
      });
    });
  }

  // this is the SOAP API test call so response is not fix
  testSoapAPI(object: { name: string; soapurl: string }): Promise<any> {
    return new Promise((resolve, reject) => {
      return this.http.post(Urls.soap, object).subscribe({
        next: (data) => {
          resolve(data);
        },
        error: (err) => {
          reject(err);
        },
      });
    });
  }

  // this is the SOAP API test call so response is not fix

  previewSoapResponse(object: { url: string; soaptool: ISoapBody }): Promise<any> {
    return new Promise((resolve, reject) => {
      return this.http.post(Urls.soap_result, object).subscribe({
        next: (data) => {
          resolve(data);
        },
        error: (err) => {
          reject(err);
        },
      });
    });
  }

  saveSoapAPI(object: ISaveAPI): Promise<void> {
    return new Promise((resolve, reject) => {
      return this.http.post(Urls.soap_save, object).subscribe({
        next: () => {
          resolve();
        },
        error: (err) => {
          reject(err);
        },
      });
    });
  }

  editSoapAPI(id: string, object: ISaveAPI): Promise<void> {
    return new Promise((resolve, reject) => {
      return this.http.put(`${Urls.soap_save}/ id`, object).subscribe({
        next: () => {
          resolve();
        },
        error: (err) => {
          reject(err);
        },
      });
    });
  }
}
