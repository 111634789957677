import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "HtmlText" })
export class HtmlToTextPipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    const div = document.createElement("DIV");
    div.innerHTML = value;
    return div.innerText;
  }
}
