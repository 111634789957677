import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Urls } from "../utils/urls";

@Injectable()
export class SearchService {
  constructor(private http: HttpClient) {}

  getAllSearch(input, intent, flow, botUser, page, limit) {
    return new Promise((resolve, reject) => {
      return this.http
        .get(
          Urls.searchContent +
            "&input=" +
            input +
            "&intent=" +
            intent +
            "&flow=" +
            flow +
            "&botUser=" +
            botUser +
            "&page=" +
            page +
            "&limit=" +
            limit
        )
        .subscribe(
          (data) => {
            resolve(data);
          },
          (err) => {
            console.log(err);
            reject(err);
          }
        );
    });
  }
}
