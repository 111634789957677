import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-add-fi",
  templateUrl: "./add-fi.component.html",
})
export class AddFiComponent implements OnInit {
  @Input() indicatorForm;
  @Input() Type;
  @Output() addFIEmitter = new EventEmitter<any>();

  ngOnInit() {
    this.indicatorForm.get("indicatorValue").valueChanges.subscribe((value) => {
      if (value) {
        this.indicatorForm.get("indicatorValue").setValue(value.replaceAll(" ", "_"), { emitEvent: false });
      }
    });
  }

  validateIndicatorName(event) {
    if (event.keyCode === 32 || event.key === " ") {
      event.preventDefault();
    }
    if (event.target.value.length >= 50) {
      event.preventDefault();
    }
    const reg = new RegExp("[A-Za-z0-9\\-\\_]+");
    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !reg.test(inputChar)) {
      event.preventDefault();
    }
  }
}
