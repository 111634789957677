import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Router } from "@angular/router";
import { Urls } from "../utils/urls";
import { Injectable } from "@angular/core";
import { IUser } from "../models/agent.model";
import { IAPICreatedSuccessfullyResponse, IAPIDeletedSuccessfullyResponse } from "../models/common-model";
import { Observable } from "rxjs";
import { IAPIResponse, RootObject } from "../models/common.model";
import { UserProfileModel } from "../models/user-profile";

@Injectable()
export class AuthService {
  user: any;
  token: string;
  remember;
  email;

  constructor(private http: HttpClient, private router: Router) {
    const token = localStorage.getItem("token");
    this.token = token ? token : null;
    const user = localStorage.getItem("user");
    this.user = user ? user : null;
    const remember = localStorage.getItem("remember");
    this.remember = remember ? remember : false;
  }

  login(data, authorization) {
    return new Promise((resolve, reject) => {
      this.http
        .post(Urls.login, data, {
          headers: {
            Authorization: authorization,
          },
        })
        .subscribe(
          (res: IAPICreatedSuccessfullyResponse<IUser>) => {
            this.token = res.data.token;
            this.user = res.data;
            localStorage.setItem("token", this.token);
            localStorage.setItem("user", JSON.stringify(this.user));
            localStorage.setItem("isAgentOnline", this.user.isAgentOnline);
            resolve(res);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  setRememberMe(val) {
    this.remember = val;
    localStorage.setItem("remember", this.remember);
  }

  getRememberMe() {
    return localStorage.getItem("remember");
  }

  getToken() {
    if (localStorage.getItem("token")) {
      return localStorage.getItem("token").slice();
    }
  }

  setOrgLogo(logo) {
    localStorage.setItem("OrgLogo", logo);
  }

  getOrgLogo() {
    return localStorage.getItem("OrgLogo");
  }

  getUser() {
    if (localStorage.getItem("user")) {
      const user = JSON.parse(localStorage.getItem("user"));
      if (typeof user !== "object") {
        this.logout();
      } else {
        return JSON.parse(localStorage.getItem("user"));
      }
    }
  }

  logout() {
    return new Promise((resolve, reject) => {
      this.http.post(Urls.logout, {}, { headers: { Authorization: this.getToken() } }).subscribe(
        (res: IAPIDeletedSuccessfullyResponse) => {
          localStorage.clear();
          this.user = "";
          this.token = "";
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  forgotPassword(data) {
    return new Promise((resolve, reject) => {
      this.http.post(Urls.forgotPassword, data).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  resetPassword(data, token) {
    return new Promise((resolve, reject) => {
      this.http
        .post(Urls.reserPassword, data, {
          headers: new HttpHeaders().append("authorization", token),
        })
        .subscribe(
          (res) => {
            resolve(res);
          },
          (err) => {
            reject(err);
            console.error(err);
          }
        );
    });
  }

  setforgotPassword(data) {
    return new Promise((resolve, reject) => {
      this.http
        .put(Urls.changePassword, data, {
          headers: new HttpHeaders().append("authorization", this.getToken()),
        })
        .subscribe(
          (res) => {
            resolve(res);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }
  getUserDetailsById() {
    return new Promise((resolve, reject) => {
      return this.http.get(Urls.usersProfile).subscribe(
        (data) => {
          resolve(data);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }
  updateUserDetailsById(data, id) {
    return new Promise((resolve, reject) => {
      return this.http.put(Urls.usersProfile + id, data).subscribe(
        (res: IAPICreatedSuccessfullyResponse<UserProfileModel>) => {
          resolve(res.data);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }
  updateUserMenuById(isSideMenuOpenflag, id) {
    return new Promise((resolve, reject) => {
      return this.http.put(Urls.usersProfile + id, isSideMenuOpenflag).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }
  setUser(user) {
    localStorage.setItem("user", JSON.stringify(user));
    this.user = user;
  }
  resetGetCall(token) {
    return new Promise((resolve, reject) => {
      this.http.get(Urls.getresetUrl + token).subscribe(
        (res: any) => {
          localStorage.setItem("token", res.message);
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  getUserBots(): any {
    return new Promise((resolve, reject) => {
      return this.http.get(Urls.baseUrl + "auth/users" + "/botAccess").subscribe(
        (data) => {
          resolve(data);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }
  uploadProfile(data) {
    return new Promise((resolve, reject) => {
      return this.http.post(Urls.baseUrl + "auth/users" + "/user-profile", data).subscribe(
        (data) => {
          resolve(data);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  /*for changing the bot , if we take the botid from interceptor then it returns last bot's id from the local storage ,
  due to that bot can not be changed so for this route bot id passed with route in header & excluded this route from interceptor . */

  getBotById(orgId: string, botId: string): Observable<IAPIResponse<RootObject>> {
    const httpHeaders: HttpHeaders = new HttpHeaders({
      botId: botId,
      organizationId: orgId,
    });
    return this.http.get<IAPIResponse<RootObject>>(`${Urls.baseUrl}org/bots/details`, {
      headers: httpHeaders,
    });
  }
}
